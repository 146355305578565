import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography, Grid, Skeleton } from "@mui/material";
import { Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const RelatedGoodDharma = () => {
  const [displayedItems, setDisplayedItems] = useState(3);
  const navigate = useNavigate();
  const { id } = useParams();

  const { get } = useAPI();

  const getData = async () => {
    const response = await get(endpoint.all_good_dharma);
    return response.data;
  };

  const { isLoading, data: allGoodDharma } = useQuery({
    queryKey: ["allGoodDharma"],
    queryFn: getData,
  });

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          {[1, 2, 3].map((index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rectangular" width={300} height={200} />
              <Skeleton variant="text" width={300} height={30} />
              <Skeleton variant="text" width={300} height={30} />
            </Box>
          ))}
        </Box>
      </>
    );
  }

  const RelatedGoodDharma = allGoodDharma?.filter((item) => item.id !== +id);

  const handleShowMore = () => {
    setDisplayedItems(RelatedGoodDharma?.length);
  };
  const handleShowLess = () => {
    setDisplayedItems(3);
  };

  return (
    <Box
      sx={{
        marginBottom: "60px",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            color: "var(--theme-color)",
            fontFamily: "var(--primary-font)",
            fontWeight: "600",
            fontSize: {
              xs: "11px",
              sm: "12px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
          }}
        >
          RELATED GOOD DHARMA
        </Typography>
        <Box
          sx={{
            marginTop: "15px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              color: "var(--light-black)",
              fontFamily: "var(--primary-font)",
              fontWeight: "500",
              fontSize: {
                xs: "20px",
                sm: "22px",
                md: "23px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            SIMILAR OFFERINGS YOU MAKE LIKE
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: "60px" }}>
        <Grid container spacing={3}>
          {RelatedGoodDharma?.slice(0, displayedItems).map((item) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={item.id}>
              <Box>
                <img
                  src={item.images[0].image}
                  alt={item.title}
                  style={{ height: "100%", width: "100%" }}
                  loading="lazy"
                />
              </Box>

              <Box
                sx={{
                  marginTop: "10px",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--black-color)",
                    fontSize: {
                      xs: "15px",
                      sm: "16px",
                      md: "18px",
                      lg: "20px",
                      xl: "20px",
                    },
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--light-black)",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    overflow: "hidden", // Hide any overflowing text
                    textOverflow: "ellipsis", // Display ellipsis for long texts
                    display: "-webkit-box",
                    WebkitLineClamp: 3, // Adjust the number of lines to fit your requirements
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    textTransform: "capitalize",
                    border: "1px solid var(--theme-color)",
                    color: "var(--white)",
                    marginBottom: "10px",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "12px",
                      lg: "15px",
                      xl: "15px",
                    },
                    ":hover": {
                      //zoom the buttom
                      transform: "scale(1.1)",
                      backgroundColor: "var(--theme-color)",
                      border: "1px solid var(--theme-color)",
                    },
                  }}
                  onClick={() => navigate(`/gooddharma/${item.id}`)}
                >
                  TAKE A LOOK
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                    alt="..."
                    style={{
                      width: "15px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                    loading="lazy"
                  />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                  >
                    <FavoriteBorderIcon />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                  >
                    <ShoppingBagOutlinedIcon fontSize="medium" />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {displayedItems < RelatedGoodDharma?.length && (
          <Button
            variant="outlined"
            sx={{
              color: "var(--theme-color)",
              border: "1px solid var(--theme-color)",
              width: {
                xs: "100%",
                sm: "100%",
                md: "15%",
                lg: "15%",
                xl: "15%",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              marginTop: "50px",
              marginBottom: "50px",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
              "&:hover": {
                backgroundColor: "var(--theme-color)",
                color: "white",
                border: "1px solid var(--theme-color)",
                transform: "scale(1.1)",
              },
            }}
            onClick={handleShowMore}
          >
            Show More
            <KeyboardArrowDownIcon />
          </Button>
        )}
        {displayedItems > 3 && (
          <Button
            variant="outlined"
            sx={{
              color: "var(--theme-color)",
              border: "1px solid var(--theme-color)",
              width: {
                xs: "100%",
                sm: "100%",
                md: "15%",
                lg: "15%",
                xl: "15%",
              },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              marginTop: "50px",
              marginBottom: "50px",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
              "&:hover": {
                backgroundColor: "var(--theme-color)",
                color: "var(--white)",
                border: "1px solid var(--theme-color)",
                transform: "scale(1.1)",
              },
            }}
            onClick={handleShowLess}
          >
            Show Less
            <KeyboardArrowUpIcon />
          </Button>
        )}
      </Box>
    </Box>
  );
};
