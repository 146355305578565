import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DharmagoodCategorySection } from "./DharmagoodCategorySection";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Paintingsearch = ({ title, length }) => {
  const [sort, setSort] = React.useState("");
  const [query, setQuery] = React.useState("");
  const [result, setResult] = React.useState([]);

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  const handleFilter = async () => {
    if (query === "") {
      toast.error("Please enter a search query");
      return;
    }
    try {
      await axios
        .post(
          process.env.REACT_APP_JINLAP_BASE_URL + `/product/detail-search`,
          {
            query: query,
            price_order: sort,
          }
        )
        .then((res) => {
          setResult(res.data.data);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          width: "100%",
          justifyContent: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "space-between",
            xl: "space-between",
          },
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          marginBottom: "2rem",
        }}
      >
        <Box
          className="searchleft_section"
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "justify",
            marginBottom: {
              xs: "0px",
              sm: "20px",
              md: "20px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Typography variant="p" sx={{ color: "#96195a" }}>
            SHOWING RESULTS
          </Typography>
          <Typography variant="p">
            {length} result of "
            <Typography variant="span" sx={{ color: "#96195a" }}>
              {title}
            </Typography>
            "
          </Typography>
        </Box>
        <Box
          className="searchright_section"
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            // backgroundColor: "green",
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "61%",
              xl: "61%",
            },
            justifyContent: "flex-start",
            alignItems: {
              xs: "flex-start",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "center",
            },
          }}
        >
          <Box
            sx={{
              marginRight: {
                xs: "0px",
                sm: "20px",
                md: "20px",
                lg: "20px",
                xl: "20px",
              },
              width: "100%",
            }}
          >
            <TextField
              id="outlined-basic"
              label="What are you looking for?"
              variant="outlined"
              required
              size="small"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                height: "100%",
                " & .MuiOutlinedInput-root": {
                  "  &.Mui-focused fieldset": {
                    borderColor: "#96195a",
                  },
                },
                //border colour when focused
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid #96195a",
                  },
                //floating label color when focused
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "#96195a",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "18px",
                    lg: "20px",
                    xl: "20px",
                  },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "row",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              justifyContent: "flex-start",
              alignItems: {
                xs: "flex-start",
                sm: "center",
                md: "center",
                lg: "center",
                xl: "center",
              },
              textAlign: "center",
              marginTop: {
                xs: "1rem",
                sm: "0",
                md: "0",
                lg: "0",
                xl: "0",
              },
              marginBottom: {
                xs: "1rem",
                sm: "0",
                md: "0",
                lg: "0",
                xl: "0",
              },
            }}
          >
            <FormControl
              sx={{
                minWidth: {
                  xs: 155,
                  sm: 130,
                  md: 130,
                  lg: 130,
                  xl: 130,
                },
                marginRight: "10px",
              }}
            >
              <TextField
                select
                labelId="sorting-label"
                id="sorting-select"
                value={sort}
                label="Price"
                onChange={handleChange}
                size="small"
                // autoWidth
                sx={{
                  width: "130px",
                  " & .MuiOutlinedInput-root": {
                    "  &.Mui-focused fieldset": {
                      borderColor: "#96195a",
                    },
                  },
                  //border colour when focused
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid #96195a",
                    },
                  //floating label color when focused
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "#96195a",
                  },
                }}
              >
                <MenuItem value={"desc"}>High to Low</MenuItem>
                <MenuItem value={"asc"}>Low to High</MenuItem>
              </TextField>
            </FormControl>

            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                marginLeft: {
                  xs: "0px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#96195A",
                  textTransform: "capitalize",

                  "&:hover": {
                    backgroundColor: "#96195A",
                  },
                }}
                onClick={handleFilter}
              >
                Filter
                <FilterAltIcon />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: "#96195A", textTransform: "capitalize" }}
            onClick={handleFilter}
          >
            Filter
            <FilterAltIcon />
          </Button>
        </Box>
        <Box
          className="searchleft_section"
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "justify",
          }}
        >
          <Typography variant="p" sx={{ color: "#96195a" }}>
            SHOWING RESULTS
          </Typography>
          <Typography variant="p">
            {length} result of "
            <Typography variant="span" sx={{ color: "#96195a" }}>
              {title}
            </Typography>
            "
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "2rem" }}>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
      </Box>
      <DharmagoodCategorySection searchData={result} />
    </>
  );
};
