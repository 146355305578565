import React from "react";
import { Typography, Box } from "@mui/material";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const ReturnAndCancellation = () => {
  const { get } = useAPI();

  const getReturnAndCancellation = async () => {
    const response = await get(endpoint.returncancellation);
    return response.data;
  };

  const { isLoading, data: returnAndCancellation } = useQuery({
    queryKey: ["returnAndCancellation"],
    queryFn: getReturnAndCancellation,
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box
      sx={{
        textAlign: "justify",
      }}
    >
      {returnAndCancellation?.map((item) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            marginTop: "20px",
            marginBottom: "20px",
            textAlign: "justify",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "700",
              textAlign: "justify",
              color: "var(--theme-color)",
              fontFamily: "var(--primary-font)",
              fontSize: {
                xs: "18px",
                sm: "20px",
                md: "22px",
                lg: "24px",
                xl: "26px",
              },
            }}
          >
            {item.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginTop: "20px",
              marginBottom: "20px",
              fontFamily: "var(--primary-font)",
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "18px",
                lg: "20px",
                xl: "22px",
              },
            }}
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></Typography>
        </Box>
      ))}
    </Box>
  );
};
