import React from "react";
import "./SupportActivity.css";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";

export const SupportActivity = () => {
  const navigate = useNavigate();
  const [videoPlayingId, setVideoPlayingId] = React.useState(null);

  const handleVideoIconClick = (itemId) => {
    setVideoPlayingId(itemId);
  };

  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.home_support_artists);
    return res.data;
  };

  const { isLoading, data: supportartistsData } = useQuery({
    queryKey: ["supportartists"],
    queryFn: getData,
  });

  if (isLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height="55vh" />
      </>
    );

  return (
    <>
      {supportartistsData?.hide !== 1 && (
        <Box
          className="support_activity"
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            },
            width: "100%",
            padding: {
              xs: "20px",
              sm: "30px",
              md: "30px",
              lg: "50px",
              xl: "50px",
            },
          }}
        >
          {supportartistsData && (
            <>
              <Box
                className="supportdesc"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "70%",
                    lg: "50%",
                    xl: "50%",
                  },
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--white)",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "17px",
                      xl: "17px",
                    },
                    marginTop: "10px",
                  }}
                >
                  {supportartistsData?.title}
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--white)",
                    fontSize: {
                      xs: "17px",
                      sm: "18px",
                      md: "20px",
                      lg: "25px",
                      xl: "25px",
                    },
                  }}
                >
                  {supportartistsData.title_first}
                </Typography>
                <br />
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--white)",
                    fontSize: {
                      xs: "13px",
                      sm: "12px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  {supportartistsData.description}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--white)",
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "18px",
                      lg: "20px",
                      xl: "20px",
                    },
                    textTransform: "uppercase",
                  }}
                >
                  {supportartistsData.title_second}
                </Typography>{" "}
                <br />
                <Box sx={{ marginTop: "20px" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "var(--white)",
                      display: "flex",
                      justifyContent: "flex-end",
                      backgroundColor: "var(--theme-color)",
                      border: "1px solid #fff",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "12px",
                        lg: "15px",
                        xl: "15px",
                      },
                      marginBottom: "20px",
                      "&:hover": {
                        backgroundColor: "var(--theme-color)",
                        color: "white",
                        border: "1px solid var(--white)",
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() => {
                      navigate("/support-artist");
                    }}
                  >
                    {supportartistsData?.button_name}
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                      alt="..."
                      style={{
                        width: "15px",
                        height: "20px",
                        marginLeft: "10px",
                      }}
                      loading="lazy"
                    />
                  </Button>
                </Box>
              </Box>

              {supportartistsData?.preview_type === "video" ? (
                <Box
                  sx={{
                    position: "relative",
                    width: {
                      xs: "90%",
                      sm: "90%",
                      md: "90%",
                      lg: "50%",
                      xl: "50%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "30vh",
                        sm: "45vh",
                        md: "50vh",
                        lg: "50vh",
                        xl: "50vh",
                      },
                      width: {
                        xs: "70%",
                        sm: "70%",
                        md: "70%",
                        lg: "100%",
                        xl: "100%",
                      },
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "block",
                        lg: "block",
                        xl: "block",
                      },
                    }}
                  >
                    {videoPlayingId === supportartistsData.id ? (
                      <>
                        {supportartistsData?.type === "youtube" ? (
                          <ReactPlayer
                            url={supportartistsData?.video_url}
                            controls={true}
                            height="100%"
                            width="100%"
                            playing={true}
                            style={{
                              position: "relative",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 0,
                            }}
                          />
                        ) : (
                          <ReactPlayer
                            url={supportartistsData?.video}
                            controls={true}
                            height="100%"
                            width="100%"
                            playing={true}
                            style={{
                              position: "relative",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 0,
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {supportartistsData?.type === "youtube" ? (
                          <ReactPlayer
                            url={supportartistsData?.video_url}
                            controls={false}
                            playing={false}
                            height="100%"
                            width="100%"
                            style={{
                              position: "relative",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 0,
                            }}
                          />
                        ) : (
                          <ReactPlayer
                            url={supportartistsData?.video}
                            controls={false}
                            playing={false}
                            height="100%"
                            width="100%"
                            style={{
                              position: "relative",
                              justifyContent: "center",
                              alignItems: "center",
                              zIndex: 0,
                            }}
                          />
                        )}
                      </>
                    )}
                  </Box>

                  {/* VideocamIcon */}
                  {videoPlayingId !== supportartistsData.id && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "var(--theme-color)",
                        width: "50px",
                        height: "50px",
                        color: "white",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "50%",
                        left: {
                          xs: "50%",
                          sm: "50%",
                          md: "50%",
                          lg: "0",
                          xl: "0",
                        },
                        transform: "translate(-50%, -50%)",
                        "&:hover": {
                          backgroundColor: "var(--white)",
                          color: "var(--theme-color)",
                        },
                      }}
                      onClick={() =>
                        handleVideoIconClick(supportartistsData.id)
                      }
                    >
                      <Box className="blur-circle" />
                      <VideocamIcon
                        sx={{
                          fontSize: "30px",
                          zIndex: 100,
                          "&: hover": {
                            transform: "scale(1.2)",
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "relative",
                    width: {
                      xs: "90%",
                      sm: "90%",
                      md: "90%",
                      lg: "50%",
                      xl: "50%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      height: {
                        xs: "30vh",
                        sm: "45vh",
                        md: "50vh",
                        lg: "50vh",
                        xl: "50vh",
                      },
                      width: {
                        xs: "100%",
                        sm: "70%",
                        md: "70%",
                        lg: "100%",
                        xl: "100%",
                      },
                    }}
                  >
                    <img
                      src={supportartistsData?.image}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                      }}
                      loading="lazy"
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};
