import React from "react";
import { Box } from "@mui/system";
import { Typography, Grid } from "@mui/material";
import "./Donation.css";
import { NavLink } from "react-router-dom";

export const ArtistDonationTopDetail = ({ artistDonationData }) => {
  const [readmore, setReadmore] = React.useState(false);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          },
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "20px",
              xl: "20px",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              // fontWeight: "bold",
              fontFamily: "var(--primary-font)",
              color: "var(--theme-color)",
              textAlign: "left",
              fontSize: {
                xs: "18px",
                sm: "18px",
                md: "20px",
                lg: "22px",
                xl: "22px",
              },
            }}
          >
            {artistDonationData?.title}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontFamily: "var(--primary-font)",
              color: "var(--light-black)",
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "14px",
                lg: "16px",
                xl: "16px",
              },
              textAlign: "justify",
            }}
          >
            {readmore ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: artistDonationData?.description,
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: artistDonationData?.description?.slice(0, 400),
                }}
              ></span>
            )}
            {artistDonationData?.description?.length > 400 && (
              <NavLink
                style={{
                  color: "var(--theme-color)",
                  whiteSpace: "nowrap",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  setReadmore(true);
                }}
              >
                {readmore ? "Read Less" : "Read More"}
              </NavLink>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
          <Box
            className="image-cont"
            sx={
              {
                // marginLeft: {
                //   xs: "40px",
                //   sm: "40px",
                //   md: "40px",
                //   lg: "60px",
                //   xl: "60px",
                // },
              }
            }
          >
            <div className="line-vert"></div>
            <div className="line-horiz"></div>
            <Box
              sx={{
                height: {
                  xs: "30vh",
                  sm: "20vh",
                  md: "20vh",
                  lg: "30vh",
                  xl: "30vh",
                },
                width: {
                  xs: "30vh",
                  sm: "25vh",
                  md: "25vh",
                  lg: "50vh",
                  xl: "50vh",
                },
              }}
            >
              <img
                src={artistDonationData?.image}
                alt="..."
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                loading="lazy"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
