import { WishlistPage } from "./pages/WishlistPage/WishlistPage";
import { CartPage } from "./pages/CartPage/CartPage";
import { CheckoutPage } from "./pages/CheckoutPage/CheckoutPage";
import { AccountPage } from "./pages/AccountPage/AccountPage";

const routes = [
  {
    path: "/account",
    component: AccountPage,
    role: ["user"],
  },
  {
    path: "/wishlist",
    component: WishlistPage,
    role: ["user"],
  },
  {
    path: "/cart",
    component: CartPage,
    role: ["user"],
  },
  {
    path: "/checkout",
    component: CheckoutPage,
    role: ["user"],
  },
];

export default routes;
