import React from "react";
import { Box } from "@mui/system";

export const LoginRegImageSide = () => {
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: {
            xs: "0vh",
            sm: "55vh",
            md: "75vh",
            lg: "81vh",
            xl: "81vh",
          },
        }}
      >
        <img
          src={
            process.env.PUBLIC_URL +
            "./assets/LogInRegistration/Loginsideimg.png"
          }
          alt="LoginRegImageSide"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: "10px",
          }}
          loading="lazy"
        />
      </Box>
    </Box>
  );
};
