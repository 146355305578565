import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { NavLink } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Cookies } from "react-cookie";
import axios from "axios";

const errorStyle = {
  color: "red",
  fontSize: "12px",
};

export const LogInForm = ({ handleClose }) => {
  const cookies = new Cookies();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  // const [emailValue, setEmailValue] = useState("");
  // const [passwordValue, setPasswordValue] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    email: "",
    password: "",
    rememberme: rememberMe,
  };

  const DataSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be 6 characters long")
      .max(15, "Password must be 15 characters long")
      .required("Password is required"),
  });

  const submitHandler = async (values, { resetForm }) => {
    setIsProcessing(true);
    values.rememberme = rememberMe;
    // if (values.rememberme) {
    //   localStorage.setItem("email", values.email);
    //   localStorage.setItem("password", values.password);
    //   localStorage.setItem("rememberme", values.rememberme);
    // }
    try {
      await axios
        .post(process.env.REACT_APP_JINLAP_BASE_URL + `/login`, values, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const response = res.data.payload;
          const token = response.token;
          const user = response.name;
          const userId = response.id;
          const email = response.email;
          const join_date = response.join_date;
          cookies.set("token", token, { sameSite: "None", secure: true });
          cookies.set("user", user, { sameSite: "None", secure: true });
          cookies.set("userId", userId, { sameSite: "None", secure: true });
          cookies.set("email", email, { sameSite: "None", secure: true });
          cookies.set("join_date", join_date, {
            sameSite: "None",
            secure: true,
          });
          toast.success(res.data.message, { sameSite: "None", secure: true });
          cookies.set("role", "user");
        });
      setIsProcessing(false);
    } catch (error) {
      toast.error("Failed to Login, Try Again");
    }
    resetForm();
    handleClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DataSchema}
      onSubmit={submitHandler}
    >
      {({ errors, touched }) => (
        <Form>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: {
                xs: "0px 0px 30px 0px",
                sm: "0px 20px 10px 20px",
                md: "0px 20px 10px 20px",
                lg: "0px 50px 20px 50px",
                xl: "0px 50px 20px 50px",
              },
            }}
          >
            <Box
              sx={{
                marginTop: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{
                  color: "var(--black-color)",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "10px",
                }}
              >
                Email Address{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Field
                as={TextField}
                variant="outlined"
                type="email"
                size="medium"
                name="email"
                label="Enter Your Email Address"
                autoComplete="off"
                id="input-with-icon-adornment"
                className={errors.email && touched.email ? "input-error" : null}
                sx={{
                  backgroundColor: "transparent",
                  fontFamily: "var(--primary-font)",
                  width: "100%",
                  " & .MuiOutlinedInput-root": {
                    "  &.Mui-focused fieldset": {
                      borderColor: "var(--btn-color)",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "0px solid var(--theme-color)",
                    borderRadius: "0",
                    padding: "0 14px",
                    height: "50px",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "0px solid var(--btn-color))",
                    },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "var(--btn-color)",
                    backgroundColor: "transparent",
                  },
                }}
                // value={emailValue} // Set the value of the email field
                // onChange={(e) => setEmailValue(e.target.value)} // Update the emailValue state
              />
              <ErrorMessage
                name="email"
                component="span"
                className="error"
                style={errorStyle}
              />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{
                  color: "var(--black-color)",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "10px",
                }}
              >
                Password <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Field
                as={TextField}
                variant="outlined"
                size="medium"
                name="password"
                type={showPassword ? "text" : "password"}
                label="Enter Your Password"
                autoComplete="off"
                id="input-with-icon-adornment"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={
                  errors.password && touched.password ? "input-error" : null
                }
                sx={{
                  backgroundColor: "transparent",
                  fontFamily: "var(--primary-font)",
                  width: "100%",
                  " & .MuiOutlinedInput-root": {
                    "  &.Mui-focused fieldset": {
                      borderColor: "var(--btn-color)",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "0px solid var(--theme-color)",
                    borderRadius: "0",
                    padding: "0 14px",
                    height: "50px",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "0px solid var(--btn-color))",
                    },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "var(--btn-color)",
                    backgroundColor: "transparent",
                  },
                }}
                // value={passwordValue} // Set the value of the password field
                // onChange={(e) => setPasswordValue(e.target.value)} // Update the passwordValue state
              />
              <ErrorMessage
                name="password"
                component="span"
                className="error"
                style={errorStyle}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember me"
                  name="rememberme"
                  sx={{
                    color: "var(--black)",
                    fontFamily: "var(--primary-font)",
                  }}
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <NavLink
                  style={{
                    textDecoration: "none",
                    color: "var(--theme-color)",
                    fontFamily: "var(--primary-font)",
                  }}
                  to="/forgotpassword"
                >
                  <Typography
                    sx={{
                      color: "var(--theme-color)",
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </NavLink>
              </Box>
            </Box>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                marginTop: "20px",
                borderRadius: "5px",
                fontFamily: "var(--primary-font)",
                borderColor: "var(--theme-color)",
                color: "var(--white)",
                backgroundColor: "var(--theme-color)",
                "&:hover": {
                  backgroundColor: "var(--white)",
                  borderColor: "var(--theme-color)",
                  color: "var(--theme-color)",
                },
              }}
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Log In"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
