import React from "react";
import "./Bottombar.css";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useApi";

export const Bottombar = () => {
  const { getCategoriesdataById } = useAPI();

  const { data: subcategories } = useQuery(
    ["subcategories"],
    getDharmaGoodSubcategoriesData
  );

  async function getDharmaGoodSubcategoriesData() {
    try {
      const res = await getCategoriesdataById(endpoint.subcategories, 1);
      return res.data;
    } catch (error) {
      throw new Error("Error fetching subcategories");
    }
  }

  // if (isLoading) {
  //   return <>Loading...</>;
  // }

  return (
    <Box
      className="bottombar-container"
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "flex",
          xl: "flex",
        },
        backgroundColor: "var(--theme-color)",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
      >
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            flexDirection: "row",
            padding: "13px 0px 13px 0px",
            margin: "0",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {subcategories?.slice(0, 7).map((item, index) => {
            return (
              <li className="bar-item" key={index}>
                <NavLink
                  to={`/goods/${item.id}`}
                  style={{
                    textDecoration: "none",
                    color: "var(--white)",
                    marginRight: "20px",
                    whiteSpace: "nowrap",
                  }}
                  className="bar-NavLink"
                >
                  {item.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </Container>
    </Box>
  );
};
