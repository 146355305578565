import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { PrivacyPolicyContents } from "../../components/PrivacyPolicyComp/PrivacyPolicyContents";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const PrivacyPolicyPage = () => {
  const [language, setLanguage] = React.useState("english");
  const { getLanguage } = useAPI();
  const getData = async () => {
    const res = await getLanguage(endpoint.privacypolicy, language);
    return res.data;
  };

  const { isLoading, data: privacypolicies } = useQuery({
    queryKey: ["privacypolicies", language],
    queryFn: getData,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      {privacypolicies && (
        <Box>
          <TopSection
            image={privacypolicies?.image}
            title={privacypolicies?.top_title}
            description={`Last Updated : ${privacypolicies?.updated_date}`}
          />
        </Box>
      )}
      <Container>
        <PrivacyPolicyContents language={language} setLanguage={setLanguage} />
        <Subscribe />
      </Container>
    </Box>
  );
};
