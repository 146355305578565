import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { ProdReviews } from "../ProdReviews";
import { ProdQuestions } from "../ProdQuestions";

export const ProdReviewTabs = ({ prodreviews, prodquestions }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indicatorStyles = {
    backgroundColor: "var(--theme-color)", // Change this to your desired color
    height: "3px", // Adjust the height of the indicator if needed
  };

  return (
    <Box sx={{ marginTop: "30px" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            TabIndicatorProps={{
              style: indicatorStyles,
            }}
          >
            <Tab
              label="Reviews"
              value="1"
              sx={{
                fontFamily: "var(--primary-font)",
              }}
            />
            <Tab
              label="Questions"
              value="2"
              sx={{
                fontFamily: "var(--primary-font)",
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ProdReviews prodreviews={prodreviews} />
        </TabPanel>
        <TabPanel value="2">
          <ProdQuestions prodquestions={prodquestions} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
