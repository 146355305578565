export const paymentData = [
  {
    id: 1,
    imgUrl: "./assets/footer/khalti.png",
  },
  {
    id: 2,
    imgUrl: "./assets/footer/esewa.png",
  },
  {
    id: 3,
    imgUrl: "./assets/footer/paypal.png",
  },
  {
    id: 4,
    imgUrl: "./assets/footer/skrill.png",
  },
  {
    id: 5,
    imgUrl: "./assets/footer/mastercard.png",
  },
  {
    id: 6,
    imgUrl: "./assets/footer/visa.png",
  },
];
