import { createSlice } from "@reduxjs/toolkit";
// import { GoodDharmaTopContentData } from "../Data/GoodDharmaPageData/GoodDharmaPageData";

const initialState = {
  gooddharmacart: [],
  items: [],
  gooddharmatotalQuantity: 0,
  gooddharmatotalPrice: 0,
};

export const gooddharmaCartSlice = createSlice({
  name: "gooddharmacart",
  initialState,
  reducers: {
    // Initialize items with the fetched data
    setGoodDharmaData: (state, action) => {
      state.items = action.payload;
    },
    addToGooddharmaCart: (state, action) => {
      const find = state.gooddharmacart.findIndex(
        (item) => item.id === action.payload.id
      );

      if (find >= 0) {
        // Increase the quantity of the existing item
        state.gooddharmacart[find].quantity += 1;
      } else {
        // Add the item to the cart
        state.gooddharmacart.push(action.payload);
      }
      // Save updated cart data to localStorage
      localStorage.setItem(
        "gooddharmacart",
        JSON.stringify(state.gooddharmacart)
      );
    },
    getGooddharmaCartTotal: (state) => {
      const { gooddharmatotalPrice, gooddharmatotalQuantity } =
        state.gooddharmacart.reduce(
          (cartTotal, cartItem) => {
            const { price } = cartItem; // There's no need for the 'quantity' here
            const itemTotal = price;
            cartTotal.gooddharmatotalPrice += itemTotal;
            cartTotal.gooddharmatotalQuantity += 1; // Increment total quantity by 1 for each item
            return cartTotal;
          },
          {
            gooddharmatotalPrice: 0,
            gooddharmatotalQuantity: 0,
          }
        );

      state.gooddharmatotalPrice = parseFloat(gooddharmatotalPrice.toFixed(2));
      state.gooddharmatotalQuantity = gooddharmatotalQuantity;
    },

    updateCartItems: (state, action) => {
      const { id, formattedDate, selectedService, price } = action.payload;
      const selectedItem = state.items.find((item) => item.id === id);

      if (selectedItem) {
        const updatedItems = state.items.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              date: formattedDate,
              service: selectedService,
              price: price,
            };
          }
          return item;
        });

        state.items = updatedItems;
      }

      // Update the corresponding item in the cart if present
      const cartItem = state.gooddharmacart.find((item) => item.id === id);
      if (cartItem) {
        cartItem.date = formattedDate;
        cartItem.service = selectedService;
        cartItem.price = price;
      }
    },

    removeGooddharmaItem: (state, action) => {
      const removedItemId = action.payload;

      // Remove the item from the cart
      state.gooddharmacart = state.gooddharmacart.filter(
        (item) => item.id !== removedItemId
      );

      // Save updated cart data to localStorage
      localStorage.setItem(
        "gooddharmacart",
        JSON.stringify(state.gooddharmacart)
      );
    },
    // Add a new action to load cart data from localStorage
    loadGooddharmaCartFromLocalStorage: (state) => {
      const cartData = localStorage.getItem("gooddharmacart");
      if (cartData) {
        state.dharmagoodcart = JSON.parse(cartData);
      }
    },
  },
});

export const {
  addToGooddharmaCart,
  getGooddharmaCartTotal,
  removeGooddharmaItem,
  updateCartItems,
  loadGooddharmaCartFromLocalStorage,
  setGoodDharmaData,
} = gooddharmaCartSlice.actions;
export default gooddharmaCartSlice.reducer;
