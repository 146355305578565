import React from "react";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { LandingSearchComponent } from "../../components/LandingSearchComponent/LandingSearchComponent";
import { useLocation } from "react-router-dom";

export const LandingSearchPage = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("q");

  return (
    <Box>
      <Box>
        <TopSection
          title={"Search"}
          description={"Search for Good Dharma and Dharma Goods"}
        />
      </Box>
      <Container>
        <LandingSearchComponent query={query} />
      </Container>
    </Box>
  );
};
