import React, { useRef } from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import ordericon from "../assets/ordericon.png";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useNavigate } from "react-router-dom";

const horizontal_line = {
  width: "100%",
  height: "1px",
  backgroundColor: "var(--title-backgrd-color)",
};
export const ThankyouforOrder = ({ handleCloseOrder }) => {
  const navigate = useNavigate();
  const printableContentRef = useRef(null);

  const printContent = () => {
    const printableContent = printableContentRef.current;
    if (!printableContent) {
      console.error("Printable content not found.");
      return;
    }

    const printWindow = window.open("", "", "height=1000,width=1000");

    // Create an HTML document for printing with inline styles
    const printDocument = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Print Invoice</title>
      </head>
      <body>
        <div style="your-inline-styles-here">
          ${printableContent.innerHTML}
        </div>
      </body>
      </html>
    `;

    printWindow.document.write(printDocument);
    printWindow.print();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: {
            xs: "auto",
            sm: "50vh",
            md: "50vh",
            lg: "70vh",
            xl: "70vh",
          }, // Center vertically at every breakpoint
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          position: "relative",
          backgroundColor: "var(--white)",
          borderRadius: "10px",
          padding: "10px",
          width: {
            xs: "95%",
            sm: "75%",
            md: "75%",
            lg: "45%",
            xl: "45%",
          },
          borderColor: "var(--white)",
          marginTop: {
            xs: "5%",
            sm: "1.5%",
            md: "1.5%",
            lg: "2%",
            xl: "2%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
          }}
        >
          <div style={horizontal_line}></div>
          <div style={horizontal_line}></div>
          <div style={horizontal_line}></div>

          <img
            src={ordericon}
            alt="..."
            style={{
              width: "80px",
              zIndex: "1",
              // bottom: "60px",
              top: "20px",
              position: "absolute",
            }}
            loading="lazy"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "16px",
                lg: "18px",
                xl: "28px",
              },
              fontWeight: "500",
              color: "var(--theme-color)",
              fontFamily: "var(--primary-font)",
              marginTop: "30px",
              marginBottom: "20px",
            }}
          >
            Thank you for your order
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              width: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              fontSize: {
                xs: "13px",
                sm: "14px",
                md: "15px",
                lg: "16px",
                xl: "16px",
              },
              fontWeight: "400",
              color: "var(--black-color)",
              fontFamily: "var(--primary-font)",
              marginBottom: {
                xs: "20px",
                sm: "50px",
                md: "50px",
                lg: "50px",
                xl: "50px",
              },
            }}
          >
            You have successfully placed your order .You will receive your order
            in the specific date as per requested. Thank you for purchasing with
            us. Keep supporting.
          </Typography>
          <Box
            sx={{
              width: {
                xs: "35vw",
                sm: "20vw",
                md: "20vw",
                lg: "15vw",
                xl: "15vw",
              },
              height: "auto",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
              alt="..."
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              loading="lazy"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              fontSize: {
                xs: "8px",
                sm: "11px",
                md: "11px",
                lg: "13px",
                xl: "13px",
              },
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              borderColor: "var(--theme-color)",
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--theme-color)",
                borderColor: "var(--theme-color)",
              },
            }}
            onClick={printContent}
          >
            {" "}
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <LocalPrintshopIcon
                fontSize="small"
                sx={{
                  marginRight: "10px",
                }}
              />
            </Box>
            Print Order Details
          </Button>
          <Box
            sx={{
              display: "none",
            }}
            ref={printableContentRef}
            id="printable-content"
          >
            {/* <Invoice /> */}
          </Box>
          <Button
            variant="outlined"
            sx={{
              fontSize: {
                xs: "8px",
                sm: "11px",
                md: "11px",
                lg: "13px",
                xl: "13px",
              },
              marginLeft: {
                xs: "10px",
                sm: "0px",
                md: "0px",
                lg: "0px",
                xl: "0px",
              },
              backgroundColor: "var(--white)",
              color: "var(--btn-color)",
              borderColor: "var(--btn-color)",
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--theme-color)",
                borderColor: "var(--theme-color)",
              },
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <LocalPrintshopIcon
                fontSize="small"
                sx={{
                  marginRight: "10px",
                }}
              />
            </Box>
            Print Receipt
          </Button>
          <Button
            variant="outlined"
            sx={{
              textTransform: "inherit",
              fontSize: {
                xs: "8px",
                sm: "11px",
                md: "11px",
                lg: "13px",
                xl: "13px",
              },
              marginLeft: {
                xs: "10px",
                sm: "0px",
                md: "0px",
                lg: "0px",
                xl: "0px",
              },
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              borderColor: "var(--theme-color)",
              "&:hover": {
                backgroundColor: "var(--white)",
                color: "var(--theme-color)",
                borderColor: "var(--theme-color)",
              },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            Return to Homepage
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
