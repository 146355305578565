import React from "react";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import { Container, Skeleton } from "@mui/material";
import { SearchSection } from "../../components/GoodDharma/GoodDharmaSearchSection/GoodDharmaSearchSection";
import { Testimonial } from "../../components/Shared/Testimonial/Testimonial";
import manicon from "../../components/assets/manicon.png";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import { GoodDharma } from "../../components/LandingPage/GoodDharma/GoodDharma";
import Vector from "../../../src/components/assets/Vector.png";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import useAPI from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { endpoint } from "../../constant/endpoint";
import { Typography } from "@mui/material";

export const GoodDharmaPage = () => {
  const { get } = useAPI();

  const getCategoriesData = async () => {
    const response = await get(endpoint.dharmas);
    return response.data;
  };

  const getTestimonialData = async () => {
    const res = await get(endpoint.good_dharma_testimonials);
    return res.data;
  };

  const { isLoading: categoryLoading, data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoriesData,
  });

  const { isLoading: testimonialLoading, data: testimonial } = useQuery({
    queryKey: ["testimonial"],
    queryFn: getTestimonialData,
  });

  const GoodDharmaData = categories?.filter(
    (item) => item.name === "Good Dharma"
  );

  const Name = GoodDharmaData?.map((item) => item.name);
  const id = GoodDharmaData?.map((item) => item.id);

  const getGoodDharmaSubcategoriesData = async () => {
    const response = await get(endpoint.all_good_dharma);
    return response.data;
  };

  const { isLoading: gooddharmaCtegoriesLoading, data: gooddharmacategories } =
    useQuery(["gooddharmacategories"], getGoodDharmaSubcategoriesData);

  const GoodDharmaDataLength = gooddharmacategories?.length;

  if (testimonialLoading || gooddharmaCtegoriesLoading) {
    return (
      <>
        <Skeleton variant="text" width="100%" height="20px" />
        <Skeleton variant="text" width="100%" height="20px" />
      </>
    );
  }

  if (categoryLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {GoodDharmaData?.map((item, index) => {
        return (
          <Box key={index}>
            <TopSection
              title={item.name}
              description={item.description}
              id={item.id}
              image={item.image}
            />
          </Box>
        );
      })}
      <Container>
        <SearchSection
          sorting="Default Searching"
          btnlabel="Filter"
          GoodDharmaDataLength={GoodDharmaDataLength}
          Name={Name}
          id={id}
        />
      </Container>
      <Container>
        <HorizontalLines imgUrl={`${manicon}`} />
      </Container>
      <Testimonial data={testimonial} />
      <Container
        sx={{
          marginTop: {
            xs: "80px",
            sm: "80px",
            md: "0px",
            lg: "0px",
            xl: "0px",
          },
        }}
      >
        <HorizontalLines imgUrl={`${Vector}`} marginTop={"40px"} />
        <GoodDharma />
        <Subscribe />
      </Container>
    </Box>
  );
};
