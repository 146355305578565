import React from "react";
import { Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const TopPathBar = () => {
  const { id } = useParams();
  const { getById } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.projects, id);
    return res.data;
  };

  const { isLoading, data: runnningprojectDetails } = useQuery({
    queryKey: ["runningprojectsDetails"],
    queryFn: getData,
  });

  if (isLoading) return <>Loading...</>;
  return (
    <Box
      sx={{
        backgroundColor: "var(--pathbar-color)",
        width: "100%",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "15px",
        }}
      >
        <Typography
          variant="p"
          sx={{
            color: "var(--light-black)",
            fontFamily: "var(--primary-font)",
            fontSize: {
              xs: "11px",
              sm: "12px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
          }}
        >
          Home | Project | {""}
          <span
            style={{
              color: "var(--theme-color)",

              fontFamily: "var(--primary-font)",
              fontWeight: "500",
            }}
          >
            {runnningprojectDetails?.title}
          </span>
        </Typography>
      </Container>
    </Box>
  );
};
