import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Typography } from "@mui/material";
import { set } from "date-fns";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--light-grey)",
    color: "var(--black)",
    fontWeight: "bold",
    fontFamily: "var(--primary-font)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  fontFamily: "var(--primary-font)",
  "& > td": {
    padding: "20px 10px 20px 10px", // Adjust the padding value as per your preference
    fontWeight: 600, // Set the font weight to 600 for the cells in the row
  },
}));

export const DharmaGoodsWishlist = () => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [id, setId] = useState(""); // Initialize the id state
  const [cookies] = useCookies(["userId", "token"]);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      setId(cookies.userId); // Set the token state with the token from cookies

      // You can also perform other actions that depend on the token here
    }
  }, [cookies]);

  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const { getWishlistById } = useAPI();

  const getData = async () => {
    const response = await getWishlistById(endpoint.getgoodsbyId);
    return response.data;
  };

  const { isLoading, data: dharmagoodwishlist } = useQuery({
    queryKey: ["dharmagoodwishlist", id],
    queryFn: getData,
    enabled: !!id,
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: "50px",
        boxShadow: "none", // Remove the box shadow
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Dharma Goods</StyledTableCell>
            <StyledTableCell align="center">Price</StyledTableCell>
            <StyledTableCell align="right">Stock</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <Box
          sx={{
            marginBottom: "20px",
          }}
        ></Box>
        <TableBody>
          {dharmagoodwishlist ? (
            dharmagoodwishlist?.map((item) => (
              <StyledTableRow key={item.product.name}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    fontFamily: "var(--primary-font)",
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      padding: "3px",
                      borderRadius: "5px",
                      marginRight: "40px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--theme-color)",
                        borderColor: "var(--theme-color)",
                      },
                    }}
                    onClick={async () => {
                      try {
                        const res = await axios.delete(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/${item.id}/wishlist`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );

                        if (res.status === 201) {
                          queryClient.invalidateQueries("dharmagoodwishlist");
                          toast.success(
                            "Product removed to wishlist successfully"
                          );
                        } else {
                          toast.error("Something went wrong");
                        }
                      } catch (error) {
                        if (error.response.status === 401) {
                          toast.error("Unauthorized access");
                        }
                        toast.error("Something went wrong", error);
                      }
                    }}
                  >
                    <CloseIcon
                      fontSize="large"
                      sx={{
                        color: "var(--white)",
                        "&:hover": {
                          color: "var(--theme-color)",
                        },
                      }}
                    />
                  </Button>
                  <Box
                    sx={{
                      width: "100px",
                      height: "80px",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.product.image[0].image_path}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      loading="lazy"
                    />
                  </Box>
                  {item.product.name}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "var(--theme-color)",
                  }}
                >
                  {selectedCurrency} {(item.product.price / rate).toFixed(2)}
                </StyledTableCell>
                {item.product.qty > 0 ? (
                  <StyledTableCell
                    align="right"
                    sx={{
                      color: "var(--stock-green)",
                    }}
                  >
                    stock left
                  </StyledTableCell>
                ) : (
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "red",
                    }}
                  >
                    Out of stock
                  </StyledTableCell>
                )}

                <StyledTableCell align="right">
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      color: "var(--white)",
                      borderRadius: "5px",
                      marginTop: "20px",
                      border: "1px solid var(--theme-color)",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "11px",
                        lg: "12px",
                        xl: "12px",
                      },
                      marginRight: "20px",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--theme-color)",
                        border: "1px solid var(--theme-color)",
                      },
                    }}
                    onClick={async () => {
                      const data = {
                        product_id: item.product.product_id,
                      };
                      try {
                        const res = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/move-to-cart/${item.product.product_id}`,
                          data,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        if (res.status === 201) {
                          queryClient.invalidateQueries("dharmagoodwishlist");
                          queryClient.invalidateQueries("dharmagoodcart");
                          toast.success("Product moved to cart successfully");
                        } else {
                          toast.error("Something went wrong");
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <ShoppingBagOutlinedIcon
                      fontSize="medium"
                      sx={{
                        marginRight: "5px",
                      }}
                    />
                    Add to cart
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={5} align="center">
                No items in the wishlist.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
