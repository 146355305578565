import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Button, Typography, Grid } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--theme-color)",
    color: "var(--white)",
    fontWeight: "bold",
    fontFamily: "var(--primary-font)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  fontFamily: "var(--primary-font)",
  "& > td": {
    padding: "20px 10px 20px 10px", // Adjust the padding value as per your preference
    fontWeight: 600, // Set the font weight to 600 for the cells in the row
  },
  "&:last-child th, &:last-child td": {
    borderBottom: 0,
  },
}));

export const GoodDharmaWishlist = () => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [id, setID] = useState("");
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      setID(cookies.userId);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies]);
  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const { getWishlistById } = useAPI();

  const queryClient = useQueryClient();

  const getData = async () => {
    const response = await getWishlistById(endpoint.all_good_dharma);
    return response.data;
  };

  const { isLoading, data: gooddharmawishlist } = useQuery({
    queryKey: ["gooddharmawishlist", id],
    queryFn: getData,
    enabled: !!id,
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: "70px",
        boxShadow: "none", // Remove the box shadow
      }}
    >
      <Table
        sx={{
          minWidth: {
            xs: "300",
            sm: "700",
            md: "700",
            lg: "700",
            xl: "700",
          },
        }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Dharma Goods</StyledTableCell>
          </TableRow>
        </TableHead>
        <Box
          sx={{
            marginBottom: "20px",
          }}
        ></Box>
        <TableBody>
          {gooddharmawishlist ? (
            gooddharmawishlist?.map((item) => (
              <StyledTableRow key={item.service_id}>
                <StyledTableCell component="th" scope="row">
                  <Grid
                    container
                    xs={12}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                  >
                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                      <Box
                        sx={{
                          width: "100% ",
                          height: "200px",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={item.image[0]?.image}
                          alt="..."
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                          loading="lazy"
                        />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{
                        padding: {
                          xs: "10px 0px 0px 0px",
                          sm: "20px",
                          md: "20px",
                          lg: "20px",
                          xl: "20px",
                        },
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ color: "var(--light-black)", fontWeight: "600" }}
                      >
                        {item.service.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "var(--gray)",
                          overflow: "hidden", // Hide any overflowing text
                          textOverflow: "ellipsis", // Display ellipsis for long texts
                          display: "-webkit-box",
                          WebkitLineClamp: 2, // Adjust the number of lines to fit your requirements
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.service.description}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "var(--theme-color)",
                          fontWeight: "600",
                          marginTop: "20px",
                        }}
                      >
                        {selectedCurrency}{" "}
                        {(item.sizes[0].price / rate).toFixed(2)}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: {
                          xs: "flex-start",
                          sm: "flex-end",
                          md: "flex-end",
                          lg: "flex-end",
                          xl: "flex-end",
                        },
                        alignItems: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                          borderRadius: "5px",
                          marginTop: "20px",
                          border: "1px solid var(--theme-color)",
                          textTransform: "capitalize",
                          fontSize: {
                            xs: "10px",
                            sm: "11px",
                            md: "11px",
                            lg: "12px",
                            xl: "12px",
                          },
                          marginRight: "20px",
                          padding: "10px",
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "var(--theme-color)",
                            border: "1px solid var(--theme-color)",
                          },
                        }}
                        onClick={async () => {
                          const data = {
                            service_id: item.service.service_id,
                          };
                          try {
                            const res = await axios.post(
                              process.env.REACT_APP_JINLAP_BASE_URL +
                                `/services/move-to-cart/${item.service.service_id}`,
                              data,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                },
                              }
                            );
                            if (res.status === 201) {
                              queryClient.invalidateQueries(
                                "gooddharmawishlist"
                              );
                              queryClient.invalidateQueries("gooddharmacart");
                              toast.success(
                                "Service moved to cart successfully"
                              );
                            } else {
                              toast.error("Something went wrong");
                            }
                          } catch (error) {
                            toast.error(error.response.data.message);
                          }
                        }}
                      >
                        <ShoppingBagOutlinedIcon
                          fontSize="medium"
                          sx={{
                            marginRight: "5px",
                          }}
                        />
                        Add to cart
                      </Button>
                      <Box
                        sx={{
                          backgroundColor: "var(--theme-color)",
                          padding: "3px",
                          borderRadius: "5px",
                          marginTop: {
                            xs: "20px",
                            sm: "15px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                          "&:hover": {
                            backgroundColor: "transparent",
                            color: "var(--theme-color)",
                            borderColor: "var(--theme-color)",
                          },
                        }}
                        onClick={async () => {
                          try {
                            const res = await axios.delete(
                              process.env.REACT_APP_JINLAP_BASE_URL +
                                `/services/${item.id}/wishlist`,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                },
                              }
                            );
                            if (res.status === 201) {
                              queryClient.invalidateQueries(
                                "gooddharmawishlist"
                              );
                              toast.success(
                                "Service removed from wishlist successfully"
                              );
                            } else {
                              toast.error("Something went wrong");
                            }
                          } catch (error) {
                            toast.error(error.response.data.message);
                          }
                        }}
                      >
                        <CloseIcon
                          fontSize="large"
                          sx={{
                            color: "var(--white)",
                            "&:hover": {
                              color: "var(--theme-color)",
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={5} align="center">
                No items in the cart.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
