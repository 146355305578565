import React from "react";
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

const hr_line = {
  width: "100%",
  height: "1px",
  backgroundColor: "#E4E4E4",
  marginTop: "20px",
  marginBottom: "20px",
};

export const Donation = () => {
  const { id } = useParams();
  const { getById } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.pastprjdonation, id);
    return res.data;
  };

  const { isLoading, data: donationData } = useQuery({
    queryKey: ["donationData", id],
    queryFn: getData,
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }
  return (
    <Box
      sx={{
        // marginTop: "0px",
        marginBottom: "50px",
      }}
    >
      <Box sx={hr_line}></Box>
      <Box>
        <Typography
          variant="h4"
          sx={{
            color: "var(--theme-color)",
            fontWeight: "bold",
            marginTop: "30px",
            fontSize: {
              xs: "14px",
              sm: "14px",
              md: "15px",
              lg: "16px",
              xl: "16px",
            },
            fontFamily: "var( --primary-font)",
          }}
        >
          KUDOS TO ALL THE CONTRIBUTOR FOR SUPPORTING OUR PROJECTS
        </Typography>
      </Box>
      {donationData?.map((item, key) => (
        <Grid container key={key}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Box
              sx={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {item.user ? (
                <Box
                  sx={{
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <img
                    src={item.user}
                    alt="..."
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "50%",
                    }}
                    loading="lazy"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: {
                      xs: "10vw",
                      sm: "6vw",
                      md: "6vw",
                      lg: "3vw",
                      xl: "3vw",
                    },
                    height: "6vh",
                    backgroundColor: "#5D8AA8",
                    borderRadius: "50%",
                    color: "(--white)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      color: "var(--white)",
                      fontFamily: "var(--font-family)",
                      fontSize: {
                        xs: "15px",
                        sm: "17px",
                        md: "18px",
                        lg: "25px",
                        xl: "25px",
                      },
                      textTransform: "capitalize",
                    }}
                  >
                    {item.payer_first_name[0]}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  marginLeft: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    className="donation-title"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                      fontFamily: "var(--primary-font)",
                      color: "var(--black)",
                      fontWeight: "bold",
                    }}
                  >
                    {item.payer_first_name} {""} {item.payer_second_name},{" "}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="donation-description"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      color: "var(--black)",
                      fontWeight: "bold",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                  >
                    {item.payer_country}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    className="donation-description"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                      color: "var(--theme-color)",
                      fontWeight: "bold",
                      marginRight: "10px",
                    }}
                  >
                    {item.currency} {""} {item.amount}
                  </Typography>
                  <span
                    style={{
                      marginRight: "10px",
                      color: "var(--light-black)",
                    }}
                  >
                    |
                  </span>
                  <Typography
                    variant="body1"
                    className="donation-description"
                    sx={{
                      fontSize: {
                        xs: "9px",
                        sm: "10px",
                        md: "12px",
                        lg: "12px",
                        xl: "12px",
                      },
                      fontFamily: "var(--primary-font)",
                      color: "var(--light-black)",
                      fontWeight: "bold",
                    }}
                  >
                    {/* {item.donationType} */}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
