import React from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/system";

export const StarRating = ({ rating }) => {
  return (
    <Box>
      <Stack spacing={1}>
        <Rating
          name="half-rating-read"
          defaultValue={rating}
          precision={0.5}
          readOnly
        />
      </Stack>
    </Box>
  );
};
