import React from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Allgoods } from "./Allgoods";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import { CategoriesData } from "../../../ApiIntegration/LandingPageApi/Categories";
import { CategoryGoods } from "./CategoryGoods";

const btn = {
  margin: "0.3rem",
  backgroundColor: "var(--white)",
  color: "var(--theme-color)",
  height: "30px",
  padding: "10px",
  borderRadius: "10px",
  border: "0.5px solid #F5F5F5",
  boxShadow: "0px 0px 0px 0px",
  //HOVER
  "&:hover": {
    backgroundColor: "var(--theme-color)",
    color: "var(--white)",
    border: "0.5px solid var(--theme-color)",
  },
};

export const Goods = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");
  const handleOptionClick = (option) => {
    setSelected(option);
  };
  const [categories, setCategories] = React.useState([]);

  const [dharmagoodcategories, setDharmagoodcategories] = React.useState([]);

  React.useEffect(() => {
    const fetchCategoriesDataAsync = async () => {
      try {
        const apiData = await CategoriesData();
        setCategories(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategoriesDataAsync();
  }, []);

  const DharmaGoodData = categories?.filter(
    (item) => item.name === "Dharma Goods"
  );

  // const _id = DharmaGoodData.map((item) => item.id);

  React.useEffect(() => {
    const fetchDharmagoodCategoriesDataAsync = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_JINLAP_BASE_URL + `/categories/1/subcategories`
        );

        setDharmagoodcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDharmagoodCategoriesDataAsync();
  }, []);

  const renderSelectedComponent = () => {
    switch (selected) {
      case "all":
        return <Allgoods />;

      default:
        // Find the selected category in the dharmagoodcategories array
        const selectedCategory = dharmagoodcategories?.find(
          (item) => item.name === selected
        );

        if (selectedCategory) {
          return (
            <CategoryGoods
              category={selectedCategory.name}
              id={selectedCategory.id}
            />
          );
        } else {
          return <Allgoods />;
        }
    }
  };
  return (
    <div style={{ marginBottom: "80px" }}>
      <h5 style={{ color: "var(--theme-color)", marginBottom: "20px" }}>
        DHARMA GOODS
      </h5>
      <h2 style={{ color: "var(--light-black)", marginBottom: "50px" }}>
        CRAFTED BY HANDS, MADE WITH SOUL
      </h2>
      {/* //only 4 item in one row while mapping*/}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" },
          margin: "auto",
          marginBottom: "50px",
        }}
      >
        <Button
          variant="contained"
          sx={btn}
          to="#all"
          onClick={() => handleOptionClick("all")}
        >
          ALL
        </Button>
        {dharmagoodcategories?.slice(0, 4).map((item, key) => (
          <Button
            variant="contained"
            sx={btn}
            to={`#${item.name}`}
            onClick={() => handleOptionClick(item.name)}
            key={key}
          >
            {item.name}
          </Button>
        ))}
        <Button
          variant="contained"
          sx={btn}
          onClick={() => navigate("/dharma-goods")}
        >
          MORE <KeyboardArrowRightIcon />
        </Button>
      </Box>
      {renderSelectedComponent()}
      {dharmagoodcategories?.length > 0 && (
        <Button
          // variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            width: { xs: "100%", sm: "100%", md: "15%", lg: "15%", xl: "15%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={() => navigate("/dharma-goods")}
        >
          SHOW MORE
          <ChevronRightIcon />
        </Button>
      )}
    </div>
  );
};
