import React from "react";
import "./All.css";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { All } from "./All";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GreenTaraPuja } from "./GreenTaraPuja";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";

const btn = {
  margin: "0.3rem",
  backgroundColor: "var(--white)",
  color: "var(--theme-color)",
  height: "30px",
  padding: "10px",
  borderRadius: "10px",
  border: "0.5px solid #F5F5F5",
  boxShadow: "0px 0px 0px 0px",
  //HOVER
  "&:hover": {
    backgroundColor: "var(--theme-color)",
    color: "white",
    border: "0.5px solid var(--theme-color)",
  },
};

export const Blog = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("");

  const handleOptionClick = (option) => {
    setSelected(option);
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.all_good_dharma);
    return res.data;
  };

  const { isLoading, data: allGoodDharma } = useQuery({
    queryKey: ["allGoodDharma"],
    queryFn: getData,
  });

  if (isLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height="80vh" />
      </>
    );

  const renderSelectedComponent = () => {
    switch (selected) {
      case "all":
        return <All />;
      default:
        // Find the selected category in the dharmagoodcategories array
        const selectedCategory = allGoodDharma?.find(
          (item) => item.name === selected
        );

        if (selectedCategory) {
          return (
            <GreenTaraPuja
              name={selectedCategory.name}
              id={selectedCategory.id}
            />
          );
        } else {
          return <All />;
        }
    }
  };

  return (
    <Box
      // className="blogall"
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "column",
          xl: "column",
        },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "var(--theme-color)",
          fontSize: {
            xs: "15px",
            sm: "18px",
            md: "20px",
            lg: "20px",
            xl: "20px",
          },
          fontFamily: "var(--primary-font)",
          textAlign: "center",
        }}
      >
        GOOD DHARMA
      </Typography>
      <br />
      <Typography
        variant="p"
        sx={{
          color: "var(--light-black)",
          fontSize: {
            xs: "18px",
            sm: "20px",
            md: "25px",
            lg: "25px",
            xl: "25px",
          },
          marginTop: "10px",
        }}
      >
        EMPOWERED BY COMPASSION, FUELED WITH WISDOM
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          width: "100%",
          margin: "auto",
          marginBottom: "50px",
          marginTop: "50px",
        }}
      >
        <Button
          variant="contained"
          sx={btn}
          to="#all"
          onClick={() => handleOptionClick("all")}
        >
          ALL
        </Button>
        {allGoodDharma?.slice(0, 4).map((item) => (
          <Button
            variant="contained"
            sx={btn}
            to={`#${item.slug}`}
            onClick={() => handleOptionClick(item.name)}
            key={item.name}
          >
            {item.name}
          </Button>
        ))}
        <Button
          variant="contained"
          sx={btn}
          to="#more"
          onClick={() => navigate("/good-dharma")}
        >
          MORE <KeyboardArrowRightIcon />
        </Button>
      </Box>
      {renderSelectedComponent()}

      {allGoodDharma?.length > 0 && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            width: { xs: "100%", sm: "100%", md: "15%", lg: "15%", xl: "15%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "white",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={() => navigate("/good-dharma")}
        >
          Show More
          <ChevronRightIcon />
        </Button>
      )}
    </Box>
  );
};
