import React from "react";
import "./Horizontalline.css";
import { Box } from "@mui/system";

const HorizontalLines = ({ imgUrl, title }) => {
  return (
    <Box>
      <div className="horizontal-line"></div>
      <div className="horizontal-line"></div>
      <div className="horizontal-line"></div>
      {imgUrl ? (
        <img
          // src={process.env.PUBLIC_URL + "/assets/Vector.png"}
          src={imgUrl}
          alt="..."
          style={{
            width: "25px",
            zIndex: "1",
            bottom: "30px",
            position: "relative",
          }}
          loading="lazy"
        />
      ) : (
        <div
          style={{
            zIndex: "1",
            bottom: "30px",
            position: "relative",
            textAlign: "center",
          }}
        >
          <h5
            style={{
              color: "var(--theme-color)",
              backgroundColor: "var( --title-backgrd-color)",
              padding: "10px",
              display: "inline-block",
            }}
          >
            {title}
          </h5>
        </div>
      )}
    </Box>
  );
};

export default HorizontalLines;
