import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoint } from "../../../constant/endpoint";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--light-grey)",
    color: "var(--black)",
    fontWeight: "bold",
    fontFamily: "var(--primary-font)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  fontFamily: "var(--primary-font)",
  "& > td": {
    padding: "20px 10px 20px 10px",
    fontWeight: 600,
  },
}));

export const DharmaGoodCart = () => {
  const { selectedCurrency, rate } = useSelector((state) => state.currency);
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
    }
  }, [cookies.token]);

  const queryClient = useQueryClient();

  const { updatedCart, getCartById } = useAPI();

  const getData = async () => {
    const response = await getCartById(endpoint.getgoodsbyId);
    return response.data;
  };

  const { isLoading, data: dharmagoodcart } = useQuery({
    queryKey: ["dharmagoodcart"],
    queryFn: getData,
  });

  const { mutate: mutateCart } = useMutation(updatedCart, {
    onSuccess: () => {
      toast.success("Cart updated successfully");
    },
    onError: (errors) => {
      toast.error(errors.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries("dharmagoodcart");
    },
  });

  const handleQuantityChange = async (itemId, newQty) => {
    // Update local state immediately
    const updatedCart = dharmagoodcart.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          qty: newQty,
          total_price: item.price * newQty,
        };
      }
      return item;
    });

    const UpdatedData = {
      id: itemId,
      data: {
        qty: newQty,
        price: updatedCart.find((item) => item.id === itemId)?.price,
        total_price: updatedCart.find((item) => item.id === itemId)
          ?.total_price,
      },
    };
    mutateCart(UpdatedData);
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: "50px",
        boxShadow: "none",
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Dharma Goods</StyledTableCell>
            <StyledTableCell align="center">Unit Price</StyledTableCell>
            <StyledTableCell align="center">Quantity</StyledTableCell>
            <StyledTableCell align="center">Total Price</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <Box
          sx={{
            marginBottom: "20px",
          }}
        ></Box>
        <TableBody>
          {dharmagoodcart ? (
            dharmagoodcart.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "10px",
                    fontFamily: "var(--primary-font)",
                  }}
                >
                  <Box
                    sx={{
                      width: "100px",
                      height: "80px",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.image[0]?.image_path}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      loading="lazy"
                    />
                  </Box>
                  {item.product.name}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "var(--theme-color)",
                  }}
                >
                  {selectedCurrency} {(item.price / rate).toFixed(2)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #E4E4E4",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        width: {
                          xs: "0",
                          sm: "70%",
                          md: "70%",
                          lg: "50%",
                          xl: "50%",
                        },
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleQuantityChange(item.id, item.qty - 1)
                        }
                        disabled={item.qty === 1}
                        sx={{
                          color: "var(--black)",
                          backgroundColor: "transparent",
                          textTransform: "capitalize",
                          "&:hover": {
                            color: "var(--theme-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <RemoveIcon fontSize="small" />
                      </Button>
                      {item.qty}
                      <Button
                        onClick={() =>
                          handleQuantityChange(item.id, item.qty + 1)
                        }
                        disabled={item.qty >= 10}
                        sx={{
                          color: "var(--black)",
                          textTransform: "capitalize",
                          backgroundColor: "transparent",
                          "&:hover": {
                            color: "var(--theme-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "var(--theme-color)",
                  }}
                >
                  {isNaN((item.price / rate).toFixed(2)) || isNaN(item.qty)
                    ? "Invalid Price"
                    : `${selectedCurrency} ${(
                        (item.price * item.qty) /
                        rate
                      ).toFixed(2)}`}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    onClick={async () => {
                      try {
                        const response = await axios.delete(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/${item.id}/cart`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        if (response.status === 201) {
                          queryClient.invalidateQueries("dharmagoodcart");
                          toast.success(
                            `${item.product.name} removed from cart successfully  `
                          );
                        } else {
                          toast.error("Something went wrong");
                        }
                      } catch (error) {
                        if (error.response.status === 401) {
                          toast.error("Unauthorized access");
                        } else {
                          toast.error("Something went wrong", error);
                        }
                      }
                    }}
                  >
                    <CloseIcon
                      fontSize="large"
                      sx={{
                        color: "var(--white)",
                        backgroundColor: "var(--theme-color)",
                        "&:hover": {
                          color: "var(--theme-color)",
                          backgroundColor: "var(--white)",
                        },
                      }}
                    />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={5} align="center">
                No items in the cart.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          margin: "20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            fontSize: {
              xs: "13px",
              sm: "13px",
              md: "14px",
              lg: "15px",
              xl: "15px",
            },
            fontFamily: "var(--primary-font)",
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
          }}
        >
          DHARMA GOOD SUBTOTAL: {""}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "var(--theme-color)",
            marginleft: "20px",
            fontSize: {
              xs: "14px",
              sm: "15px",
              md: "16px",
              lg: "18px",
              xl: "18px",
            },
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
            fontFamily: "var(--primary-font)",
          }}
        >
          {selectedCurrency}{" "}
          {dharmagoodcart
            ? (
                dharmagoodcart?.reduce(
                  (acc, item) => acc + item.qty * item.price,
                  0
                ) / rate
              ).toFixed(2)
            : 0}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "1px",
          backgroundColor: "#E4E4E4",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      ></Box>
    </TableContainer>
  );
};
