import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TopContent } from "./TopContent";
import { useParams } from "react-router-dom";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const PastInitiativesContentSection = ({ pastprojectDetails }) => {
  const { id } = useParams();

  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isLoading, data: siteSettings } = useQuery({
    queryKey: ["siteSettings"],
    queryFn: getData,
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box
      sx={{
        marginTop: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "20px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "30%",
              sm: "10%",
              md: "10%",
              lg: "8%",
              xl: "5%",
            },
            height: "auto",
          }}
        >
          <img
            src={siteSettings?.secondary_logo}
            alt="projectlogo "
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              objectPosition: "center",
            }}
            loading="lazy"
          />
        </Box>
        <Box
          sx={{
            textAlign: "justify",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "19px",
                sm: "20px",
                md: "22px",
                lg: "24px",
                xl: "24px",
              },

              color: "var(--black)",
              fontFamily: "var( --primary-font)",
            }}
          >
            {pastprojectDetails?.title}
          </Typography>
        </Box>
      </Box>
      <Box>
        <TopContent />
      </Box>
    </Box>
  );
};
