import React from "react";
import { Typography, Container } from "@mui/material";
import { Box } from "@mui/system";

export const WishlistTopPathBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: "var(--pathbar-color)",
        width: "100%",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "15px",
        }}
      >
        <Typography
          variant="p"
          sx={{
            color: "var(--light-black)",
            fontFamily: "var(--primary-font)",
            fontSize: {
              xs: "11px",
              sm: "12px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
          }}
        >
          Home | {""}
          <span
            style={{
              color: "var(--theme-color)",
              fontWeight: "500",
              fontFamily: "var(--primary-font)",
            }}
          >
            Wishlist
          </span>
        </Typography>
      </Container>
    </Box>
  );
};
