import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { TextField, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { useMediaQuery, useTheme } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { DonationLists } from "./DonationLists";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CurrencyData } from "../../ApiIntegration/Shared/CurrencyData";
import ArtistStripe from "../../Payment/ArtistStripe";

const selectcounties = [
  {
    value: "USA",
    label: "USA",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Japan",
    label: "Japan",
  },
];

const amounts = [
  {
    id: 1,
    amount: 500.0,
  },
  {
    id: 2,
    amount: 1000.0,
  },
  {
    id: 3,
    amount: 2000.0,
  },
  {
    id: 4,
    amount: 5000.0,
  },
];

const lineStyle = {
  width: "100%",
  height: "1px",
  backgroundColor: "#E4E4E4",
  marginTop: "50px",
  marginBottom: "30px",
};

const BottomlineStyle = {
  width: "100%",
  height: "2px",
  backgroundColor: "#E4E4E4",
  marginTop: "50px",
  marginBottom: "30px",
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    // marginTop: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(5),
    minWidth: 90,
    fontFamily: "var(--primary-font)",
    backgroundColor: "#F8F8F8",

    padding: "10px 10px",
    "& .MuiSelect-icon": {
      color: "black",
    },
    "& .MuiSelect-root": {
      color: "#E4E4E4",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
  },
  select: {
    textAlign: "center",
    textDecoration: "none",
    color: "var(--white)",
    fontFamily: "var(--primary-font)",
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  },
}));
const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

export const DonationForm = () => {
  const { id } = useParams();
  const theme = useTheme();

  const [selectedAmount, setSelectedAmount] = useState("");
  const [coverfees, setCoverfees] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [donationAmount, setDonationAmount] = React.useState("");
  const [openVisa, setOpenVisa] = React.useState(false);
  const handleOpenVisa = () => setOpenVisa(true);
  const handleCloseVisa = () => setOpenVisa(false);
  const [orderId, setOrderId] = React.useState("");

  // Function to handle button click and update selected amount
  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
  };

  const [token, setToken] = useState(""); // Initialize the token state

  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);

      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const [currencies, setCurrencies] = React.useState([]);

  React.useEffect(() => {
    const fetchCurrenciesDataAsync = async () => {
      try {
        const apiData = await CurrencyData();
        setCurrencies(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCurrenciesDataAsync();
  }, []);

  const initialValues = {
    amount: "",
    payer_first_name: "",
    payer_second_name: "",
    payer_country: "",
    payer_email: "",
    payer_number: "",
    payer_comment: "",
  };

  const phoneRegExp = /^[0-9]{10}$/;

  const DataSchema = Yup.object().shape({
    amount: Yup.number().required("Required"),
    payer_first_name: Yup.string()
      .min(2, "First name should be at least 2 characters")
      .max(20, "First name should be at most 20 characters")
      .required("Required"),
    payer_second_name: Yup.string()
      .min(2, "Last name should be at least 2 characters")
      .max(20, "Last name should be at most 20 characters")
      .required("Required"),
    payer_email: Yup.string()
      .email("Invalid email format")
      .required("Required"),
    payer_number: Yup.string()
      .matches(phoneRegExp, "Contact number is not valid")
      .min(6, "Contact number should be at least 10 digits")
      .max(20, "Contact number should be at most 10 digits")
      .required("Required"),
    payer_comment: Yup.string()
      .min(5, "Comment should be at least 5 character")
      .max(300, "Comment should be at most 300 character")
      .required("Required"),
  });

  const [paymentopen, setPaymentopen] = React.useState(false);
  const handlePaymentOpen = () => setPaymentopen(true);
  const handlePaymentClose = () => setPaymentopen(false);

  // Use the useMediaQuery hook to get the screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm", "xs"));
  const label = isSmallScreen ? "Enter Amount" : "Enter Contribution Amount";

  const classes = useStyles();

  const [country, setCountry] = React.useState("NPR");

  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  //get rate of currency where symbol id country
  const selectedCurrency = currencies.find(
    (currency) => currency.symbol === country
  );

  const rate = selectedCurrency?.rate;

  const submitHandler = async (values, { resetForm }) => {
    setIsLoading(true);
    if (values.amount === "" && values.amount === null) {
      values.amount = selectedAmount;
    }

    values.artist_id = parseFloat(id);
    values.amount = parseFloat(values.amount);
    setDonationAmount(values.amount);
    values.cover_fee = coverfees === true ? 1 : 0;

    try {
      const response = await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/artist-donations`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setOrderId(response.data.payload.id);
      handlePaymentOpen();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
    resetForm();
  };

  const handleClickVisa = () => {
    handleOpenVisa();
    handlePaymentClose();
  };

  return (
    <Box
      sx={{
        marginTop: "20px",
      }}
    >
      <Box
        sx={{
          marginTop: "40px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontFamily: "var(--primary-font)",
            color: "var(--theme-color)",
            textAlign: "left",
            fontSize: {
              xs: "18px",
              sm: "18px",
              md: "20px",
              lg: "22px",
              xl: "22px",
            },
          }}
        >
          Choose a donation amount
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={DataSchema}
          onSubmit={submitHandler}
        >
          {(formik) => {
            const { errors, touched, values } = formik;

            return (
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "column",
                      lg: "row",
                      xl: "row",
                    },
                    justifyContent: "flex-start",
                    alignItems: {
                      xs: "flex-start",
                      sm: "flex-start",
                      md: "flex-start",
                      lg: "center",
                      xl: "center",
                    },
                    width: {
                      xs: "100%",
                      sm: "80%",
                      md: "80%",
                      lg: "80%",
                      xl: "80%",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "80%",
                        lg: "80%",
                        xl: "80%",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid #E4E4E4",
                        marginTop: "40px",
                        height: "50px",
                        backgroundColor: "var(--white)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <FormControl className={classes.formControl}>
                        <Select
                          // open={open}
                          // onClose={handleClose}
                          // onOpen={handleOpen}
                          value={country}
                          name="country"
                          size="small"
                          onChange={handleChange}
                        >
                          {currencies?.map((option, key) => (
                            <MenuItem
                              value={option.symbol}
                              key={key}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#E4E4E4",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={option.image}
                                  alt={option.name}
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                  }}
                                  loading="lazy"
                                />
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Field
                        as={TextField}
                        id="outlined-basic"
                        autoComplete="off"
                        variant="outlined"
                        size="small"
                        // name="currency"
                        value={country}
                        readOnly
                        sx={{
                          backgroundColor: "var(--white)",
                          fontFamily: "var(--primary-font)",
                          width: {
                            xs: "30%",
                            sm: "20%",
                            md: "18%",
                            lg: "18%",
                            xl: "18%",
                          },
                          height: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--white)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--white)",
                            borderRadius: "0",
                            padding: "0",
                            height: "100%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #eee",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid var(--white)",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--white)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <Field
                        as={TextField}
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        name="amount"
                        type="number"
                        autoComplete="off"
                        label={label}
                        value={
                          selectedAmount !== ""
                            ? (values.amount = selectedAmount)
                            : values.amount
                        }
                        sx={{
                          backgroundColor: "var(--white)",
                          fontFamily: "var(--primary-font)",
                          width: "65%",
                          height: "100%",
                          // padding: "1px",
                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--white)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--white)",
                            borderRadius: "0",
                            padding: "0",
                            height: "100%",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid white",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid var(--white)",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--white)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                    </Box>
                    <ErrorMessage
                      name="amount"
                      component="span"
                      className="error"
                      style={errorStyle}
                    />
                  </Box>

                  <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    xs={12}
                  >
                    {amounts.map((amount, key) => (
                      <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                        <Button
                          variant="outlined"
                          sx={{
                            backgroundColor: "transparent",
                            color: "var(--black)",
                            fontFamily: "var(--primary-font)",
                            textTransform: "capitalize",
                            border: "1px solid #E4E4E4",
                            marginTop: "40px",
                            borderRadius: "5px",
                            // marginRight: "10px",
                            padding: "10px",
                            "&:hover": {
                              backgroundColor: "var(--theme-color)",
                              color: "var(--white)",
                              border: "1px solid var(--theme-color)",
                            },
                          }}
                          onClick={() =>
                            handleButtonClick((amount.amount / rate).toFixed(2))
                          }
                        >
                          {(amount.amount / rate).toFixed(2)}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box sx={lineStyle}></Box>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "block",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <DonationLists />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "var(--black)",
                          fontFamily: "var(--primary-font)",
                          fontWeight: "bold",
                          marginTop: "20px",
                        }}
                      >
                        Your Information
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            First Name:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            className={
                              errors.payer_first_name &&
                              touched.payer_first_name
                                ? "input-error"
                                : null
                            }
                            name="payer_first_name"
                            id="input-with-icon-adornment"
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: {
                                xs: "100%",
                                sm: "90%",
                                md: "90%",
                                lg: "90%",
                                xl: "90%",
                              },

                              // padding: "1px",
                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="payer_first_name"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            Last Name:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="payer_second_name"
                            id="input-with-icon-adornment"
                            className={
                              errors.payer_second_name &&
                              touched.payer_second_name
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: {
                                xs: "100%",
                                sm: "90%",
                                md: "90%",
                                lg: "90%",
                                xl: "90%",
                              },

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="payer_second_name"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: {
                            xs: "column",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            marginTop: "20px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            Select Country:
                          </InputLabel>

                          <Field
                            as={TextField}
                            select
                            labelId="sort-order-label"
                            id="project-select"
                            name="payer_country"
                            defaultValue="Nepal"
                            size="small"
                            sx={{
                              height: "50px",
                              width: {
                                xs: "100%",
                                sm: "90%",
                                md: "90%",
                                lg: "90%",
                                xl: "90%",
                              },
                              // textAlign: "center",
                              borderRadius: "5px",
                              color: "var(--btn-color)",
                              backgroundColor: "var(--white)",
                              textAlign: "justify",
                              "& .MuiOutlinedInput-root": {
                                "&:hover fieldset": {
                                  borderColor: "var(--black-color)", // Change border color on hover
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "var(--black-color)", // Change border color on focus
                                },
                              },
                            }}
                          >
                            {selectcounties.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Field>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginTop: "20px",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            Email Address:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="payer_email"
                            type="email"
                            id="input-with-icon-adornment"
                            className={
                              errors.payer_email && touched.payer_email
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: {
                                xs: "100%",
                                sm: "90%",
                                md: "90%",
                                lg: "90%",
                                xl: "90%",
                              },

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: {
                                xs: "12px",
                                sm: "12px",
                                md: "12px",
                                lg: "12px",
                                xl: "12px",
                              },
                              color: "var(--btn-color)",
                            }}
                          >
                            Your receipt will be emailed here
                          </Typography>
                          <ErrorMessage
                            name="payer_email"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          marginTop: "20px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <InputLabel
                          htmlFor="input-with-icon-adornment"
                          sx={{
                            color: "var(--black-color)",
                            fontFamily: "var(--primary-font)",
                          }}
                        >
                          Contact Number:
                        </InputLabel>
                        <Field
                          as={TextField}
                          variant="outlined"
                          autoComplete="off"
                          size="small"
                          type="number"
                          name="payer_number"
                          id="input-with-icon-adornment"
                          className={
                            errors.payer_number && touched.payer_number
                              ? "input-error"
                              : null
                          }
                          sx={{
                            backgroundColor: "transparent",
                            fontFamily: "var(--primary-font)",
                            width: {
                              xs: "100%",
                              sm: "45%",
                              md: "45%",
                              lg: "45%",
                              xl: "45%",
                            },

                            " & .MuiOutlinedInput-root": {
                              "  &.Mui-focused fieldset": {
                                borderColor: "var(--btn-color)",
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              border: "0px solid var(--theme-color)",
                              borderRadius: "0",
                              padding: "0",
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                // border: "1px solid #E4E4E4",
                                borderRadius: "5px",
                              },
                            //border colour when focused
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                border: "0px solid var(--btn-color))",
                              },
                            //floating label color when focused
                            "& .MuiInputLabel-outlined.Mui-focused": {
                              color: "var(--btn-color)",
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                        <ErrorMessage
                          name="payer_number"
                          component="span"
                          className="error"
                          style={errorStyle}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginTop: "20px",
                          width: {
                            xs: "100%",
                            sm: "95%",
                            md: "95%",
                            lg: "95%",
                            xl: "95%",
                          },
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <InputLabel
                          htmlFor="input-with-icon-adornment"
                          sx={{
                            color: "var(--black-color)",
                            fontFamily: "var(--primary-font)",
                          }}
                        >
                          Leave a comment:
                        </InputLabel>
                        <TextareaAutosize
                          aria-label="minimum height"
                          type="text"
                          minRows={4}
                          style={{
                            width: "100%",
                            height: 100,
                            backgroundColor: "transparent",
                            fontFamily: "var(--primary-font)",
                            borderColor: "var()",
                            outlineColor: "var(--btn-color)", // Add this line to change the border color when focused
                          }}
                          className="form-message"
                          name="payer_comment"
                          value={formik.values.payer_comment}
                          onChange={formik.handleChange}
                          // error={
                          //   formik.touched.comment && formik.errors.comment
                          // }
                          // helperText={
                          //   formik.touched.comment && formik.errors.comment
                          // }
                        />
                        <ErrorMessage
                          name="payer_comment"
                          component="span"
                          className="error"
                          style={errorStyle}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    xl={4}
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                        xl: "block",
                      },
                    }}
                  >
                    <DonationLists />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Box sx={BottomlineStyle}></Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "15px",
                            lg: "16px",
                            xl: "16px",
                          },
                          textAlign: "left",
                        }}
                      >
                        Donation Amount:
                      </Typography>
                      <Box sx={{ marginTop: "10px" }}>
                        <Typography
                          sx={{
                            fontFamily: "var(--primary-font)",
                            fontSize: {
                              xs: "18px",
                              sm: "18px",
                              md: "20px",
                              lg: "22px",
                              xl: "22px",
                            },
                            textAlign: "left",
                            color: "var(--theme-color)",
                          }}
                        >
                          {country} {""}
                          {values.amount ? values.amount : 0} {""}
                          <span style={{ color: "var(--black)" }}></span>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            textAlign: "left",
                            marginTop: "20px",
                            width: "100%",
                          }}
                        >
                          <FormControlLabel
                            control={<Checkbox name="checkbox" />}
                            labelPlacement="end"
                            sx={{
                              marginTop: "-15px",
                            }}
                            name="coverfees"
                            value={coverfees}
                            onChange={() => {
                              setCoverfees(!coverfees);
                            }}
                            // label="I’d like to cover the fees associated with my donation so more of my donation goes directly to Jinlap Organization."
                          />
                          <Typography
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              fontSize: {
                                xs: "12px",
                                sm: "12px",
                                md: "14px",
                                lg: "14px",
                                xl: "14px",
                              },
                            }}
                          >
                            I’d like to cover the fees associated with my
                            donation so more of my donation goes directly to
                            Jinlap Organization.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            flexWrap: "wrap",
                            marginTop: "50px",
                          }}
                        >
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              backgroundColor: "var(--theme-color)",
                              color: "var(--white)",
                              marginBottom: "10px",
                              fontSize: {
                                xs: "13px",
                                sm: "15px",
                                md: "16px",
                                lg: "18px",
                                xl: "18px",
                              },
                              marginTop: {
                                xs: "10px",
                                sm: "10px",
                                md: "15px",
                                lg: "18px",
                                xl: "18px",
                              },
                              textTransform: "capitalize",
                              padding: "10px",
                              "&:hover": {
                                backgroundColor: "var(--theme-color)",
                                color: "var(--white)",
                                transform: "scale(1.1)",
                              },
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <span style={{ color: "white" }}>
                                Processing...
                              </span>
                            ) : (
                              "Pay Now"
                            )}
                          </Button>
                          <Modal
                            open={paymentopen}
                            onClose={handlePaymentClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={{
                                backgroundColor: "var(--white)",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                padding: "10px",
                                width: {
                                  xs: "95%",
                                  sm: "75%",
                                  md: "75%",
                                  lg: "42%",
                                  xl: "42%",
                                },
                                borderColor: "var(--white)",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  // flexWrap: "wrap",
                                  marginTop: "10px",
                                }}
                              >
                                <InputLabel
                                  htmlFor="input-with-icon-adornment"
                                  sx={{
                                    color: "var(--black-color)",
                                    marginBottom: "10px",
                                    fontWeight: "600",
                                    fontFamily: "var(--primary-font)",
                                    fontSize: {
                                      xs: "14px",
                                      sm: "15px",
                                      md: "16px",
                                      lg: "18px",
                                      xl: "18px",
                                    },
                                  }}
                                >
                                  Payment:
                                </InputLabel>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "var(--light-black)",
                                    fontFamily: "var(--primary-font)",
                                    fontSize: {
                                      xs: "12px",
                                      sm: "12px",
                                      md: "12px",
                                      lg: "14px",
                                      xl: "14px",
                                    },
                                    marginBottom: "10px",
                                  }}
                                >
                                  Donate with your prefered payment method:
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: "100px",
                                      height: "50px",
                                      margin: "10px",
                                      //hover
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        transition: "all 0.2s ease-in-out",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        `../assets/footer/khalti.png`
                                      }
                                      alt="..."
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                      }}
                                      loading="lazy"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "100px",
                                      height: "50px",
                                      marginBottom: "10px",
                                      //hover
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        transition: "all 0.2s ease-in-out",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        `../assets/footer/esewa.png`
                                      }
                                      alt="..."
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                      }}
                                      loading="lazy"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "100px",
                                      height: "50px",
                                      marginBottom: "10px",
                                      //hover
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        transition: "all 0.2s ease-in-out",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        `../assets/footer/paypal.png`
                                      }
                                      alt="..."
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                      }}
                                      loading="lazy"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "100px",
                                      height: "50px",
                                      marginBottom: "10px",
                                      //hover
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        transition: "all 0.2s ease-in-out",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        `../assets/footer/skrill.png`
                                      }
                                      alt="..."
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                      }}
                                      loading="lazy"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "100px",
                                      height: "50px",
                                      marginBottom: "10px",
                                      //hover
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        transition: "all 0.2s ease-in-out",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        `../assets/footer/mastercard.png`
                                      }
                                      alt="..."
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                      }}
                                      loading="lazy"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      width: "100px",
                                      height: "50px",
                                      marginBottom: "10px",
                                      //hover
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        transition: "all 0.2s ease-in-out",
                                        cursor: "pointer",
                                      },
                                    }}
                                    onClick={handleClickVisa}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        `../assets/footer/visa.png`
                                      }
                                      alt="..."
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                      }}
                                      loading="lazy"
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Modal>
                          <Modal
                            open={openVisa}
                            onClose={handleCloseVisa}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              sx={{
                                backgroundColor: "var(--white)",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                padding: "10px",
                                width: {
                                  xs: "95%",
                                  sm: "75%",
                                  md: "75%",
                                  lg: "42%",
                                  xl: "42%",
                                },
                                borderColor: "var(--white)",
                              }}
                            >
                              <ArtistStripe
                                totalamount={donationAmount}
                                artistId={orderId}
                                handleCloseVisa={handleCloseVisa}
                              />
                            </Box>
                          </Modal>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};
