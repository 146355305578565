import React from "react";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

export const RegistrationForm = ({ handleClose }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    name: "",
    email: "",
    password: "",
  };

  const DataSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Required")
      .min(6, "Password is too short - should be 6 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    name: Yup.string()
      .required("Full Name is required")
      .matches(/^[A-Za-z ]*$/, "Only alphabetic characters are allowed"),
  });

  const submitHandler = async (values, { resetForm }) => {
    setIsProcessing(true);
    try {
      const res = await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/register`,
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      // handleClose();
      setIsProcessing(false);
      toast.success(res.data.message);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Check if error.response, error.response.data, and message are defined
        toast.error(error.response.data.message);
      } else {
        // Handle the case where some parts of the error object are undefined
        toast.error("An error occurred during registration.");
      }
    }
    resetForm();
    handleClose();
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DataSchema}
      onSubmit={submitHandler}
    >
      {({ errors, touched }) => (
        <Form>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: {
                xs: "0px 0px 30px 0px",
                sm: "0px 20px 30px 20px",
                md: "0px 20px 10px 20px",
                lg: "0px 50px 20px 50px",
                xl: "0px 50px 20px 50px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{
                  color: "var(--black-color)",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "10px",
                }}
              >
                Full Name{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Field
                as={TextField}
                variant="outlined"
                type="text"
                size="medium"
                name="name"
                label="Enter Your Full Name"
                autoComplete="off"
                id="input-with-icon-adornment"
                className={errors.name && touched.name ? "input-error" : null}
                sx={{
                  backgroundColor: "transparent",
                  fontFamily: "var(--primary-font)",
                  width: "100%",
                  " & .MuiOutlinedInput-root": {
                    "  &.Mui-focused fieldset": {
                      borderColor: "var(--btn-color)",
                    },
                  },

                  "& .MuiOutlinedInput-root": {
                    border: "0px solid var(--theme-color)",
                    borderRadius: "0",
                    padding: "0 14px",
                    height: "50px",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    // border: "1px solid #E4E4E4",
                    borderRadius: "5px",
                  },
                  //border colour when focused
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "0px solid var(--btn-color))",
                    },
                  //floating label color when focused
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "var(--btn-color)",
                    backgroundColor: "transparent",
                  },
                }}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="error"
                style={errorStyle}
              />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{
                  color: "var(--black-color)",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "10px",
                }}
              >
                Email Address{" "}
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Field
                as={TextField}
                variant="outlined"
                type="email"
                size="medium"
                name="email"
                label="Enter Your Email Address"
                autoComplete="off"
                id="input-with-icon-adornment"
                className={errors.email && touched.email ? "input-error" : null}
                sx={{
                  backgroundColor: "transparent",
                  fontFamily: "var(--primary-font)",
                  width: "100%",
                  " & .MuiOutlinedInput-root": {
                    "  &.Mui-focused fieldset": {
                      borderColor: "var(--btn-color)",
                    },
                  },

                  "& .MuiOutlinedInput-root": {
                    border: "0px solid var(--theme-color)",
                    borderRadius: "0",
                    padding: "0 14px",
                    height: "50px",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    // border: "1px solid #E4E4E4",
                    borderRadius: "5px",
                  },
                  //border colour when focused
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "0px solid var(--btn-color))",
                    },
                  //floating label color when focused
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "var(--btn-color)",
                    backgroundColor: "transparent",
                  },
                }}
              />
              <ErrorMessage
                name="email"
                component="span"
                className="error"
                style={errorStyle}
              />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{
                  color: "var(--black-color)",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "10px",
                }}
              >
                Password <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Field
                as={TextField}
                variant="outlined"
                size="medium"
                name="password"
                type={showPassword ? "text" : "password"}
                label="Enter Your Password"
                autoComplete="off"
                id="input-with-icon-adornment"
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={
                  errors.password && touched.password ? "input-error" : null
                }
                sx={{
                  backgroundColor: "transparent",
                  fontFamily: "var(--primary-font)",
                  width: "100%",

                  " & .MuiOutlinedInput-root": {
                    "  &.Mui-focused fieldset": {
                      borderColor: "var(--btn-color)",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "0px solid var(--theme-color)",
                    borderRadius: "0",
                    padding: "0 14px",
                    height: "50px",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    // border: "1px solid #E4E4E4",
                    borderRadius: "5px",
                  },
                  //border colour when focused
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "0px solid var(--btn-color))",
                    },
                  //floating label color when focused
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "var(--btn-color)",
                    backgroundColor: "transparent",
                  },
                }}
              />
              <ErrorMessage
                name="password"
                component="span"
                className="error"
                style={errorStyle}
              />
            </Box>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                textTransform: "capitalize",
                marginTop: "20px",
                borderRadius: "5px",
                fontFamily: "var(--primary-font)",
                borderColor: "var(--theme-color)",
                color: "var(--white)",
                backgroundColor: "var(--theme-color)",
                "&:hover": {
                  backgroundColor: "var(--white)",
                  borderColor: "var(--theme-color)",
                  color: "var(--theme-color)",
                },
              }}
              type="submit"
              disabled={isProcessing}
            >
              {isProcessing ? "Loading..." : "Register"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
