import React from "react";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export const Allgoods = () => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.all_dharma_goods);
    return res.data;
  };

  const { isLoading, data: allGoods } = useQuery({
    queryKey: ["allGoods"],
    queryFn: getData,
  });

  if (isLoading) return;

  <Container
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      gap: "20px",
    }}
  >
    {[1, 2, 3].map((index) => (
      <Box
        key={index}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Skeleton variant="rectangular" width={300} height={200} />
        <Skeleton variant="text" width={300} height={30} />
        <Skeleton variant="text" width={300} height={30} />
      </Box>
    ))}
  </Container>;

  return (
    <div>
      <Grid container spacing={2}>
        {/* First item displayed at the top in a single row */}
        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "block",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            width: "100%",
          }}
        >
          {allGoods?.slice(0, 1).map((item, index) => {
            return (
              <div
                className="card"
                style={{ border: "0", padding: "0", margin: "0" }}
                key={index}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%",
                    "&:hover": {
                      "&::before": {
                        content: "''",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "var(--light-theme-color)", // Light pink color
                        opacity: 0.7,
                        zIndex: 1,
                      },
                      "& button": {
                        visibility: "visible",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        zIndex: 2,
                        padding: "2px 5px 2px 5px",
                        backgroundColor: "var(--yellow)",
                        color: "var(--white)",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: {
                            xs: "17px",
                            sm: "11px",
                            md: "11px",
                            lg: "12px",
                            xl: "12px",
                          },
                        }}
                      >
                        4.3
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: "35vh",
                      }}
                    >
                      <img
                        src={item.images[0]?.image}
                        alt={item.name}
                        style={{
                          height: "100%",
                          width: "100%",
                          marginRight: "20px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        loading="lazy"
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* Icons */}
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "40%",
                        transform: "translate(-50%, -50%)",
                        visibility: "hidden",
                        zIndex: 2,
                      }}
                      size="small"
                      color="primary"
                      onClick={async () => {
                        const wishlistdata = {
                          color: item.colors[0].color,
                          size: item.sizes[0].size,
                          product_id: item.id,
                          currency: selectedCurrency,
                          qty: 1,
                          price: item.after_discount
                            ? (item.after_discount / rate).toFixed(2)
                            : (item.price / rate).toFixed(2),
                        };

                        try {
                          const response = await axios.post(
                            process.env.REACT_APP_JINLAP_BASE_URL +
                              `/products/add-to-wishlist`,
                            wishlistdata,
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          if (response.data.status === true) {
                            queryClient.invalidateQueries("dharmagoodwishlist");
                            toast.success(
                              `${item.name} added to wishlist successfully!`
                            );
                          } else {
                            toast.warning(response.data.message);
                          }
                        } catch (error) {
                          toast.error(error.response.data.message);
                        }
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "var(--very-lightthemecolor)",
                          padding: "5px",
                          borderRadius: "50%",
                          color: "var(--theme-color)",
                          "&:hover": {
                            //zoom the buttom
                            transform: "scale(1.1)",
                            backgroundColor: "var(--theme-color)",
                            color: "var(--white)",
                          },
                        }}
                      >
                        <FavoriteBorderIcon fontSize="medium" />
                      </Box>
                    </IconButton>
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "60%",
                        transform: "translate(-50%, -50%)",
                        visibility: "hidden",
                        zIndex: 2,
                      }}
                      size="small"
                      color="primary"
                      onClick={async () => {
                        const data = {
                          color: item.colors[0].color,
                          size: item.sizes[0].size,
                          qty: 1,
                          product_id: item.id,
                          currency: selectedCurrency,
                          price: item.after_discount
                            ? (item.after_discount / rate).toFixed(2)
                            : (item.price / rate).toFixed(2),
                        };

                        try {
                          const response = await axios.post(
                            process.env.REACT_APP_JINLAP_BASE_URL +
                              `/products/add-to-cart`,
                            data,
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          if (response.data.status === true) {
                            queryClient.invalidateQueries("dharmagoodcart");
                            toast.success(
                              `${item.name} added to cart successfully!`
                            );
                          } else {
                            toast.warning(response.data.message);
                          }
                        } catch (error) {
                          toast.error(error.response.data.message);
                        }
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "var(--very-lightthemecolor)",
                          padding: "5px",
                          borderRadius: "50%",
                          color: "var(--theme-color)",
                          "&:hover": {
                            //zoom the buttom
                            transform: "scale(1.1)",
                            backgroundColor: "var(--theme-color)",
                            color: "var(--white)",
                          },
                        }}
                      >
                        <ShoppingBagOutlinedIcon fontSize="medium" />
                      </Box>
                    </IconButton>
                  </Box>
                </Box>

                <Box
                  sx={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "var(--theme-color)",
                      fontSize: {
                        xs: "15px",
                        sm: "18px",
                        md: "20px",
                        lg: "20px",
                        xl: "20px",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate(`/productdetails/${item.id}`);
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: "10px",
                      display: "flex",
                      textAlign: "justify",
                      marginRight: "20px",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      overflow: "hidden", // Hide any overflowing text
                      textOverflow: "ellipsis", // Display ellipsis for long texts
                      display: "-webkit-box",
                      WebkitLineClamp: 2, // Adjust the number of lines to fit your requirements
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {item.description}
                  </Typography>
                  <Typography
                    variant="span"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "var(--theme-color)",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                  >
                    {item.after_discount ? (
                      <>
                        <del>
                          {selectedCurrency} {""}{" "}
                          {(item.price / rate).toFixed(2)}
                        </del>
                        <span
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {selectedCurrency} {""}{" "}
                          {(item.after_discount / rate).toFixed(2)}
                        </span>
                      </>
                    ) : (
                      <span>
                        {selectedCurrency} {""}
                        {item.price.toFixed(2)}
                      </span>
                    )}
                  </Typography>
                </Box>
              </div>
            );
          })}
        </Grid>

        {/* The rest of the items displayed */}

        {allGoods?.slice(1).map((item) => (
          <Grid
            item
            xs={6}
            sx={{
              display: {
                xs: "block",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
            key={item.id}
          >
            <div
              className="card"
              style={{ border: "0", padding: "0", margin: "0" }}
            >
              <Box
                sx={{
                  position: "relative",
                  "&:hover": {
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "var(--light-theme-color)", // Light pink color
                      opacity: 0.7,
                      zIndex: 1,
                    },
                    "& button": {
                      visibility: "visible",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      zIndex: 2,
                      padding: "2px 5px 2px 5px",
                      backgroundColor: "var(--yellow)",
                      color: "var(--white)",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "11px",
                          md: "11px",
                          lg: "12px",
                          xl: "12px",
                        },
                      }}
                    >
                      4.3
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "25vh",
                    }}
                  >
                    <img
                      src={item.images[0]?.image}
                      alt={item.name}
                      style={{
                        height: "100%",
                        width: "100%",
                        marginRight: "20px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      loading="lazy"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Icons */}
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "40%",
                      transform: "translate(-50%, -50%)",
                      visibility: "hidden",
                      zIndex: 2,
                    }}
                    size="small"
                    color="primary"
                    onClick={async () => {
                      const wishlistdata = {
                        color: item.colors[0].color,
                        size: item.sizes[0].size,
                        product_id: item.id,
                        currency: selectedCurrency,
                        qty: 1,
                        price: item.after_discount
                          ? (item.after_discount / rate).toFixed(2)
                          : (item.price / rate).toFixed(2),
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/add-to-wishlist`,
                          wishlistdata,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        if (response.data.status === true) {
                          queryClient.invalidateQueries("dharmagoodwishlist");
                          toast.success(
                            `${item.name} added to wishlist successfully!`
                          );
                        } else {
                          toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--very-lightthemecolor)",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "var(--theme-color)",
                        "&:hover": {
                          //zoom the buttom
                          transform: "scale(1.1)",
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      <FavoriteBorderIcon fontSize="medium" />
                    </Box>
                  </IconButton>
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "60%",
                      transform: "translate(-50%, -50%)",
                      visibility: "hidden",
                      zIndex: 2,
                    }}
                    size="small"
                    color="primary"
                    onClick={async () => {
                      const data = {
                        color: item.colors[0].color,
                        size: item.sizes[0].size,
                        qty: 1,
                        product_id: item.id,
                        currency: selectedCurrency,
                        price: item.after_discount
                          ? (item.after_discount / rate).toFixed(2)
                          : (item.price / rate).toFixed(2),
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/add-to-cart`,
                          data,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        if (response.data.status === true) {
                          queryClient.invalidateQueries("dharmagoodcart");
                          toast.success(
                            `${item.name} added to cart successfully!`
                          );
                        } else {
                          toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--very-lightthemecolor)",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "var(--theme-color)",
                        "&:hover": {
                          //zoom the buttom
                          transform: "scale(1.1)",
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      <ShoppingBagOutlinedIcon fontSize="medium" />
                    </Box>
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  padding: "0",
                  margin: "0",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "var(--theme-color)",
                    fontSize: {
                      xs: "15px",
                      sm: "18px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/productdetails/${item.id}`);
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    textAlign: "justify",
                    marginRight: "20px",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                    overflow: "hidden", // Hide any overflowing text
                    textOverflow: "ellipsis", // Display ellipsis for long texts
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Adjust the number of lines to fit your requirements
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.description}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "var(--theme-color)",
                    fontSize: {
                      xs: "10px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  {item.after_discount ? (
                    <>
                      <del>
                        {selectedCurrency} {""} {(item.price / rate).toFixed(2)}
                      </del>
                      <span
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {selectedCurrency} {""}{" "}
                        {(item.after_discount / rate).toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <span>
                      {selectedCurrency} {""}
                      {item.price.toFixed(2)}
                    </span>
                  )}
                </Typography>
              </Box>
            </div>
          </Grid>
        ))}

        {allGoods?.map((item) => (
          <Grid
            item
            sm={4}
            md={4}
            lg={3}
            xl={3}
            sx={{
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
            key={item.id}
          >
            <div className="card" style={{ border: "0" }}>
              <Box
                sx={{
                  position: "relative",
                  "&:hover": {
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "var(--light-theme-color)", // Light pink color
                      opacity: 0.7,
                      zIndex: 1,
                    },
                    "& button": {
                      visibility: "visible",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      zIndex: 2,
                      padding: "2px 5px 2px 5px",
                      backgroundColor: "var(--yellow)",
                      color: "var(--white)",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "11px",
                          md: "11px",
                          lg: "12px",
                          xl: "12px",
                        },
                      }}
                    >
                      4.3
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "35vh",
                    }}
                  >
                    <img
                      src={item.images[0]?.image}
                      alt={item.name}
                      style={{
                        height: "100%",
                        width: "100%",
                        // marginRight: "20px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      loading="lazy"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Icons */}
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "40%",
                      transform: "translate(-50%, -50%)",
                      visibility: "hidden",
                      zIndex: 2,
                    }}
                    size="small"
                    color="primary"
                    onClick={async () => {
                      const wishlistdata = {
                        color: item.colors[0].color,
                        size: item.sizes[0].size,
                        product_id: item.id,
                        currency: selectedCurrency,
                        qty: 1,
                        price: item.after_discount
                          ? (item.after_discount / rate).toFixed(2)
                          : (item.price / rate).toFixed(2),
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/add-to-wishlist`,
                          wishlistdata,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        if (response.data.status === true) {
                          queryClient.invalidateQueries("dharmagoodwishlist");
                          toast.success(
                            `${item.name} added to wishlist successfully!`
                          );
                        } else {
                          toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--very-lightthemecolor)",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "var(--theme-color)",
                        "&:hover": {
                          //zoom the buttom
                          transform: "scale(1.1)",
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      <FavoriteBorderIcon fontSize="medium" />
                    </Box>
                  </IconButton>
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "60%",
                      transform: "translate(-50%, -50%)",
                      visibility: "hidden",
                      zIndex: 2,
                    }}
                    size="small"
                    color="primary"
                    onClick={async () => {
                      const data = {
                        color: item.colors[0].color,
                        size: item.sizes[0].size,
                        qty: 1,
                        product_id: item.id,
                        currency: selectedCurrency,
                        price: item.after_discount
                          ? (item.after_discount / rate).toFixed(2)
                          : (item.price / rate).toFixed(2),
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/add-to-cart`,
                          data,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        if (response.data.status === true) {
                          queryClient.invalidateQueries("dharmagoodcart");
                          toast.success(
                            `${item.name} added to cart successfully!`
                          );
                        } else {
                          toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--very-lightthemecolor)",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "var(--theme-color)",
                        "&:hover": {
                          //zoom the buttom
                          transform: "scale(1.1)",
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      <ShoppingBagOutlinedIcon fontSize="medium" />
                    </Box>
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "var(--theme-color)",
                    fontSize: {
                      xs: "15px",
                      sm: "18px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/productdetails/${item.id}`);
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    marginBottom: "10px",
                    display: "flex",
                    textAlign: "justify",
                    marginRight: "20px",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                    overflow: "hidden", // Hide any overflowing text
                    textOverflow: "ellipsis", // Display ellipsis for long texts
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Adjust the number of lines to fit your requirements
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.description}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "var(--theme-color)",
                    fontSize: {
                      xs: "7px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  {item.after_discount ? (
                    <>
                      <del>
                        {selectedCurrency} {""} {(item.price / rate).toFixed(2)}
                      </del>
                      <span
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {selectedCurrency} {""}{" "}
                        {(item.after_discount / rate).toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <span>
                      {selectedCurrency} {""}
                      {item.price.toFixed(2)}
                    </span>
                  )}
                </Typography>
              </Box>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
