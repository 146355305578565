import React, { useState } from "react";
import Form from "./Form";
import MainApp from "./MainApp";

function App() {
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);

  const handleCodeSubmit = (code) => {
    // Check if the provided code is correct
    if (code === "3322") {
      setIsCodeCorrect(true);
    } else {
      setIsCodeCorrect(false);
      // Optionally, display an error message
    }
  };

  return (
    <div className="App">
      {isCodeCorrect ? <MainApp /> : <Form onSubmit={handleCodeSubmit} />}
    </div>
  );
}

export default App;
