import React from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import "./SocialInitiaties.css";
import HorizontalLines from "../../Shared/Horizontalline/Horizontalline";
import lotus from "../../assets/lotus.png";
import { Contribution } from "./Contribution";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const SocialInitiaties = ({
  social_title_two_image,
  social_title_two_image_fund,
  social_title_two_image_description,
  social_title_two_image_two,
  social_title_two_image_two_fund,
  social_title_two_image_two_description,
  social_title,
  social_description,
  social_image,
  social_image_one,
  social_image_two,
  social_image_three,
  social_image_four,
  social_image_five,
  social_image_six,
  background_image,
}) => {
  const leftData = [
    {
      imgUrl: social_title_two_image,
      fund: social_title_two_image_fund,
      desc: social_title_two_image_description,
    },
  ];

  const rightData = [
    {
      imgUrl: social_title_two_image_two,
      fund: social_title_two_image_two_fund,
      desc: social_title_two_image_two_description,
    },
  ];

  const mergedData = leftData.concat(rightData);

  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const navigate = useNavigate();
  return (
    <>
      <Box
        className="socialinitiavties"
        sx={{
          backgroundImage: `url(${background_image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="overlay"></div>
        <Grid
          container
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="1"
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            spacing={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "var(--white)",
                  fontSize: {
                    xs: "30px",
                    sm: "30px",
                    md: "40px",
                    lg: "40px",
                    xl: "40px",
                  },
                  // marginLeft: {
                  //   xs: "0%",
                  //   sm: "0%",
                  //   md: "28%",
                  //   lg: "33%",
                  //   xl: "30%",
                  // },
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "50%",
                    lg: "50%",
                    xl: "50%",
                  },
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "justify",
                    lg: "justify",
                    xl: "justify",
                  },
                  justifyContent: "center",

                  fontFamily: "var(--font-family-body)",
                }}
              >
                SOCIAL INITIATIVES
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    border: "1px solid var(--white)",
                    color: "var(--white)",
                    marginBottom: "10px",
                    textTransform: "capitalize",
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "13px",
                      lg: "13px",
                      xl: "13px",
                    },

                    marginTop: "20px",

                    fontFamily: "var(--font-family-body)",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "var(--white)",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => {
                    navigate("/projects");
                  }}
                >
                  View Social Initiatives
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                    alt="..."
                    style={{
                      width: "15px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                    loading="lazy"
                  />
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            backgroundColor="var(--white)"
            width="50%"
            display={{
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            }}
            flexDirection={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            }}
            justifyContent="center"
            alignItems="center"
            padding="58px 0px 20px 0px"
          >
            <Container>
              <HorizontalLines imgUrl={`${lotus}`} />
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    color: "var(--black)",
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "17px",
                      lg: "19px",
                      xl: "20px",
                    },
                    textAlign: "center",
                    fontFamily: "var(--font-family-body)",
                  }}
                >
                  A COMMUNITY OF PEOPLE HELPING EACH PTHER
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                {mergedData?.map((item, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: {
                          xs: "0px",
                          sm: "0px",
                          md: "20px",
                          lg: "20px",
                          xl: "0px",
                        },
                      }}
                      key={index}
                    >
                      <div className="images-container">
                        <div className="img-container">
                          <img
                            src={item.imgUrl}
                            alt=".."
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                            loading="lazy"
                          />
                        </div>
                        <div className="sidebar">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "./assets/AboutPage/Storyimages/smallhandicon.png"
                            }
                            alt="..."
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <Box
                        sx={{
                          height: "auto",
                          width: "70%",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "13px",
                              sm: "14px",
                              md: "15px",
                              lg: "16px",
                              xl: "16px",
                            },
                            textAlign: "justify",
                            fontFamily: "var(--font-family-body)",
                            fontWeight: "bold",
                          }}
                        >
                          Fund raised: {""}
                          <span style={{ color: "var(--theme-color)" }}>
                            {selectedCurrency} {(item.fund * rate).toFixed(2)}
                          </span>
                        </Typography>
                        <br />
                        <Typography
                          variant="body1"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "14px",
                              lg: "15px",
                              xl: "15px",
                            },
                            textAlign: "justify",
                            fontFamily: "var(--font-family-body)",
                          }}
                        >
                          {item.desc}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        sm={12}
        backgroundColor="var(--white)"
        width="100%"
        display={{
          xs: "flex",
          sm: "flex",
          md: "none",
          lg: "none",
          xl: "none",
        }}
        flexDirection={{
          xs: "column",
          sm: "column",
          md: "row",
          lg: "row",
          xl: "row",
        }}
        justifyContent="center"
        alignItems="center"
        padding="58px 0px 20px 0px"
      >
        <Container>
          <HorizontalLines imgUrl={`${lotus}`} />
          <Box>
            <Typography
              variant="h3"
              sx={{
                color: "var(--black)",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "17px",
                  lg: "19px",
                  xl: "20px",
                },
                textAlign: "center",
                fontFamily: "var(--font-family-body)",
              }}
            >
              A COMMUNITY OF PEOPLE HELPING EACH PTHER
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            {mergedData?.map((item, key) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key={key}
                >
                  <div className="images-container">
                    <div className="img-container">
                      <img
                        src={item.imgUrl}
                        alt=".."
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                        loading="lazy"
                      />
                    </div>
                    <div className="sidebar">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "./assets/AboutPage/Storyimages/smallhandicon.png"
                        }
                        alt="..."
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <Box
                    sx={{
                      height: "auto",
                      width: "70%",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "15px",
                          lg: "16px",
                          xl: "16px",
                        },
                        textAlign: "justify",
                        fontFamily: "var(--font-family-body)",
                        fontWeight: "bold",
                      }}
                    >
                      Fund raised: {""}
                      <span style={{ color: "var(--theme-color)" }}>
                        {selectedCurrency} {(item.fund * rate).toFixed(2)}
                      </span>
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "14px",
                          lg: "15px",
                          xl: "15px",
                        },
                        textAlign: "justify",
                        fontFamily: "var(--font-family-body)",
                      }}
                    >
                      {item.desc}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Container>
      </Grid>
      <Contribution
        social_title={social_title}
        social_description={social_description}
        social_image={social_image}
        social_image_one={social_image_one}
        social_image_two={social_image_two}
        social_image_three={social_image_three}
        social_image_four={social_image_four}
        social_image_five={social_image_five}
        social_image_six={social_image_six}
      />
    </>
  );
};
