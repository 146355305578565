import React from "react";
import { Button, Container } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "@mui/material/Skeleton";

export const Dharma = () => {
  const navigate = useNavigate();
  const handleTakeALook = (name) => {
    let path = "";
    if (name === "Dharma Goods") {
      path = "dharma-goods";
    } else {
      path = "good-dharma";
    }
    navigate(path);
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.dharmas);
    return res.data;
  };

  const { isLoading, data: Dharma } = useQuery({
    queryKey: ["dharma"],
    queryFn: getData,
  });

  if (isLoading)
    return (
      <Container>
        <Skeleton variant="rectangular" width="50%" height="200px" />
      </Container>
    );

  const Dharmalength = Dharma?.length;
  const oneItem = Dharmalength === 1;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
          md: "row",
          lg: "row",
          xl: "row",
        },
        justifyContent: "space-between",
        position: "absolute",
        left: oneItem ? "20%" : "50%",
        transform: "translateX(-50%)",
        top: {
          xs: "90%",
          sm: "80%",
          md: "80%",
          lg: "80%",
          xl: "80%",
        },
        marginBottom: "100px",
      }}
    >
      {Dharma?.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: {
                xs: "95%",
                sm: "95%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              height: {
                xs: "200px",
                sm: "200px",
                md: "300px",
                lg: "300px",
                xl: "300px",
              },
              position: "relative",
              top: "0",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 10px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: {
                  xs: "center",
                  sm: "center",
                  md: index % 2 === 0 ? "flex-end" : "flex-start", // Align even index Button to flex-end, and odd index Button to flex-start
                  lg: index % 2 === 0 ? "flex-end" : "flex-start",
                  xl: index % 2 === 0 ? "flex-end" : "flex-start",
                },
                position: "relative",
                top: {
                  xs: "25%",
                  sm: "25%",
                  md: "40%",
                  lg: "40%",
                  xl: "40%",
                },
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: index % 2 === 0 ? "flex-end" : "flex-start", // Align even index Button to flex-end, and odd index Button to flex-start
                  lg: index % 2 === 0 ? "flex-end" : "flex-start",
                  xl: index % 2 === 0 ? "flex-end" : "flex-start",
                },
                margin: "0 20px",
              }}
            >
              <Box sx={{ margin: "10px 0px 15px 0px" }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "23px",
                      sm: "25px",
                      md: "25px",
                      lg: "30px",
                      xl: "30px",
                    },
                    // justifyContent: item.justify,
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                    display: "flex",
                    backgroundColor: "var(--theme-color)",
                    width: "165px",
                    fontFamily: "var(--primary-font)",
                    ":hover": {
                      //zoom the buttom
                      transform: "scale(1.1)",
                      backgroundColor: "var(--theme-color)",
                    },
                  }}
                  onClick={() => handleTakeALook(item.name)}
                >
                  Take a look
                  <img
                    src={process.env.PUBLIC_URL + "./assets/icons/left.png"}
                    alt="..."
                    style={{
                      width: "15px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                    loading="lazy"
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Container>
  );
};
