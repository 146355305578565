import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { Cookies } from "react-cookie";
import ProjectDonationCheckoutForm from "./ProjectDonationCheckout";

const cookies = new Cookies();

export default function ProjectStripe({
  totalamount,
  projectId,
  handleCloseVisa,
}) {
  const userToken = cookies.get("token");
  const stripePromise = loadStripe(`${process.env.REACT_APP_PUBLISHABLE_KEY}`);
  const [clientSecret, setClientSecret] = useState("");
  // const items = [{ amount: total }];

  //string to int
  const totalAmount = parseInt(totalamount);

  const fetchClientSecret = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/stripe`,
        {
          amount: totalAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchClientSecret();
  }, []);

  return (
    <>
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <ProjectDonationCheckoutForm
            projectId={projectId}
            handleCloseVisa={handleCloseVisa}
          />
        </Elements>
      )}
    </>
  );
}
