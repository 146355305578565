import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SamplePrevArrow, SampleNextArrow } from "../Shared/arrows/arrows";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export default function TopSlider() {
  const [isMobile, setIsMobile] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // Set isMobile based on screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.home_top_slider);
    return res.data;
  };

  const { isLoading, data: TopSliderData } = useQuery({
    queryKey: ["topSliderData"],
    queryFn: getData,
  });

  if (isLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height="80vh" />
      </>
    );

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery) {
      navigate(`/search-results?q=${searchQuery}`);
    }
  };

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: isMobile ? false : true,
    nextArrow: !isMobile && <SampleNextArrow />,
    prevArrow: !isMobile && <SamplePrevArrow />,
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Slider {...settings} style={{ width: "100%" }}>
        {TopSliderData?.map((item, index) => (
          //place the child component in center of the parent component
          <Box
            key={index}
            sx={{
              backgroundImage: `url(${item.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "80vh",
              opacity: "0.5",
            }}
          ></Box>
        ))}
      </Slider>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "80vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "25%",
          marginBottom: "100px",
        }}
      >
        <Typography
          sx={{
            color: "white",
            // position: "absolute",
            // left: "500px",
            // top: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "var(--theme-color)",
            padding: "10px",
            marginBottom: "20px",
            fontSize: {
              xs: "25px",
              sm: "17px",
              md: "17px",
              lg: "20px",
              xl: "20px",
            },
            width: {
              xs: "100%",
              sm: "80%",
              md: "50%",
              lg: "57%",
              xl: "57%",
            },
          }}
          variant="p"
        >
          Looking for a Dharma Goods and Good Dharma?? search here!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            fontSize: {
              xs: "13px",
              sm: "13px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
            width: "100%",
          }}
        >
          <form onSubmit={handleSearch}>
            <TextField
              id="search"
              type="search"
              label="Search for Dharma goods and good Dharma"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{
                width: {
                  xs: "100%",
                  sm: "70%",
                  md: "350px",
                  lg: "532px",
                  xl: "532px",
                },
                borderRadius: "5px",
                backgroundColor: "#fff",
                border: "0",
                "& .MuiOutlinedInput-root": {
                  border: "0px solid var(--white)",
                  borderRadius: "0",
                  padding: "0",
                  height: "55px",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--theme-color)",
                  },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "var(--theme-color)",
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "var(--theme-color)",
                textTransform: "capitalize",
                height: "auto",
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  lg: "15px",
                  xl: "15px",
                },
                marginTop: {
                  xs: "10px",
                  sm: "10px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                padding: "15px",
                color: "var(--white)",
                "&:hover": {
                  backgroundColor: "var(--theme-color)",
                  color: "var(--white)",
                  transform: "scale(1.1)",
                },
              }}
            >
              Search
              <SearchIcon sx={{ marginLeft: "5px" }} />
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
}
