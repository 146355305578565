import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { OrderDetails } from "./OrderDetails";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses}`]: {
    margin: 0,
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#303030",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    fontFamily: "var(--primary-font)", // Apply custom font family
    color: "var(--light-black)", // Apply custom text color
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const OldOrders = () => {
  const [selectedId, setSelectedId] = React.useState(null);
  const { getAuthData } = useAPI();

  const getOldOrderDetails = async () => {
    const res = await getAuthData(endpoint.orderhistory);
    return res.data;
  };

  const { isLoading, data: oldorderDetails } = useQuery({
    queryKey: ["oldorderDetails"],
    queryFn: getOldOrderDetails,
  });

  if (isLoading) return <div>Loading...</div>;
  const handleViewDetails = (id) => {
    setSelectedId(id);
  };
  return (
    <Box>
      {selectedId ? (
        <OrderDetails
          orderId={selectedId}
          onClose={() => setSelectedId(null)}
        />
      ) : (
        <>
          {" "}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Order Code</StyledTableCell>
                    <StyledTableCell align="left">Product Name</StyledTableCell>
                    <StyledTableCell align="right">
                      Ordered Date
                    </StyledTableCell>
                    <StyledTableCell align="right">Quantity</StyledTableCell>
                    <StyledTableCell align="center">
                      Order Status
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      Payment Status
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oldorderDetails?.slice(0, 10)?.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.order_code}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row?.items?.map((item) => {
                          return (
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "var(--black)",
                                fontFamily: "var(--primary-font)",
                              }}
                              key={item.id}
                            >
                              {item.product?.name}
                              {item.service?.name}
                            </Typography>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.created_at}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.items?.map((item) => {
                          return <span>{item.qty}</span>;
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor:
                                row.status === "delivered"
                                  ? "var(--green)"
                                  : "red",
                              color: "var(--white)",
                              padding: "5px",
                              width: "fit-content",
                              borderRadius: "5px",
                              textAlign: "center",
                              textTransform: "capitalize",
                            }}
                          >
                            {row.status}
                          </Box>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left ">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--green)",
                              color: "var(--white)",
                              padding: "5px",
                              width: "fit-content",
                              borderRadius: "5px",
                            }}
                          >
                            paid
                          </Box>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="outlined"
                          sx={{
                            backgroundColor: "var(--theme-color)",
                            color: "var(--white)",
                            borderRadius: "5px",
                            textTransform: "capitalize",
                            border: "1px solid var(--theme-color)",
                            fontSize: {
                              xs: "11px",
                              sm: "11px",
                              md: "12px",
                              lg: "13px",
                              xl: "13px",
                            },
                            "&:hover": {
                              backgroundColor: "transparent",
                              color: "var(--theme-color)",
                              border: "1px solid var(--theme-color)",
                            },
                          }}
                          onClick={() => {
                            handleViewDetails(row.id);
                          }}
                        >
                          View Details
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 250 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Order Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oldorderDetails?.slice(0, 10)?.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "var(--black)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            OrderCode:{" "}
                            <span style={{ color: "var(--theme-color)" }}>
                              {row.order_code}
                            </span>
                          </Typography>
                          {row?.items?.map((item) => {
                            return (
                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  color: "var(--black)",
                                  fontFamily: "var(--primary-font)",
                                }}
                              >
                                {item.product !== null &&
                                  item.product !== "" && (
                                    <>
                                      Product Name:{" "}
                                      <span
                                        style={{
                                          color: "var(--theme-color)",
                                        }}
                                      >
                                        {item.product?.name}
                                      </span>
                                    </>
                                  )}
                                {item.service !== null &&
                                  item.service !== "" && (
                                    <>
                                      Service Name:{" "}
                                      <span
                                        style={{
                                          color: "var(--theme-color)",
                                        }}
                                      >
                                        {item.service?.name}
                                      </span>
                                    </>
                                  )}
                              </Typography>
                            );
                          })}

                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "var(--black)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            Ordered Date:{" "}
                            <span style={{ color: "var(--theme-color)" }}>
                              {row.created_at}
                            </span>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "var(--black)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            Quantity:{" "}
                            <span style={{ color: "var(--theme-color)" }}>
                              {row?.items?.map((item) => {
                                return <span>{item.qty}</span>;
                              })}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "20px",
                              marginTop: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                backgroundColor:
                                  row.status === "delivered"
                                    ? "var(--green)"
                                    : "red",
                                color: "var(--white)",
                                padding: "5px",
                                width: "fit-content",
                                borderRadius: "5px",
                                textAlign: "center",
                                textTransform: "capitalize",
                              }}
                            >
                              {row.status}
                            </Typography>
                            <Typography
                              sx={{
                                backgroundColor: "var(--green)",
                                color: "var(--white)",
                                padding: "5px",
                                width: "fit-content",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              paid
                            </Typography>
                          </Box>
                          <Button
                            variant="outlined"
                            sx={{
                              backgroundColor: "var(--theme-color)",
                              color: "var(--white)",
                              textTransform: "capitalize",
                              borderRadius: "5px",
                              border: "1px solid var(--theme-color)",
                              fontSize: {
                                xs: "11px",
                                sm: "11px",
                                md: "12px",
                                lg: "13px",
                                xl: "13px",
                              },
                              marginTop: "10px",
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "var(--theme-color)",
                                border: "1px solid var(--theme-color)",
                              },
                            }}
                            onClick={() => {
                              handleViewDetails(row.id);
                            }}
                          >
                            View Details
                          </Button>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </Box>
  );
};
