import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const hor_line = {
  width: "100%",
  height: "2px",
  backgroundColor: "var(--light-grey)",
  marginTop: "20px",
  marginBottom: "20px",
};

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

export const QuestionForm = ({ handleClose, prodTitle, prodId }) => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);
  const initialValues = {
    question: "",
  };

  const validationSchema = Yup.object().shape({
    question: Yup.string()
      .required("Required")
      .min(10, "Must be 10 characters or more")
      .max(128, "Must be 100 characters or less"),
  });

  const submitHandler = async (values, { resetForm }) => {
    values.product_id = prodId;
    try {
      await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/products/${prodId}/question`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Question posted successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
    resetForm();
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            color: "var(--theme-color)",
            fontWeight: "500",
            fontFamily: "var(--primary-font)",
            fontSize: {
              xs: "13px",
              sm: "14px",
              md: "15px",
              lg: "16px",
              xl: "16px",
            },
          }}
        >
          Ask questions for “{prodTitle}”
        </Typography>
        <Button
          sx={{
            // position: "absolute",
            // top: "0px",
            // right: "0px",
            color: "var(--black-color)",
            "&:hover": {
              backgroundColor: "transparent",
              color: "var(--theme-color)",
            },
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Button>
      </Box>

      <div style={hor_line}></div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched, isValid, dirty, values }) => (
          <Form>
            <Box
              sx={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{
                  color: "var(--black-color)",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "10px",
                }}
              >
                Question:
              </InputLabel>
              <Field
                as={TextField}
                variant="outlined"
                size="large"
                className={
                  errors.question && touched.question ? "input-error" : null
                }
                name="question"
                id="input-with-icon-adornment"
                sx={{
                  backgroundColor: "transparent",
                  fontFamily: "var(--primary-font)",
                  width: "100%",

                  // padding: "1px",
                  " & .MuiOutlinedInput-root": {
                    "  &.Mui-focused fieldset": {
                      borderColor: "var(--btn-color)",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "0px solid var(--theme-color)",
                    borderRadius: "0",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    // border: "1px solid #E4E4E4",
                    borderRadius: "5px",
                  },
                  //border colour when focused
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "0px solid var(--btn-color))",
                    },
                  //floating label color when focused
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "var(--btn-color)",
                    backgroundColor: "transparent",
                  },
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "12px",
                    lg: "12px",
                    xl: "12px",
                  },
                  color: "var(--btn-color)",
                  fontFamily: "var(--primary-font)",
                  alignSelf: "flex-end",
                }}
              >
                Character count: 0/128
              </Typography>
              <ErrorMessage
                name="question"
                component="span"
                className="error"
                style={errorStyle}
              />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  backgroundColor: "var(--theme-color)",
                  color: "var(--white)",
                  borderRadius: "5px",
                  border: "1px solid var(--theme-color)",
                  marginRight: "20px",
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "10px",
                    sm: "11px",
                    md: "11px",
                    lg: "12px",
                    xl: "12px",
                  },
                  padding: "10px 15px 10px 15px",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "var(--theme-color)",
                    border: "1px solid var(--theme-color)",
                  },
                }}
              >
                Post Question
              </Button>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "var(--white)",
                  color: "var(--light-black)",
                  borderRadius: "5px",
                  border: "1px solid var(--light-black)",
                  textTransform: "capitalize",
                  fontSize: {
                    xs: "10px",
                    sm: "11px",
                    md: "11px",
                    lg: "12px",
                    xl: "12px",
                  },
                  padding: "10px 15px 10px 15px",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "var(--theme-color)",
                    border: "1px solid var(--theme-color)",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
