import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { Typography } from "@mui/material";
import { OldOrders } from "./OldOrders";
import { NewOrders } from "./NewOrders";
import { styled } from "@mui/system";
const CustomTabPanel = styled(TabPanel)({
  "&.MuiTabPanel-root": {
    padding: "30px 0px 0px 0px !important",
  },
});

export const Orders = (props) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indicatorStyles = {
    backgroundColor: "var(--black)", // Change this to your desired color
    height: "3px", // Adjust the height of the indicator if needed
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          color: "var(--black)",
          fontWeight: "500",
          fontFamily: "var(--primary-font)",
          marginBottom: "20px",
          marginTop: "80px",
          fontSize: {
            xs: "14px",
            sm: "16px",
            md: "18px",
            lg: "20px",
            xl: "24px",
          },
        }}
      >
        Order List
      </Typography>
      <Box sx={{ marginTop: "30px", width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: indicatorStyles,
              }}
            >
              <Tab
                label="New Orders"
                value="1"
                sx={{
                  fontFamily: "var(--primary-font)",
                }}
              />
              <Tab
                label="Order History"
                value="2"
                sx={{
                  fontFamily: "var(--primary-font)",
                }}
              />
            </TabList>
          </Box>
          <CustomTabPanel
            {...props}
            value="1"
            sx={{
              padding: 0, // Remove padding from the TabPanel
            }}
          >
            <NewOrders />
          </CustomTabPanel>
          <CustomTabPanel {...props} value="2">
            <OldOrders />
          </CustomTabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};
