import React from "react";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

const selectcounties = [
  {
    value: "USA",
    label: "USA",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Japan",
    label: "Japan",
  },
];

const selectStates = [
  {
    value: "State1",
    label: "State1",
  },
  {
    value: "State2",
    label: "State2",
  },
  {
    value: "State3",
    label: "State3",
  },
];

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

export const InformationForm = ({ onContinue }) => {
  const [emailmecheckBox, setEmailmecheckBox] = React.useState(false);
  const [differentreceivercheckBox, setDifferentreceivercheckBox] =
    React.useState(false);
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);
  
  const [isLoading, setIsLoading] = React.useState(false);

  const userData = JSON.parse(localStorage.getItem("userInformation"));

  const initialValues = {
    country: userData?.country ? userData.country : "Nepal",
    name: userData?.name ? userData.name : "",
    email: userData?.email ? userData.email : "",
    number: userData?.number ? userData.number : "",
    emailme: userData?.email_me ? userData?.email_me : false,
    shippingcountry: userData?.receiver_country
      ? userData.receiver_country
      : "Nepal",
    differentreceiver: false,
    ReceiversfirstName: userData?.receiver_firstname
      ? userData?.receiver_firstname
      : "",
    ReceiverslastName: userData?.receiver_secondname
      ? userData?.receiver_secondname
      : "",
    ReceiverEmail: userData?.receiver_email ? userData?.receiver_email : "",
    ReceiverNumber: userData?.receiver_number ? userData?.receiver_number : "",
    ReceiverAddress: userData?.receiver_address
      ? userData?.receiver_address
      : "",
    ReceiverState: userData?.receiver_state
      ? userData.receiver_state
      : "State1",
    ReceiverCity: userData?.receiver_city ? userData?.receiver_city : "",
    ReceiverZipcode: userData?.receiver_zipcode
      ? userData.receiver_zipcode
      : "",
  };
  const phoneRegExp = /^[0-9]{10}$/;

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Required"),
    name: Yup.string()
      .required("Required")
      .min(1, "First name should be at least 3 characters"),
    email: Yup.string().required("Required").email("Invalid email format"),
    number: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Contact number is not valid")
      .min(10, "Contact number should be at least 10 digits")
      .max(10, "Contact number should be at most 10 digits"),
    shippingcountry: Yup.string().required("Required"),
    ReceiverNumber: Yup.string()
      .required("Required")
      .matches(phoneRegExp, "Contact number is not valid")
      .min(10, "Contact number should be at least 10 digits")
      .max(10, "Contact number should be at most 10 digits"),
    ReceiverAddress: Yup.string().required("Required"),
    ReceiverState: Yup.string().required("Required"),
    ReceiverCity: Yup.string().required("Required"),
    ReceiverZipcode: Yup.string()
      .required("Required")
      .min(2, "Zip code should be at most 10 digits"),
  });

  const submitHandler = async (values) => {
    setIsLoading(true);
    values.emailme = emailmecheckBox;

    const updatedValues = {
      name: values.name,
      email: values.email,
      number: values.ReceiverNumber,
      country: values.country,
      email_me: values.emailme === true ? 1 : 0,
      receiver_country: values.shippingcountry,
      receiver_firstname: values.ReceiversfirstName
        ? values.ReceiversfirstName
        : values.name,
      receiver_secondname: values.ReceiverslastName
        ? values.ReceiverslastName
        : values.name,
      receiver_email: values.ReceiverEmail
        ? values.ReceiverEmail
        : values.email,
      receiver_number: values.ReceiverNumber,
      receiver_address: values.ReceiverAddress,
      receiver_state: values.ReceiverState,
      receiver_city: values.ReceiverCity,
      receiver_zipcode: values.ReceiverZipcode,
    };
    localStorage.setItem("userInformation", JSON.stringify(updatedValues));
    try {
      await axios.put(
        process.env.REACT_APP_JINLAP_BASE_URL + `/updateUser`,
        updatedValues,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Information submitted successfully");
      onContinue();
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        // backgroundColor: "var(--white)",
        marginTop: "30px",
        marginBottom: "80px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "15px 0px 15px 0px",
          width: "100%",
        }}
      >
        <Typography
          variant="p"
          sx={{
            color: "var(--light-black)",
            fontFamily: "var(--primary-font)",
            fontSize: {
              xs: "10px",
              sm: "11px",
              md: "12px",
              lg: "14px",
              xl: "14px",
            },
          }}
        >
          <span
            style={{
              color: "var(--theme-color)",
              fontFamily: "var(--primary-font)",
              fontWeight: "600",
            }}
          >
            Information
          </span>
          {">"} Shipping {">"} Payment
        </Typography>
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "90%",
            md: "90%",
            lg: "90%",
            xl: "90%",
          },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched }) => (
            <Form>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {/* title */}
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "15px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "20px",
                      },
                      fontWeight: "600",
                      color: "var(--black)",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    Personal Information
                  </Typography>
                </Box>
                {/* start of information form */}
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        color: "var(--black-color)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      Select Country:
                    </InputLabel>

                    <Field
                      as={TextField}
                      id="project-select"
                      name="country"
                      select
                      defaultValue="Nepal"
                      size="small"
                      className={
                        errors.country && touched.country ? "input-error" : null
                      }
                      sx={{
                        height: "50px",
                        width: "100%",
                        // textAlign: "center",
                        borderRadius: "5px",
                        color: "#E4E4E4",
                        backgroundColor: "var(--white)",
                        textAlign: "justify",
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "var(--black-color)", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "var(--black-color)", // Change border color on focus
                          },
                        },
                      }}
                    >
                      {selectcounties.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          // sx={{
                          //   color:
                          //     option.value === values.country
                          //       ? "var(--theme-color)"
                          //       : "inherit",
                          //   backgroundColor:
                          //     option.value === values.country
                          //       ? "white"
                          //       : "transparent",
                          //   // "&:hover": {
                          //   //   backgroundColor: "var(--theme-color)",
                          //   // },
                          // }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="country"
                      component="span"
                      className="error"
                      style={errorStyle}
                    />
                  </Box>
                  {/* <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: {
                        xs: "row",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  > */}
                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        color: "var(--black-color)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      Full Name:
                    </InputLabel>
                    <Field
                      as={TextField}
                      variant="outlined"
                      autoComplete="off"
                      size="small"
                      name="name"
                      id="input-with-icon-adornment"
                      className={
                        errors.name && touched.name ? "input-error" : null
                      }
                      sx={{
                        backgroundColor: "transparent",
                        fontFamily: "var(--primary-font)",
                        width: "100%",

                        " & .MuiOutlinedInput-root": {
                          "  &.Mui-focused fieldset": {
                            borderColor: "var(--btn-color)",
                          },
                        },

                        "& .MuiOutlinedInput-root": {
                          border: "0px solid var(--theme-color)",
                          borderRadius: "0",
                          padding: "0",
                        },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            // border: "1px solid #E4E4E4",
                            borderRadius: "5px",
                          },
                        //border colour when focused
                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "0px solid var(--btn-color))",
                          },
                        //floating label color when focused
                        "& .MuiInputLabel-outlined.Mui-focused": {
                          color: "var(--btn-color)",
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="error"
                      style={errorStyle}
                    />
                  </Box>
                  {/* <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: "5%",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        Last Name:
                      </InputLabel>
                      <Field
                        as={TextField}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        name="lastName"
                        id="input-with-icon-adornment"
                        className={
                          errors.lastName && touched.lastName
                            ? "input-error"
                            : null
                        }
                        sx={{
                          backgroundColor: "transparent",
                          fontFamily: "var(--primary-font)",
                          width: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--btn-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--theme-color)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              // border: "1px solid #E4E4E4",
                              borderRadius: "5px",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "0px solid var(--btn-color))",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--btn-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                  </Box> */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: {
                        xs: "row",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        Email Address:
                      </InputLabel>
                      <Field
                        as={TextField}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        name="email"
                        id="input-with-icon-adornment"
                        className={
                          errors.email && touched.email ? "input-error" : null
                        }
                        sx={{
                          backgroundColor: "transparent",
                          fontFamily: "var(--primary-font)",
                          width: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--btn-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--theme-color)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              // border: "1px solid #E4E4E4",
                              borderRadius: "5px",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "0px solid var(--btn-color))",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--btn-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: "5%",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        Contact Number:
                      </InputLabel>
                      <Field
                        as={TextField}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        name="number"
                        type="number"
                        id="input-with-icon-adornment"
                        className={
                          errors.number && touched.number ? "input-error" : null
                        }
                        sx={{
                          backgroundColor: "transparent",
                          fontFamily: "var(--primary-font)",
                          width: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--btn-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--theme-color)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              // border: "1px solid #E4E4E4",
                              borderRadius: "5px",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "0px solid var(--btn-color))",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--btn-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="number"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "left",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox name="checkbox" />}
                      labelPlacement="end"
                      // name="emailme"
                      // className={
                      //   errors.emailme && touched.emailme ? "input-error" : null
                      // }
                      value={emailmecheckBox}
                      onChange={() => {
                        setEmailmecheckBox(!emailmecheckBox);
                      }}

                      // label="I’d like to cover the fees associated with my donation so more of my donation goes directly to Jinlap Organization."
                    />
                    <Typography
                      sx={{
                        color: "var(--black-color)",
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                          lg: "14px",
                          xl: "14px",
                        },
                      }}
                    >
                      Email me with news and offers
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* start of shipping part */}

              <Box
                sx={{
                  width: "100%",
                }}
              >
                {/* title */}
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "15px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "20px",
                      },
                      fontWeight: "600",
                      color: "var(--black)",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "50px",
                    }}
                  >
                    Shipping Address
                  </Typography>
                </Box>
                {/* start of shipping form */}
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        color: "var(--black-color)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      Select Country:
                    </InputLabel>

                    <Field
                      as={TextField}
                      labelId="sort-order-label"
                      id="project-select"
                      name="shippingcountry"
                      select
                      defaultValue="Nepal"
                      size="small"
                      className={
                        errors.shippingcountry && touched.shippingcountry
                          ? "input-error"
                          : null
                      }
                      sx={{
                        height: "50px",
                        width: "100%",
                        // textAlign: "center",
                        borderRadius: "5px",
                        color: "var(--btn-color)",
                        backgroundColor: "var(--white)",
                        textAlign: "justify",
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "var(--black-color)", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "var(--black-color)", // Change border color on focus
                          },
                        },
                      }}
                    >
                      {selectcounties.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Field>

                    <ErrorMessage
                      name="shippingcountry"
                      component="span"
                      className="error"
                      style={errorStyle}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      textAlign: "left",
                      //   marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox name="checkbox" />}
                      labelPlacement="end"
                      //  name="differentreceiver"
                      // className={
                      //   errors.differentreceiver && touched.differentreceiver
                      //     ? "input-error"
                      //     : null
                      // }
                      value={differentreceivercheckBox}
                      onChange={() => {
                        setDifferentreceivercheckBox(
                          !differentreceivercheckBox
                        );
                      }}

                      // label="I’d like to cover the fees associated with my donation so more of my donation goes directly to Jinlap Organization."
                    />
                    <Typography
                      sx={{
                        color: "var(--black-color)",
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                          lg: "14px",
                          xl: "14px",
                        },
                      }}
                    >
                      Different receiver from you?
                    </Typography>
                  </Box>
                  {differentreceivercheckBox ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: {
                            xs: "row",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            Receiver firstName:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="ReceiversfirstName"
                            id="input-with-icon-adornment"
                            className={
                              errors.ReceiversfirstName &&
                              touched.ReceiversfirstName
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="ReceiversfirstName"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                        <Box
                          sx={{
                            marginTop: "20px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginLeft: "5%",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                            }}
                          >
                            Receiver lastName:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="ReceiverslastName"
                            id="input-with-icon-adornment"
                            className={
                              errors.ReceiverslastName &&
                              touched.ReceiverslastName
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="ReceiverslastName"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "20px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <InputLabel
                          htmlFor="input-with-icon-adornment"
                          sx={{
                            color: "var(--black-color)",
                            fontFamily: "var(--primary-font)",
                          }}
                        >
                          Email Address:
                        </InputLabel>
                        <Field
                          as={TextField}
                          variant="outlined"
                          autoComplete="off"
                          size="small"
                          name="ReceiverEmail"
                          id="input-with-icon-adornment"
                          className={
                            errors.ReceiverEmail && touched.ReceiverEmail
                              ? "input-error"
                              : null
                          }
                          sx={{
                            backgroundColor: "transparent",
                            fontFamily: "var(--primary-font)",
                            width: "100%",

                            " & .MuiOutlinedInput-root": {
                              "  &.Mui-focused fieldset": {
                                borderColor: "var(--btn-color)",
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              border: "0px solid var(--theme-color)",
                              borderRadius: "0",
                              padding: "0",
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                // border: "1px solid #E4E4E4",
                                borderRadius: "5px",
                              },
                            //border colour when focused
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                border: "0px solid var(--btn-color))",
                              },
                            //floating label color when focused
                            "& .MuiInputLabel-outlined.Mui-focused": {
                              color: "var(--btn-color)",
                              backgroundColor: "transparent",
                            },
                          }}
                        />
                        <ErrorMessage
                          name="ReceiverEmail"
                          component="span"
                          className="error"
                          style={errorStyle}
                        />
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: {
                        xs: "row",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        Secondary/Receiver Number:
                      </InputLabel>
                      <Field
                        as={TextField}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        name="ReceiverNumber"
                        id="input-with-icon-adornment"
                        type="number"
                        className={
                          errors.ReceiverNumber && touched.ReceiverNumber
                            ? "input-error"
                            : null
                        }
                        sx={{
                          backgroundColor: "transparent",
                          fontFamily: "var(--primary-font)",
                          width: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--btn-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--theme-color)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              // border: "1px solid #E4E4E4",
                              borderRadius: "5px",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "0px solid var(--btn-color))",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--btn-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="ReceiverNumber"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: "5%",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        Address:
                      </InputLabel>
                      <Field
                        as={TextField}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        name="ReceiverAddress"
                        id="input-with-icon-adornment"
                        type="text"
                        className={
                          errors.ReceiverAddress && touched.ReceiverAddress
                            ? "input-error"
                            : null
                        }
                        sx={{
                          backgroundColor: "transparent",
                          fontFamily: "var(--primary-font)",
                          width: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--btn-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--theme-color)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              // border: "1px solid #E4E4E4",
                              borderRadius: "5px",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "0px solid var(--btn-color))",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--btn-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="ReceiverAddress"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: {
                        xs: "row",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        City:
                      </InputLabel>
                      <Field
                        as={TextField}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        name="ReceiverCity"
                        id="input-with-icon-adornment"
                        className={
                          errors.ReceiverCity && touched.ReceiverCity
                            ? "input-error"
                            : null
                        }
                        sx={{
                          backgroundColor: "transparent",
                          fontFamily: "var(--primary-font)",
                          width: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--btn-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--theme-color)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              // border: "1px solid #E4E4E4",
                              borderRadius: "5px",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "0px solid var(--btn-color))",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--btn-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="ReceiverCity"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: "5%",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        State:
                      </InputLabel>
                      <Field
                        as={TextField}
                        labelId="sort-order-label"
                        id="project-select"
                        name="ReceiverState"
                        select
                        defaultValue="State1"
                        size="small"
                        className={
                          errors.ReceiverState && touched.ReceiverState
                            ? "input-error"
                            : null
                        }
                        sx={{
                          height: "50px",
                          width: "100%",
                          // textAlign: "center",
                          borderRadius: "5px",
                          color: "var(--btn-color)",
                          backgroundColor: "var(--white)",
                          textAlign: "justify",
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "var(--black-color)", // Change border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "var(--black-color)", // Change border color on focus
                            },
                          },
                        }}
                      >
                        {selectStates.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="ReceiverState"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                    <Box
                      sx={{
                        marginTop: "20px",
                        width: "100%",
                        // display: {
                        //   xs: "none",
                        //   sm: "flex",
                        //   md: "flex",
                        //   lg: "flex",
                        //   xl: "flex",
                        // },
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: "5%",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        ZipCode:
                      </InputLabel>
                      <Field
                        as={TextField}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                        name="ReceiverZipcode"
                        id="input-with-icon-adornment"
                        className={
                          errors.ReceiverZipcode && touched.ReceiverZipcode
                            ? "input-error"
                            : null
                        }
                        sx={{
                          backgroundColor: "transparent",
                          fontFamily: "var(--primary-font)",
                          width: "100%",

                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--btn-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--theme-color)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              // border: "1px solid #E4E4E4",
                              borderRadius: "5px",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "0px solid var(--btn-color))",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--btn-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="ReceiverZipcode"
                        component="span"
                        className="error"
                        style={errorStyle}
                      />
                    </Box>
                  </Box>
                  {/* <Box
                    sx={{
                      marginTop: "20px",
                      width: "48%",
                      display: {
                        xs: "flex",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        color: "var(--black-color)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      ZipCode:
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="ReceiverZipcode2"
                      id="input-with-icon-adornment"
                      className={
                        errors.ReceiverZipcode2 && touched.ReceiverZipcode2
                          ? "input-error"
                          : null
                      }
                      sx={{
                        backgroundColor: "transparent",
                        fontFamily: "var(--primary-font)",
                        width: "100%",

                        " & .MuiOutlinedInput-root": {
                          "  &.Mui-focused fieldset": {
                            borderColor: "var(--btn-color)",
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          border: "0px solid var(--theme-color)",
                          borderRadius: "0",
                          padding: "0",
                        },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            // border: "1px solid #E4E4E4",
                            borderRadius: "5px",
                          },
                        //border colour when focused
                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "0px solid var(--btn-color))",
                          },
                        //floating label color when focused
                        "& .MuiInputLabel-outlined.Mui-focused": {
                          color: "var(--btn-color)",
                          backgroundColor: "transparent",
                        },
                      }}
                    />
                    <ErrorMessage
                      name="ReceiverZipcode2"
                      component="span"
                      className="error"
                      style={errorStyle}
                    />
                  </Box> */}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                    justifyContent: {
                      xs: "flex-start",
                      sm: "space-between",
                      md: "space-between",
                      lg: "space-between",
                      xl: "space-between",
                    },
                    alignItems: {
                      xs: "flex-start",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "center",
                    },
                    marginTop: "40px",
                  }}
                >
                  <NavLink
                    to="/cart"
                    style={{
                      color: "var(--theme-color)",
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "./assets/leftarrow.png"}
                      alt="..."
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                      }}
                      loading="lazy"
                    />
                    Return to cart
                  </NavLink>
                  <Button
                    variant="outlined"
                    type="submit"
                    // onClick={onContinue}
                    // className={!(dirty && isValid) ? "disabled-btn" : ""}
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      color: "var(--white)",
                      fontFamily: "var(--primary-font)",
                      textTransform: "capitalize",
                      marginTop: {
                        xs: "20px",
                        sm: "0px",
                        md: "0px",
                        lg: "0px",
                        xl: "0px",
                      },
                      borderRadius: "5px",
                      padding: "10px 20px 10px 20px",
                      borderColor: "var(--theme-color)",
                      fontSize: {
                        xs: "9px",
                        sm: "10px",
                        md: "11px",
                        lg: "12px",
                        xl: "12px",
                      },
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--theme-color)",
                        borderColor: "var(--theme-color)",
                      },
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span style={{ color: "white" }}>Processing...</span>
                    ) : (
                      "Continue to shipping"
                    )}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
