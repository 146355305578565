/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { CustNextArrow, CustPrevArrow } from "../../Shared/arrows/arrows";
import "./NotesSlider.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NotesData } from "../../../ApiIntegration/AboutUsApi/NotesApi";

export const NotesSlider = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [notesData, setNotesData] = React.useState([]);

  useEffect(() => {
    // Set isMobile based on screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch notes data and extract the image URLs from the notesData array
    const fetchNotesDataAsync = async () => {
      try {
        const apiData = await NotesData();
        setNotesData(apiData);
        const urls = apiData?.map((item) => item.image);
        setImageUrls(urls);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchNotesDataAsync();
  }, []);

  const settings = {
    customPaging: function (i) {
      return (
        <a className="custom-paging">
          <img src={imageUrls[i]} alt="..." loading="lazy" />
        </a>
      );
    },

    nextArrow: isMobile ? null : <CustNextArrow />,
    prevArrow: isMobile ? null : <CustPrevArrow />,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    pauseOnHover: true,
  };

  return (
    <Box className="layout">
      <Box className="slider-bottom-container">
        {/* {imageUrls.length > 0 ? ( */}
        <Slider {...settings}>
          {notesData?.map((item) => (
            <ul key={item.id}>
              <li
                className="slider-item"
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  position: "relative", // Set the position of the slider item to relative
                }}
              >
                <Box
                  className="image-container"
                  sx={{
                    margin: {
                      xs: "10px",
                      sm: "0px 15px 0px 0px",
                      md: "0px 15px 0px 0px",
                      lg: "10px",
                      xl: "10px",
                    },
                  }}
                >
                  <div className="line-vertical"></div>
                  <div className="line-horizontal"></div>
                  <img src={item.image} alt="..." loading="lazy" />
                </Box>
                <Box
                  // className="data-container"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    // backgroundColor: "blue",
                    // paddingTop: "20px",
                  }}
                >
                  <Box
                    className="writer-container"
                    sx={{
                      position: {
                        xs: "relative",
                        sm: "relative",
                        md: "absolute",
                        lg: "absolute",
                        xl: "absolute",
                      }, // Set the writer-container to be sticky
                      top: {
                        xs: "0px",
                        sm: "0px",
                        md: "10px",
                        lg: "10px",
                        xl: "10px",
                      }, // Align the writer-container to the top of the slider item

                      textAlign: {
                        xs: "justify",
                        sm: "justify",
                        md: "justify",
                        lg: "justify",
                        xl: "justify",
                      },
                      marginLeft: {
                        xs: "0px",
                        sm: "0px",
                        md: "20px",
                        lg: "20px",
                        xl: "20px",
                      },
                      marginTop: {
                        xs: "30px",
                        sm: "30px",
                        md: "0px",
                        lg: "0px",
                        xl: "0px",
                      },
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: {
                          xs: "22px",
                          sm: "22px",
                          md: "22px",
                          lg: "24px",
                          xl: "24px",
                        },
                        color: "var(--theme-color)",
                        fontWeight: "600",
                        fontFamily: "var(--primary-font)",
                      }}
                      className="writer-name"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "13px",
                          md: "14px",
                          lg: "14px",
                          xl: "14px",
                        },
                        color: "var(--light-black)",
                        fontFamily: "var(--primary-font)",
                        padding: "10px, 20px, 10px, 20px",
                        backgroundColor: "#FFFFFF",
                      }}
                      className="writer-position"
                    >
                      {item.designation}, {item.company_name},{" "}
                      {item.country_name}
                    </Typography>
                  </Box>
                  <Box
                    className="trust-description-container"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "40px", // Add a little gap between writer-container and trust-description-container
                      // backgroundColor: "red",
                      // margin: "20px",
                      textAlign: {
                        xs: "center",
                        sm: "center",
                        md: "justify",
                        lg: "justify",
                        xl: "justify",
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "18px",
                          sm: "18px",
                          md: "18px",
                          lg: "20px",
                          xl: "20px",
                        },
                        color: "var(--theme-color)",
                      }}
                      className="trust-title"
                    >
                      "{item.title}"
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "13px",
                          sm: "13px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                        color: "var(--light-black)",
                        textAlign: {
                          xs: "left",
                          sm: "left",
                          md: "center",
                          lg: "center",
                          xl: "center",
                        },
                      }}
                      className="trust-description"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></Typography>
                  </Box>
                </Box>
              </li>
            </ul>
          ))}
        </Slider>
        {/* ) : (
          <p>Loading...</p>
        )} */}
      </Box>
    </Box>
  );
};
