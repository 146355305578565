import React from "react";
import "./Supportproject.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import ReactPlayer from "react-player";

export const Supportproject = () => {
  const navigate = useNavigate();

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.home_support_projects);
    return res.data;
  };

  const { isLoading, data: supportprojectsData } = useQuery({
    queryKey: ["supportprojects"],
    queryFn: getData,
  });

  if (isLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="50%" height="55vh" />
      </>
    );


  return (
    <>
      {supportprojectsData?.hide !== 1 && (
        <>
          {supportprojectsData && (
            <Box
              className="supportproject"
              sx={{
                background: {
                  xs: supportprojectsData.image
                    ? `url(${supportprojectsData.image})`
                    : "var(--theme-color)",
                  sm: supportprojectsData.image
                    ? `url(${supportprojectsData.image})`
                    : "var(--theme-color)",
                  md: supportprojectsData.image
                    ? `url(${supportprojectsData.image})`
                    : "var(--theme-color)",
                  lg: "var(--theme-color)",
                  xl: "var(--theme-color)",
                },
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                height: "auto",

                // backgroundColor: "green",
                display: "flex",
                flexDirection: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "row",
                  xl: "row",
                },
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xl: "none",
                  },
                  height: {
                    xs: "6 6vh",
                    sm: "38.5vh",
                    md: "30.5vh",
                    lg: "0",
                    xl: "0",
                  },
                  top: 0,
                  left: 0,
                  zIndex: "100",
                  background:
                    "linear-gradient(to right,  rgba(202,81,141,0.19100140056022408) 34%, rgba(150,25,90,0.9781162464985994) 99%)",
                }}
              ></Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "55%",
                    md: "55%",
                    lg: "55%",
                    xl: "55%",
                  },
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                    xl: "block",
                  },
                  height: {
                    xs: "auto",
                    sm: "auto",
                    md: "auto",
                    lg: "73vh",
                    xl: "73vh",
                  },
                  position: "relative",
                }}
              >
                <img
                  src={supportprojectsData?.image}
                  alt="..."
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading="lazy"
                />

                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: {
                      xs: "auto",
                      sm: "auto",
                      md: "auto",
                      lg: "73vh",
                      xl: "73vh",
                    },
                    top: 0,
                    left: 0,

                    zIndex: "100",
                    background:
                      "linear-gradient(to right,  rgba(202,81,141,0.19100140056022408) 34%, rgba(150,25,90,0.9781162464985994) 99%)",
                  }}
                ></Box>
              </Box>
              <Box
                className="supporprojecttdesc"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "90%",
                    md: "90%",
                    lg: "45%",
                    xl: "45%",
                  },
                  marginLeft: {
                    xs: "20px",
                    sm: "0px",
                    md: "0px",
                    lg: "0px",
                    xl: "0px",
                  },
                  marginRight: {
                    xs: "20px",
                    sm: "50px",
                    md: "50px",
                    lg: "80px",
                    xl: "80px",
                  },
                  zIndex: "100",
                  padding: {
                    xs: "20px 0px 0px 0px",
                    sm: "20px 0px 0px 0px",
                    md: "20px 0px 0px 0px",
                    lg: "0px",
                    xl: "0px",
                  },
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--white)",
                    marginTop: "20px",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontFamily: "var(--primary-font)",
                    textTransform: "uppercase",
                  }}
                >
                  {supportprojectsData?.title}
                </Typography>
                <br />
                <br />
                <Typography
                  variant="h4"
                  sx={{
                    color: "var(--white)",
                    marginBottom: "20px",
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "20px",
                      lg: "25px",
                      xl: "25px",
                    },
                    fontFamily: "var(--primary-font)",
                  }}
                >
                  {supportprojectsData.title_first}
                </Typography>

                <hr style={{ color: "#fff" }} />
                <Typography
                  variant="p"
                  sx={{
                    color: "white",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  {supportprojectsData.description}
                </Typography>
                <br />
                <br />
                <Box sx={{ fontSize: "20px" }}>
                  <Typography
                    variant="p"
                    sx={{
                      color: "var(--white)",
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "15px",
                        lg: "20px",
                        xl: "20px",
                      },
                    }}
                  >
                    {supportprojectsData.title_second}
                  </Typography>{" "}
                </Box>

                <Box sx={{ marginTop: "20px", marginBottom: "50px" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "var(--white)",
                      display: "flex",
                      justifyContent: "flex-end",
                      border: "1px solid #fff",
                      fontSize: {
                        xs: "12px",
                        sm: "12px",
                        md: "12px",
                        lg: "15px",
                        xl: "15px",
                      },
                      "&:hover": {
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                        border: "1px solid var(--white)",
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={() => {
                      navigate("/projects");
                    }}
                  >
                    {supportprojectsData?.button_name}
                    <img
                      src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                      alt="..."
                      style={{
                        width: "15px",
                        height: "20px",
                        marginLeft: "10px",
                      }}
                      loading="lazy"
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};
