import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { GoodDharmaTopContent } from "../GoodDharmaTopContent/GoodDharmaTopContent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export const SearchSection = ({
  sorting,
  btnlabel,
  GoodDharmaDataLength,
  Name,
  id,
}) => {
  const [sort, setSort] = React.useState("");
  const [query, setQuery] = React.useState("");

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  const handleQuery = (event) => {
    setQuery(event.target.value);
  };

  const [result, setResult] = React.useState([]);

  const handleFilter = async () => {
    if (query === "") {
      toast.error("Please enter a search query");
      return;
    }
    try {
      await axios
        .post(process.env.REACT_APP_JINLAP_BASE_URL + `/service/search`, {
          search: query,
          order: sort,
        })
        .then((res) => {
          setResult(res.data.data);
        });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          width: "100%",
          justifyContent: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "space-between",
            xl: "space-between",
          },
          alignItems: {
            xs: "flex-start",
            sm: "flex-start",
            md: "flex-start",
            lg: "center",
            xl: "center",
          },
          marginBottom: "2rem",
        }}
      >
        <Box
          className="searchleft_section"
          sx={{
            textAlign: "justify",
            display: {
              xs: "none",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Typography
            variant="p"
            sx={{
              color: "var(--theme-color)",
              fontSize: {
                xs: "15px",
                sm: "15px",
                md: "16px",
                lg: "17px",
                xl: "17px",
              },
            }}
          >
            SHOWING RESULTS
          </Typography>
          <br />
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "15px",
                sm: "15px",
                md: "16px",
                lg: "17px",
                xl: "17px",
              },
            }}
          >
            {GoodDharmaDataLength} result of "
            <span
              style={{
                color: "var(--theme-color)",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "17px",
                },
              }}
            >
              {Name}
            </span>
            "
          </Typography>
        </Box>
        <Box
          className="searchright_section"
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            // backgroundColor: "green",
            width: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "60%",
              xl: "60%",
            },
            justifyContent: {
              xs: "flex-start",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-end",
              xl: "flex-end",
            },
            alignItems: {
              xs: "flex-start",
              sm: "center",
              md: "center",
              lg: "center",
              xl: "center",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "35%",
                md: "35%",
                lg: "40%",
                xl: "40%",
              },
              marginBottom: {
                xs: "10px",
                sm: "0px",
                md: "0px",
                lg: "0px",
                xl: "0px",
              },
            }}
          >
            <TextField
              id="outlined-basic"
              label="What are you looking for?"
              variant="outlined"
              size="small"
              value={query}
              onChange={handleQuery}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                height: "100%",
                " & .MuiOutlinedInput-root": {
                  "  &.Mui-focused fieldset": {
                    borderColor: "var(--theme-color)",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  border: "0px solid var(--white)",
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
                //border colour when focused
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--theme-color)",
                  },
                //floating label color when focused
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "var(--theme-color)",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "18px",
                    lg: "20px",
                    xl: "20px",
                  },
                },
              }}
            />
          </Box>

          {sorting && btnlabel ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                justifyContent: "flex-end",
                alignItems: {
                  xs: "flex-start",
                  sm: "center",
                  md: "center",
                  lg: "center",
                  xl: "center",
                },
                textAlign: "center",
                width: {
                  xs: "100%",
                  sm: "45%",
                  md: "45%",
                  lg: "45%",
                  xl: "45%",
                },
              }}
            >
              <FormControl
                sx={{
                  margin: {
                    xs: "10px 0px",
                    sm: "0px 10px",
                    md: "0px 10px",
                    lg: "0px 10px",
                    xl: "0px 10px",
                  },
                  minWidth: 170,
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                  },
                  marginBottom: {
                    xs: "20px",
                    sm: "0px",
                    md: "0px",
                    lg: "0px",
                    xl: "0px",
                  },
                }}
              >
                {/* <InputLabel id="sorting-label"> {sorting}</InputLabel> */}
                <TextField
                  select
                  labelId="sorting-label"
                  id="sorting-select"
                  required
                  value={sort}
                  label="Default Sorting"
                  onChange={handleChange}
                  size="small"
                  autoWidth
                  sx={{
                    " & .MuiOutlinedInput-root": {
                      "  &.Mui-focused fieldset": {
                        borderColor: "var(--theme-color)",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "0px solid var(--white)",
                      borderRadius: "0",
                      padding: "0",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #eee",
                      },
                    //border colour when focused
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid var(--theme-color)",
                      },
                    //floating label color when focused
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "var(--theme-color)",
                    },
                  }}
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </TextField>
              </FormControl>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    "&:hover": {
                      backgroundColor: "var(--theme-color)",
                    },
                  }}
                  onClick={handleFilter}
                >
                  {btnlabel}
                  <FilterAltIcon />
                </Button>
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box
          className="searchleft_section"
          sx={{
            textAlign: "justify",
            display: {
              xs: "block",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            marginTop: "20px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              color: "var(--theme-color)",
              fontSize: {
                xs: "15px",
                sm: "15px",
                md: "16px",
                lg: "17px",
                xl: "17px",
              },
            }}
          >
            SHOWING RESULTS
          </Typography>
          <br />
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "15px",
                sm: "15px",
                md: "16px",
                lg: "17px",
                xl: "17px",
              },
            }}
          >
            {GoodDharmaDataLength} result of "
            <span
              style={{
                color: "var(--theme-color)",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "16px",
                  lg: "17px",
                  xl: "17px",
                },
              }}
            >
              {Name}
            </span>
            "
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "2rem" }}>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
      </Box>
      <GoodDharmaTopContent searchData={result} />
    </>
  );
};
