import React from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { WishlistTopPathBar } from "../../components/WishlistComp/WishlistToppathBar/WishlistTopPathBar";
import { DharmaGoodsWishlist } from "../../components/WishlistComp/DharmaGoodsWishlist/DharmaGoodsWishlist";
import { GoodDharmaWishlist } from "../../components/WishlistComp/GoodDharmaWishlist/GoodDharmaWishlist";
import { MobDharmaGoodWishlist } from "../../components/WishlistComp/DharmaGoodsWishlist/MobDharmaGoodWishlist";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import Buddhaicon from "../../components/assets/buddhaicon.png";
import lotus from "../../components/assets/lotus.png";
import { WishlistRelatedGoodDharma } from "../../components/WishlistComp/WishlistRelatedGoodDharma/WishlistRelatedGoodDharma";
import { WishlistSimilarproducts } from "../../components/WishlistComp/WishlistSimilarproducts";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";

export const WishlistPage = () => {
  return (
    <Box>
      <WishlistTopPathBar />

      <Container>
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "var(--primary-font)",
              color: "var(--light-black)",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "40px 0px 40px 0px",
            }}
          >
            Wishlist
          </Typography>
        </Box>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <DharmaGoodsWishlist />
        </Box>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <MobDharmaGoodWishlist />
        </Box>

        <GoodDharmaWishlist />

        <Box>
          <HorizontalLines imgUrl={`${Buddhaicon}`} />
          <h5 style={{ color: "var(--theme-color)", marginBottom: "20px" }}>
            SIMILAR DHARMA GOODS
          </h5>
          <h2 style={{ color: "var(--light-black)", marginBottom: "20px" }}>
            CRAFTED BY HANDS, YOU MAY LIKE
          </h2>
          <WishlistSimilarproducts />
        </Box>
        <Box
          sx={{
            marginTop: "80px",
          }}
        >
          <HorizontalLines imgUrl={`${lotus}`} />
          <WishlistRelatedGoodDharma />
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <Subscribe />
        </Box>
      </Container>
    </Box>
  );
};
