import axios from "axios";

export const SupportProjectPage = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_JINLAP_BASE_URL + `/projects`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const SupportRunningProjectData = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_JINLAP_BASE_URL + `/past-projects`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
