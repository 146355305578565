import React from "react";
import { Box } from "@mui/system";
import { Button, Typography, Rating } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { ThankyouforReview } from "./ThankyouforReview";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const hor_line = {
  width: "100%",
  height: "2px",
  backgroundColor: "var(--light-grey)",
  marginTop: "20px",
  marginBottom: "20px",
};

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

export const PujaReviewForm = ({ handleClosereview, pujaTitle, pujaId }) => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);
  const [showThankyou, setShowThankyou] = React.useState(false);
  const initialValues = {
    rating: 0,
    title: "",
    review: "",
  };

  const validationSchema = Yup.object().shape({
    rating: Yup.number().required("Required"),
    title: Yup.string()
      .required("Required")
      .min(5, "Must be 10 characters or more")
      .max(50, "Must be 100 characters or less"),
    review: Yup.string()
      .required("Required")
      .min(10, "Must be 10 characters or more")
      .max(200, "Must be 100 characters or less"),
  });

  const submitHandler = async (values, { resetForm }) => {
    values.service_id = pujaId;

    try {
      await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/services/${pujaId}/review`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Review posted successfully");
      setShowThankyou(true);
    } catch (error) {
      toast.error(error.response.data.message);
    }

    resetForm();
  };
  return (
    <>
      {showThankyou ? (
        <ThankyouforReview handleClosereview={handleClosereview} />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                color: "var(--theme-color)",
                fontWeight: "500",
                fontFamily: "var(--primary-font)",
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "15px",
                  lg: "16px",
                  xl: "16px",
                },
              }}
            >
              Write review for “{pujaTitle}”
            </Typography>
            <Button
              sx={{
                // position: "absolute",
                // top: "0px",
                // right: "0px",
                color: "var(--black-color)",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "var(--theme-color)",
                },
              }}
              onClick={handleClosereview}
            >
              <CloseIcon />
            </Button>
          </Box>

          <div style={hor_line}></div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
          >
            {({ errors, touched }) => (
              <Form>
                <Box
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                    }}
                  >
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      sx={{
                        color: "var(--black-color)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      Rating
                    </InputLabel>

                    <Field
                      as={Rating}
                      type="number"
                      name="rating"
                      required
                      //   value={value}
                      //   onChange={(event, newValue) => {
                      //     setValue(newValue);
                      //   }}
                    />
                  </Box>

                  <ErrorMessage
                    name="rating"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>

                <Box
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                    }}
                  >
                    Title
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    autoComplete="off"
                    size="medium"
                    name="title"
                    id="input-with-icon-adornment"
                    className={
                      errors.title && touched.title ? "input-error" : null
                    }
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: "100%",

                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },

                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="title"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                      marginBottom: "10px",
                    }}
                  >
                    Your Review(Less than 200 words):
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    multiline
                    rows={4}
                    className={
                      errors.review && touched.review ? "input-error" : null
                    }
                    name="review"
                    id="input-with-icon-adornment"
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: "100%",

                      // padding: "1px",
                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="review"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      color: "var(--white)",
                      borderRadius: "5px",
                      border: "1px solid var(--theme-color)",
                      textTransform: "capitalize",
                      marginRight: "20px",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "11px",
                        lg: "12px",
                        xl: "12px",
                      },
                      padding: "10px 15px 10px 15px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--theme-color)",
                        border: "1px solid var(--theme-color)",
                      },
                    }}
                  >
                    Post Review
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: "var(--white)",
                      color: "var(--light-black)",
                      borderRadius: "5px",
                      border: "1px solid var(--light-black)",
                      textTransform: "capitalize",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "11px",
                        lg: "12px",
                        xl: "12px",
                      },
                      padding: "10px 15px 10px 15px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--theme-color)",
                        border: "1px solid var(--theme-color)",
                      },
                    }}
                    onClick={handleClosereview}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};
