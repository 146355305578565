import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const DataContent = () => {
  const { id } = useParams();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  const location = useLocation();

  // Function to generate shareable URLs for social media platforms
  const generateShareUrl = () => {
    // Replace these placeholders with the actual URL and content you want to share
    const shareUrl = `${window.location.origin}${location.pathname}`;
    const shareTitle = "Check out this page"; // Replace with your page title
    const shareDescription = "Description of the shared content"; // Replace with your description

    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareTitle
      )}&url=${encodeURIComponent(shareUrl)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${shareTitle}\n${shareUrl}`
      )}`,
      youtubeUrl: "https://www.youtube.com/user/yourchannel", // Replace with the user's YouTube channel URL
      tiktokUrl: "https://www.tiktok.com/youraccount",
    };
  };

  const socialMediaUrls = generateShareUrl();

  const { getById, getDonationId, getFundData } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.projects, id);
    return res.data;
  };
  const getFundDatas = async () => {
    const res = await getFundData(endpoint.projectFund, id);
    return res.data;
  };

  const { isLoading, data: pastprojectsDetails } = useQuery({
    queryKey: ["pastprojectsDetails"],
    queryFn: getData,
  });

  const { data: fundData } = useQuery({
    queryKey: ["fundData", id],
    queryFn: getFundDatas,
  });

  if (isLoading) return <div>Loading...</div>;

  const iconBoxStyle = {
    paddingTop: "10px",
    paddingBottom: "10px",

    //grey line on the right side of the icon
  };

  const truncatedDescription = pastprojectsDetails?.description?.slice(0, 1500);

  return (
    <Box
      sx={{
        marginTop: "40px",
      }}
    >
      {pastprojectsDetails && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            <Typography
              sx={{
                textAlign: "left",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "16px",
                  xl: "16px",
                },
                fontFamily: "var(--primary-font)",
                color: "var(--light-black)",
                paddingBottom: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              {showMore ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: pastprojectsDetails?.description,
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: truncatedDescription + "...",
                  }}
                />
              )}
              {pastprojectsDetails?.description?.length > 1500 && (
                <NavLink
                  style={{
                    color: "var(--theme-color)",
                    marginBottom: "30px",
                  }}
                  onClick={toggleShowMore}
                >
                  {showMore ? "Read less" : "Read more"}
                </NavLink>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "column",
                  xl: "column",
                },
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: {
                  xs: "0px",
                  sm: "50px",
                  md: "50px",
                  lg: "0px",
                  xl: "0px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CalendarMonthOutlinedIcon
                  fontSize="medium"
                  sx={{
                    color: "var(--theme-color)",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "left",
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontFamily: "var(--primary-font)",
                    color: "var(--light-black)",
                  }}
                >
                  Start Date: {pastprojectsDetails?.start_date}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "10px",
                  marginTop: {
                    xs: "10px",
                    sm: "0px",
                    md: "0px",
                    lg: "10px",
                    xl: "10px",
                  },
                }}
              >
                <CalendarMonthOutlinedIcon
                  fontSize="medium"
                  sx={{
                    color: "var(--theme-color)",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "left",
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontFamily: "var(--primary-font)",
                    color: "var(--light-black)",
                  }}
                >
                  End Date: {pastprojectsDetails?.end_date}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "column",
                  xl: "column",
                },
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: {
                  xs: "0px",
                  sm: "55px",
                  md: "55px",
                  lg: "0px",
                  xl: "0px",
                },
              }}
            >
              <Box className="task-progress">
                <Typography
                  variant="p"
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "13px",
                      md: "13px",
                      lg: "13px",
                      xl: "15px",
                    },
                    fontFamily: "var(--primary-font)",
                    color: "var( --light-black)",
                    margin: "10px 0px 10px 0px",

                    textAlign: "left",
                    float: "left",
                  }}
                >
                  <span style={{ color: "var(--theme-color" }}>
                    {fundData?.total_sum}
                    {""} {fundData?.currency} raised {""}
                  </span>
                  of {fundData?.currency}
                  {fundData?.goal_amount} goal
                </Typography>

                <progress
                  Box
                  className="progress progress1"
                  max={fundData?.goal_amount}
                  value={fundData?.Total_Sum}
                  style={{
                    width: "100%",
                  }}
                ></progress>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontFamily: "var(--primary-font)",
                    color: "var( --light-black)",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  {fundData?.count} Donation Made
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "18px",
                      },
                      color: "var(--black)",
                      fontFamily: "var( --primary-font)",
                      textAlign: "justify",
                      fontWeight: "500",
                    }}
                  >
                    Share this story
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    zIndex: "1",
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "0",
                      lg: "0",
                      xl: "0",
                    },
                  }}
                >
                  <ul
                    style={{
                      listStyle: "none",
                      margin: "0",
                      padding: "0",
                      display: "flex",
                    }}
                    className="socialmediaicons"
                  >
                    <li className="socialmediaiconlist">
                      <a
                        href={socialMediaUrls.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mediaiconstags"
                      >
                        <FaFacebookF
                          style={iconBoxStyle}
                          size="40px"
                          className="icon"
                        />
                      </a>
                    </li>
                    <li className="socialmediaiconlist">
                      <a
                        href={socialMediaUrls.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mediaiconstags"
                      >
                        <FaXTwitter
                          style={iconBoxStyle}
                          size="40px"
                          className="icon"
                        />
                      </a>
                    </li>
                    <li className="socialmediaiconlist">
                      <a
                        href={socialMediaUrls.whatsapp}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mediaiconstags"
                      >
                        <ImWhatsapp
                          style={iconBoxStyle}
                          size="40px"
                          className="icon"
                        />
                      </a>
                    </li>
                    {/* <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaYoutube style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaTiktok style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li> */}
                  </ul>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
