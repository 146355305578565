import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { DonationTopDetail } from "../../components/DonationPageComp/DonationTopDetail";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { ProjectDonationForm } from "../../components/DonationPageComp/ProjectDonationForm";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const ProjectDonation = () => {
  const { get } = useAPI();

  const getProjectDonationData = async () => {
    const response = await get(endpoint.projectdonation);
    return response.data;
  };

  const { isDataLoading, data: projectDonationData } = useQuery({
    queryKey: ["projectDonationData"],
    queryFn: getProjectDonationData,
  });

  if (isDataLoading) return <div>Loading...</div>;
  return (
    <Box>
      <Box>
        <TopSection
          image={projectDonationData?.top_image}
          title={projectDonationData?.top_title}
          description={projectDonationData?.top_description}
        />
      </Box>
      <Container>
        <DonationTopDetail projectDonationData={projectDonationData} />
        <ProjectDonationForm />
        <Box
          sx={{
            marginTop: "80px",
          }}
        >
          <Subscribe />
        </Box>
      </Container>
    </Box>
  );
};
