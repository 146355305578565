import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { StarRating } from "../../../Shared/StarRating/StarRating";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Modal from "@mui/material/Modal";
import { QuestionForm } from "../../../QuestionForm/QuestionForm";
import { ReviewForm } from "../../../ReviewForm/ReviewForm";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { ProdReviewTabs } from "../ReviewTabs/ProdReviewTabs";

export const ProdReviews = ({
  prodTitle,
  prodId,
  prodreviews,
  prodquestions,
  totalreviews,
  avgRating,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openreview, setOpenreview] = React.useState(false);
  const handleOpenreview = () => setOpenreview(true);
  const handleClosereview = () => setOpenreview(false);

  return (
    <Box
      sx={{
        marginTop: "40px",
      }}
    >
      <Box>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          },
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "var(--black-color)",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "16px",
                  lg: "18px",
                  xl: "24px",
                },
                fontWeight: "500",
                fontFamily: "var(--primary-font)",
              }}
            >
              {avgRating ? avgRating : 0}
            </Typography>
            <StarRating rating={avgRating} />
          </Box>
          <Box>
            <Typography
              variant="body1"
              sx={{
                color: "var(--theme-color)",
                fontWeight: "500",
                fontFamily: "var(--primary-font)",
              }}
            >
              Based on {totalreviews ? totalreviews : 0} reviews
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: {
              xs: "20px",
              sm: "0px",
              md: "0px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              textTransform: "inherit",
              borderRadius: "5px",
              border: "1px solid var(--theme-color)",
              marginRight: "20px",
              fontSize: {
                xs: "11px",
                sm: "13px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--theme-color)",
                border: "1px solid var(--theme-color)",
              },
            }}
            onClick={handleOpenreview}
          >
            <StarBorderOutlinedIcon
              sx={{
                marginRight: "5px",
              }}
            />
            Write a review
          </Button>
          <Modal
            open={openreview}
            onClose={handleClosereview}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: "var(--white)",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                width: {
                  xs: "90%",
                  sm: "60%",
                  md: "60%",
                  lg: "40%",
                  xl: "40%",
                },
              }}
            >
              <ReviewForm
                handleClosereview={handleClosereview}
                prodTitle={prodTitle}
                prodId={prodId}
              />
            </Box>
          </Modal>
          <Button
            variant="outlined"
            sx={{
              color: "var(--btn-color)",
              border: "1px solid var(--btn-color)",
              borderRadius: "5px",
              textTransform: "inherit",
              fontSize: {
                xs: "11px",
                sm: "13px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
              "&:hover": {
                backgroundColor: "transparent",
                color: "var(--theme-color)",
                border: "1px solid var(--theme-color)",
              },
            }}
            onClick={handleOpen}
          >
            <HelpOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
            Ask a question
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: "var(--white)",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                width: {
                  xs: "100%",
                  sm: "40%",
                  md: "40%",
                  lg: "40%",
                  xl: "40%",
                },
              }}
            >
              <QuestionForm
                handleClose={handleClose}
                prodTitle={prodTitle}
                prodId={prodId}
              />
            </Box>
          </Modal>
        </Box>
      </Box>
      <ProdReviewTabs prodreviews={prodreviews} prodquestions={prodquestions} />
    </Box>
  );
};
