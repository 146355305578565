import React from "react";
import { Container } from "@mui/material";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import { SupportArtistsContent } from "../../components/SupportArtistsComponents/SupportArtistsContent";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";

export const SupportArtistPage = () => {
  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.home_support_artists);
    return res.data;
  };

  const { isLoading, data: supportartistsData } = useQuery({
    queryKey: ["supportartists"],
    queryFn: getData,
  });

  if (isLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height="60vh" />
      </>
    );

  return (
    <Box>
      {" "}
      {supportartistsData && (
        <Box>
          <TopSection
            title2={supportartistsData?.title_first}
            subtitle1={supportartistsData?.title_third}
            image={supportartistsData?.image}
          />
        </Box>
      )}
      <Container>
        <SupportArtistsContent />
      </Container>
      <Container
        sx={{
          marginTop: {
            xs: "80px",
            sm: "80px",
            md: "90px",
            lg: "80px",
            xl: "80px",
          },
        }}
      >
        <Subscribe />
      </Container>
    </Box>
  );
};
