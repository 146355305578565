import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCurrency: "NRS",
  rate: 1,
};

const CurrencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
    getSelectedCurrencyRate: (state, action) => {
      state.rate = action.payload;
    },
  },
});

export const { setSelectedCurrency, getSelectedCurrencyRate } =
  CurrencySlice.actions;

export default CurrencySlice.reducer;
