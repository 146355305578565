import React from "react";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

export const Organizer = ({ organizerLogo, Link, address, name }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "10px",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "var(--light-black)",
            fontFamily: "var(--primary-font)",
            fontWeight: "600",
            marginTop: "20px",
            fontSize: {
              xs: "14px",
              sm: "15px",
              md: "16px",
              lg: "18px",
              xl: "18px",
            },
          }}
        >
          Organizer
        </Typography>
      </Box>
      <Box>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
            xl: "row",
          },
          marginTop: "20px",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              width: "4%",
            }}
          >
            <img
              src={organizerLogo}
              alt=""
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
              loading="lazy"
            />
          </Box>
          <Box
            sx={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "var(--black)",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "14px",
                  lg: "15px",
                  xl: "15px",
                },
                fontFamily: "var(--primary-font)",
                fontWeight: "600",
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "var(--btn-color)",
                fontSize: {
                  xs: "10px",
                  sm: "10px",
                  md: "12px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              {address}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: {
              xs: "10px",
              sm: "10px",
              md: "0px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Button
            variant="outlined"
            sx={{
              border: "1px solid var( --light-black)",
              color: "var(--light-black)",
              "&:hover": {
                backgroundColor: "var(--theme-color)",
                color: "var(--white)",
                border: "1px solid var(--theme-color)",
              },
            }}
            onClick={() => window.open(Link, "_blank")}
          >
            Contact
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
