import React from "react";
import { Typography, Container } from "@mui/material";
import { Box } from "@mui/system";

export const GoodDharmaTopbar = ({ title }) => {
  return (
    <Box
      sx={{
        backgroundColor: "var(--pathbar-color)",
        width: "100%",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "15px",
        }}
      >
        <Typography
          variant="p"
          sx={{
            color: "var(--light-black)",
            fontFamily: "var(--primary-font)",
            fontSize: {
              xs: "10px",
              sm: "11px",
              md: "12px",
              lg: "14px",
              xl: "14px",
            },
          }}
        >
          Home | Project | {""}
          <span
            style={{
              color: "var(--theme-color)",
              fontFamily: "var(--primary-font)",
            }}
          >
            {title}
          </span>
        </Typography>
      </Container>
    </Box>
  );
};
