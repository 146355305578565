import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import "./Profilepopup.css";
import { Cookies } from "react-cookie";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

export const Profilepopup = ({ handlClickClose }) => {
  const [activeLink, setActiveLink] = useState("");
  const cookies = new Cookies();

  const username = cookies.get("user");
  const email = cookies.get("email");
  const profile = cookies.get("profile");
  const join_date = cookies.get("join_date");
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookiess] = useCookies(["token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookiess.token) {
      setToken(cookiess.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookiess.token]);

  const [isloggingOut, setIsloggingOut] = useState(false);

  const handlelogout = async () => {
    setIsloggingOut(true);
    try {
      await axios.post(process.env.REACT_APP_JINLAP_BASE_URL + `/logout`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      cookies.remove("user");
      cookies.remove("email");
      cookies.remove("profile");
      cookies.remove("token");
      cookies.remove("join_date");
      handlClickClose();
      toast.success("Logout Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsloggingOut(false);
  };

  return (
    <Box
      sx={{
        width: { xs: "50%", sm: "50%", md: "50%", lg: "17%", xl: "17%" },
        backgroundColor: "var(--white)",
        position: "absolute",
        top: "50px",
        right: "0px",
        zIndex: "100",
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
      }}
    >
      <Box
        sx={{
          marginTop: "2px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {profile ? (
            <Box
              sx={{
                width: {
                  xs: "10vw",
                  sm: "6vw",
                  md: "6vw",
                  lg: "3vw",
                  xl: "3vw",
                },
                height: "6vh",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "./assets/reviewer/reviewer2.jpg"}
                alt="Profile"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  borderRadius: "50%",
                }}
                loading="lazy"
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: {
                  xs: "10vw",
                  sm: "6vw",
                  md: "6vw",
                  lg: "3vw",
                  xl: "3vw",
                },
                height: "6vh",
                backgroundColor: "#5D8AA8",
                borderRadius: "50%",
                color: "(--white)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  color: "var(--white)",
                  fontFamily: "var(--font-family)",
                  fontSize: {
                    xs: "15px",
                    sm: "17px",
                    md: "18px",
                    lg: "25px",
                    xl: "25px",
                  },
                  textTransform: "capitalize",
                }}
              >
                {username[0]}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "10px",
            }}
          >
            {" "}
            <Typography
              variant="p"
              sx={{
                color: "var(--black)",
                fontFamily: "var(--font-family)",
                fontSize: {
                  xs: "11px",
                  sm: "13px",
                  md: "13px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Welcome !
            </Typography>
            <Typography
              variant="p"
              sx={{
                color: "var(--black)",
                fontFamily: "var(--font-family)",
                fontSize: {
                  xs: "11px",
                  sm: "13px",
                  md: "13px",
                  lg: "15px",
                  xl: "15px",
                },
                textTransform: "capitalize",
              }}
            >
              {username}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "var(--font-family)",
              fontSize: {
                xs: "11px",
                sm: "13px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
              marginLeft: "10px",
            }}
          >
            {email}
          </Typography>
          <Typography
            variant="p"
            sx={{
              fontFamily: "var(--font-family)",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "12px",
                lg: "14px",
                xl: "14px",
                marginLeft: "10px",
              },
            }}
          >
            Joined on {join_date}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
            }}
          >
            <li>
              <NavLink
                to="/account"
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "row",
                  margin: "0",
                  padding: "5px",
                }}
                className={`nav-NavLink ${
                  activeLink === "account" ? "active" : ""
                }`}
                // isactive={() => setActiveLink("account")}
                onClick={() => {
                  handlClickClose();
                  setActiveLink("account");
                }}
              >
                {/* <Typography
                  variant="p"
                  sx={{
                    color: "var(--black)",
                    fontSize: {
                      xs: "11px",
                      sm: "13px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                > */}
                <AccountBoxRoundedIcon
                  fontSize="medium"
                  sx={{
                    marginRight: "10px",
                  }}
                />
                Accounts
                {/* </Typography> */}
              </NavLink>
            </li>
            <li>
              <NavLink
                style={{
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "row",
                  margin: "0",
                  padding: "5px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--light-black)",
                    fontSize: {
                      xs: "11px",
                      sm: "13px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                  onClick={handlelogout}
                >
                  <LogoutRoundedIcon
                    fontSize="medium"
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                  {isloggingOut ? "Logging Out..." : "Logout"}
                </Typography>
              </NavLink>
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};
