import axios from "axios";
import { Cookies } from "react-cookie";

const cookies = new Cookies();
const userToken = cookies.get("token");

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_JINLAP_BASE_URL,
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${userToken}`,
    "Content-Type": "application/json",
    Accept: "application/json",
    // RetryAfter: 5000,
  },
});

export default AxiosInstance;
