import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

export const HowitwillDone = ({ howitwilldone }) => {
  const location = useLocation();
  const iconBoxStyle = {
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  // Function to generate shareable URLs for social media platforms
  const generateShareUrl = () => {
    // Replace these placeholders with the actual URL and content you want to share
    const shareUrl = `${window.location.origin}${location.pathname}`;

    const shareTitle = "Check out this page"; // Replace with your page title
    const shareDescription = "Description of the shared content"; // Replace with your description

    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareTitle
      )}&url=${encodeURIComponent(shareUrl)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${shareTitle}\n${shareUrl}`
      )}`,
      youtubeUrl: "https://www.youtube.com/user/yourchannel", // Replace with the user's YouTube channel URL
      tiktokUrl: "https://www.tiktok.com/youraccount",
    };
  };

  const socialMediaUrls = generateShareUrl();
  return (
    <Box>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "20px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            marginTop: "10px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "18px",
              },
              color: "var(--btn-color)",
              fontFamily: "var( --primary-font)",
              textAlign: "justify",
              fontWeight: "500",
            }}
          >
            Share
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            alignItems: "flex-start",
            zIndex: "1",
          }}
        >
          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
              display: "flex",
            }}
            className="socialmediaicons"
          >
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaFacebookF
                  style={iconBoxStyle}
                  size="40px"
                  className="icon"
                />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaXTwitter style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.whatsapp}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <ImWhatsapp style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            {/* <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaYoutube style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaTiktok style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li> */}
          </ul>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: "14px",
              sm: "14px",
              md: "16px",
              lg: "18px",
              xl: "20px",
            },
            color: "var(--theme-color)",
            fontFamily: "var( --primary-font)",
            textAlign: "justify",
            fontWeight: "500",
          }}
        >
          How it will be done?
        </Typography>
        <Box
          sx={{
            marginTop: "10px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "13px",
                sm: "13px",
                md: "15px",
                lg: "17px",
                xl: "17px",
              },
              color: "var(--light-black)",
              fontFamily: "var(--primary-font)",
              textAlign: "left",
            }}
            dangerouslySetInnerHTML={{ __html: howitwilldone }}
          >
            {/* {howitwilldone} */}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
