import React from "react";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { StrongerSection } from "../../components/AboutUsComponents/StrongerSection/StrongerSection";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { BottomStrongerSection } from "../../components/AboutUsComponents/StrongerSection/BottomStrongerSection";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import lotus from "../../components/assets/lotus.png";
import { Notes } from "../../components/AboutUsComponents/NotesSection/Notes";
import { Story } from "../../components/AboutUsComponents/Story/Story";
import { FocusedHappiness } from "../../components/AboutUsComponents/FocusedHappiness/FocusedHappiness";
import { GoodDharmaStry } from "../../components/AboutUsComponents/GoodDharmaStry/GoodDharmaStry";
import { Rituals } from "../../components/AboutUsComponents/Rituals/Rituals";
import { SocialInitiaties } from "../../components/AboutUsComponents/SocialInitiaties/SocialInitiaties";
import "./AboutPage.css";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const AboutPage = () => {
  const { get } = useAPI();

  const getAboutData = async () => {
    const response = await get(endpoint.abouts);
    return response.data;
  };

  const { isLoading, data: aboutData } = useQuery({
    queryKey: ["AboutData"],
    queryFn: getAboutData,
  });

  if (isLoading) return <div>Loading...</div>;

  console.log("aboutData", aboutData);

  return (
    <Box>
      {aboutData && (
        <div>
          <TopSection
            image={aboutData?.cover_image}
            title2={"About Us"}
            subtitle1={aboutData?.cover_title}
            subtitle2={aboutData?.cover_title_two}
            id={aboutData?.id}
          />
          <Container>
            <StrongerSection
              title={aboutData?.title}
              description={aboutData?.description}
              image={aboutData?.image}
              id={aboutData?.id}
            />
          </Container>
          <Box className="notesmain-cont">
            <BottomStrongerSection images={aboutData?.images} />
            <Container>
              <HorizontalLines imgUrl={`${lotus}`} />
              <Notes />
            </Container>
          </Box>

          <Story
            left_image={aboutData?.left_image}
            left_title={aboutData?.left_title}
            left_description={aboutData?.left_description}
            center_image={aboutData?.center_image}
            right_image={aboutData?.right_image}
            right_title={aboutData?.right_title}
            right_description={aboutData?.right_description}
            background_image={aboutData?.story_behind_background_image}
            watermark={aboutData?.add_logo_as_watermark}
            story_main_title_dharma_goods={
              aboutData?.story_main_title_dharma_goods
            }
            story_sub_title_dharma_goods={
              aboutData?.story_sub_title_dharma_goods
            }
          />
          <Container>
            <FocusedHappiness
              happiness_title_one={aboutData?.happiness_title_one}
              happiness_title_one_description={
                aboutData?.happiness_title_one_description
              }
              happiness_title_two={aboutData?.happiness_title_two}
              happiness_image_one={aboutData?.happiness_image_one}
              happiness_image_two={aboutData?.happiness_image_two}
              happiness_image_three={aboutData?.happiness_image_three}
              mission_image={aboutData?.mission_image}
              mission_description={aboutData?.mission_description}
              id={aboutData?.id}
            />
          </Container>
          <GoodDharmaStry
            story_left_image={aboutData?.story_left_image}
            story_left_title={aboutData?.story_left_title}
            story_left_description={aboutData?.story_left_description}
            story_center_image={aboutData?.story_center_image}
            story_right_image={aboutData?.story_right_image}
            story_right_title={aboutData?.story_right_title}
            story_right_description={aboutData?.story_right_description}
            watermark={aboutData?.add_logo_as_watermark}
            story_main_title_good_dharma={
              aboutData?.story_main_title_good_dharma
            }
            story_sub_title_good_dharma={aboutData?.story_sub_title_good_dharma}
          />
          <Container>
            <Rituals
              ritual_title_one={aboutData?.ritual_title_one}
              ritual_title_one_description={
                aboutData?.ritual_title_one_description
              }
              ritual_title_two={aboutData?.ritual_title_two}
              ritual_image_one={aboutData?.ritual_image_one}
              ritual_image_two={aboutData?.ritual_image_two}
              ritual_image_three={aboutData?.ritual_image_three}
              id={aboutData?.id}
            />
          </Container>
          <SocialInitiaties
            social_title_two_image={aboutData?.social_title_two_image}
            social_title_two_image_fund={aboutData?.social_title_two_image_fund}
            social_title_two_image_description={
              aboutData?.social_title_two_image_description
            }
            social_title_two_image_two={aboutData?.social_title_two_image_two}
            social_title_two_image_two_fund={
              aboutData?.social_title_two_image_two_fund
            }
            social_title_two_image_two_description={
              aboutData?.social_title_two_image_two_description
            }
            social_title={aboutData?.social_title}
            social_description={aboutData?.social_description}
            social_image={aboutData?.social_image}
            social_image_one={aboutData?.social_image_one}
            social_image_two={aboutData?.social_image_two}
            social_image_three={aboutData?.social_image_three}
            social_image_four={aboutData?.social_image_four}
            social_image_five={aboutData?.social_image_five}
            social_image_six={aboutData?.social_image_six}
            background_image={aboutData?.social_inititive_bg_image}
          />
          <Container>
            <Subscribe />
          </Container>
        </div>
      )}
    </Box>
  );
};
