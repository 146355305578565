import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import DoneIcon from "@mui/icons-material/Done";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "var(--theme-color)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "var(--theme-color)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "var(--theme-color)",
  }),
  ...(ownerState.completed && {
    backgroundColor: "var(--theme-color)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <DoneIcon />,
    2: <DoneIcon />,
    3: <DoneIcon />,
    4: <DoneIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {active || completed ? icons[String(props.icon)] : null}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Payment", "Processing", "Shipping", "Delivered"];

export default function OrderTracker({ orderId, orderDetails }) {
  const [activestep, setActiveStep] = React.useState(0);

  useEffect(() => {
    if (orderDetails?.status === "approved") {
      setActiveStep(1);
    }
    if (orderDetails?.status === "shipping") {
      setActiveStep(2);
    }
    if (orderDetails?.status === "delivered") {
      setActiveStep(3);
    }
  }, [orderDetails?.status]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stack
        sx={{
          width: "100%",
          //   marginTop: "20px",
        }}
      >
        <Stepper
          alternativeLabel
          activeStep={activestep} // Change this value to indicate active step
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon} icon={index + 1}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Box
        sx={{
          textAlign: "left",
          marginTop: "50px",
          marginLeft: {
            xs: "0px",
            sm: "20px",
            md: "20px",
            lg: "20px",
            xl: "20px",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "var(--black)",
            fontWeight: "500",
            fontSize: {
              xs: "11px",
              sm: "12px",
              md: "14px",
              lg: "16px",
              xl: "15px",
            },
            backgroundColor: "#F5F5F5",
            padding: "20px",
          }}
        >
          {activestep === 0 && "Your payment has been made via stripe."}
          {activestep === 1 &&
            "Your order has been prepared for processing. After processing we will ship your product to delivering partner"}
          {activestep === 2 &&
            "Your order have been shipped to your shipping address with our Shipping Partner"}
          {activestep === 3 &&
            "Your package has been delivered. Thank you for shopping at Jinlap"}
        </Typography>
      </Box>
    </Box>
  );
}
