import React from "react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";

export const ContributionBottom = ({
  social_image_one,
  social_image_two,
  social_image_three,
  social_image_four,
  social_image_five,
  social_image_six,
}) => {
  return (
    <Box
      sx={{
        paddingBottom: "30px",
      }}
    >
      <Container>
        <Grid
          xs={12}
          display="flex"
          flexDirection={{
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
            xl: "row",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "90%",
                  height: "25vh",
                  margin: "10px",
                }}
              >
                <img
                  src={social_image_one}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading="lazy"
                />
              </Box>
              <Box
                sx={{
                  width: "90%",
                  height: "25vh",
                  margin: "10px",
                }}
              >
                <img
                  src={social_image_two}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading="lazy"
                />
              </Box>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: {
                  xs: "90%",
                  sm: "90%",
                  md: "60%",
                  lg: "60%",
                  xl: "60%",
                },
                height: "50vh",
                margin: "10px",
              }}
            >
              <img
                src={social_image_three}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                loading="lazy"
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "50vh",
                margin: "10px",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <img
                src={social_image_four}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                loading="lazy"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "90%",
                  height: "25vh",
                  margin: "10px",
                }}
              >
                <img
                  src={social_image_four}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading="lazy"
                />
              </Box>

              <Box
                sx={{
                  width: "90%",
                  height: "25vh",
                  margin: "10px",
                }}
              >
                <img
                  src={social_image_five}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading="lazy"
                />
              </Box>
            </Box>
            <Box
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "40%",
                height: "45vh",
                margin: "10px",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <img
                src={social_image_six}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
                loading="lazy"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
