import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Grid, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import discountIcon from "../../assets/discount.png";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Cookies } from "react-cookie";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const CartBottomComp = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [appliedDiscount, setAppliedDiscount] = useState("");
  const [emptycart, setEmptycart] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm", "xs"));
  const btnlabel = isSmallScreen ? "Discount" : "Apply Discount";

  const [promocode, setPromocode] = useState("");

  const { getCartById } = useAPI();

  const getData = async () => {
    const response = await getCartById(endpoint.getgoodsbyId);
    return response.data;
  };

  const getDharmaData = async () => {
    const response = await getCartById(endpoint.all_good_dharma);
    return response.data;
  };

  const { isLoading: isLoadingProducts, data: dharmagoodcart } = useQuery({
    queryKey: ["products"],
    queryFn: getData,
  });

  const { isLoading: isLoadingServices, data: gooddharmacart } = useQuery({
    queryKey: ["services"],
    queryFn: getDharmaData,
  });

  const cookies = new Cookies();
  const userToken = cookies.get("token");

  useEffect(() => {
    try {
      const fetchPromocodeData = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_JINLAP_BASE_URL + `/coupon`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          setPromocode(response.data.payload);
        } catch (error) {
          if (error.response.status === 401) {
            toast.error("Unauthorized access");
          } else {
            console.error("Error fetching data:", error);
          }
        }
      };

      fetchPromocodeData();
    } catch (error) {
      console.error("error", error);
    }
  }, [userToken]);

  //sum all the total_price of items of dharmagoodcart
  const Dharmagoodstotal = dharmagoodcart?.reduce(
    (acc, item) => acc + item.qty * item.price,
    0
  );

  const Gooddharmatotal = gooddharmacart?.reduce(
    (acc, item) => acc + parseInt(item.price, 10),
    0
  );

  const totalCost = Dharmagoodstotal + Gooddharmatotal;

  const handleApplyDiscount = () => {
    setIsProcessing(true);
    const appliedPromoCode = promocode.code === code;

    if (appliedPromoCode) {
      setAppliedDiscount(promocode.amount);
      toast.success("Promo code applied successfully");
    } else {
      setAppliedDiscount(0);
      toast.error("Promo Code is Invalid");
    }
    setIsProcessing(false);
  };

  const grandTotalcalcu = totalCost / rate - appliedDiscount / rate;

  const grandTotal = grandTotalcalcu.toFixed(2);

  useEffect(() => {
    if (grandTotal >= 0) {
      setEmptycart(false);
    }
  }, [grandTotal]);

  const handleCheckout = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/checkout`,
        {
          total_amount: grandTotal,
          sub_total: (totalCost / rate).toFixed(2),
          delivery_charge: 0,
          discount_amount: appliedDiscount ? appliedDiscount / rate : 0,
          tax: 0,
          currency: selectedCurrency,
          rate: rate,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoading(false);
      toast.success("Proceeding to Checkout");
      navigate("/checkout");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleCartEmpty = async () => {
    toast.success("Your Cart is empty, Please Fill the Cart");
    navigate("/");
  };

  if (isLoadingProducts || isLoadingServices) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={8}
          xl={8}
          // sx={{
          //   display: "flex",
          //   justifyContent: "space-between",
          // }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "none",
                xl: "none",
              },
              alignItems: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
                lg: "",
                xl: "",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "var(--very-lightthemecolor)",
                  padding: "20px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  Dharma Goods Subtotal: {""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                    color: "var(--theme-color)",
                    marginLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {selectedCurrency}{" "}
                  {(
                    dharmagoodcart?.reduce(
                      (acc, item) => acc + item.qty * item.price,
                      0
                    ) / rate
                  ).toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "var(--very-lightthemecolor)",
                  padding: "20px",
                  marginTop: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "0px",
                    xl: "0px",
                  },
                  marginLeft: {
                    xs: "0px",
                    sm: "0px",
                    md: "0px",
                    lg: "30px",
                    xl: "30px",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  Good Dharma Subtotal: {""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                    color: "var(--theme-color)",
                    marginLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {selectedCurrency} {(Gooddharmatotal / rate).toFixed(2)}
                </Typography>
              </Box>
            </Box>
            {grandTotal > promocode?.amount / rate ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginTop: "20px",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "15px",
                      lg: "18px",
                      xl: "18px",
                    },
                    marginBottom: "8px",
                    textAlign: "left",
                  }}
                >
                  If you have a promotion code, Please enter here to claim
                  discounts
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "row",
                      sm: "row",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                    marginTop: "10px",
                    width: "100%",
                  }}
                >
                  <TextField
                    id="search"
                    type="search"
                    label="Enter Promotion Code"
                    size="small"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "60%",
                        md: "60%",
                        lg: "60%",
                        xl: "60%",
                      },

                      backgroundColor: "#fff",
                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--theme-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "1px solid #E4E4E4",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #eee",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid var(--theme-color)",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--theme-color)",
                      },
                    }}
                    inputProps={{
                      style: {
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "18px",
                          lg: "20px",
                          xl: "20px",
                        },
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      height: "auto",
                      textTransform: "capitalize",
                      fontSize: {
                        xs: "9px",
                        sm: "9px",
                        md: "10px",
                        lg: "12px",
                        xl: "12px",
                      },
                      padding: {
                        xs: "12px 15px 12px 15px ",
                        sm: "10px",
                        md: "11px",
                        lg: "10px",
                        xl: "10px",
                      },
                      alignSelf: { xs: "flex-start", sm: "flex-start" },
                      "&:hover": {
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                        transform: "scale(1.1)",
                      },
                      fontFamily: "var(--primary-font)",
                    }}
                    onClick={handleApplyDiscount}
                    disabled={isProcessing}
                  >
                    <img
                      src={process.env.PUBLIC_URL + `${discountIcon}`}
                      alt="..."
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "10px",
                      }}
                      loading="lazy"
                    />
                    {isProcessing ? (
                      <span style={{ color: "white" }}>Processing...</span>
                    ) : (
                      btnlabel
                    )}
                  </Button>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "var(--primary-font)",
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                Total Price: {""}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "var(--primary-font)",
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  color: "var(--theme-color)",
                  marginLeft: "10px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                {selectedCurrency} {(totalCost / rate).toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  Discount: {""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    color: "var(--theme-color)",
                    marginLeft: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  - {""} {selectedCurrency}{" "}
                  {(appliedDiscount / rate).toFixed(2)}
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  Tax: {""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    color: "var(--theme-color)",
                    marginLeft: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  Including tax
                </Typography>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  Grand Total: {""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    color: "var(--theme-color)",
                    marginLeft: "10px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    backgroundColor: "var(--very-lightthemecolor)",
                  }}
                >
                  {selectedCurrency} {grandTotal}
                </Typography>
              </Box>
            </Box>
            {/* <Stripe total={grandTotal} /> */}
            <Box
              sx={{
                marginTop: "30px",
                width: "100%",
              }}
            >
              {emptycart === false ? (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--theme-color)",
                    width: "100%",
                    borderColor: "var(--theme-color)",
                    color: "var(--white)",
                    "&:hover": {
                      backgroundColor: "var(--white)",
                      color: "var(--theme-color)",
                      borderColor: "var(--theme-color)",
                    },
                    fontFamily: "var(--primary-font)",
                  }}
                  onClick={handleCheckout}
                  disabled={isLoading}
                >
                  <ShoppingBagOutlinedIcon
                    fontSize="medium"
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                  {isLoading ? (
                    <span style={{ color: "white" }}>Processing...</span>
                  ) : (
                    "Proceed to checkout"
                  )}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    backgroundColor: "var(--theme-color)",
                    width: "100%",
                    borderColor: "var(--theme-color)",
                    color: "var(--white)",
                    "&:hover": {
                      backgroundColor: "var(--white)",
                      color: "var(--theme-color)",
                      borderColor: "var(--theme-color)",
                    },
                    fontFamily: "var(--primary-font)",
                  }}
                  onClick={handleCartEmpty}
                  // disabled={grandTotal <= 0}
                >
                  <ShoppingBagOutlinedIcon
                    fontSize="medium"
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                  Cart is Empty
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
