import React from "react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { TopPathSupportArtistsToppathBarBar } from "../../components/SupportArtistsDetailComponent/SupportArtistsToppathBar/SupportArtistsToppathBar";
import { SupportArtistsContent } from "../../components/SupportArtistsDetailComponent/SupportArtistsContentSection/SupportArtistsContent";
import { SupportArtistsDonationSection } from "../../components/SupportArtistsDetailComponent/SupportArtistsDonationSection";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";

export const SupportArtistsDetailPage = () => {
  return (
    <Box>
      <TopPathSupportArtistsToppathBarBar />
      <Container>
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "column",
              xl: "row",
            },
            justifyContent: "space-evenly",
            marginTop: "50px",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <SupportArtistsContent />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <SupportArtistsDonationSection buttonlabel={"Donate Now"} />
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          marginTop: {
            xs: "50px",
            sm: "50px",
            md: "90px",
            lg: "80px",
            xl: "80px",
          },
        }}
      >
        <Subscribe />
      </Container>
    </Box>
  );
};
