import AxiosInstance from "../api";
// import AuthAxiosInstance from "../api/Authindex";
import { Cookies } from "react-cookie";
import axios from "axios";
import { useState, useEffect } from "react";

const cookies = new Cookies();
// const id = cookies.get("userId");

const useAPI = () => {
  const [id, setId] = useState(cookies.get("userId"));
  const [token, setToken] = useState(cookies.get("token"));

  useEffect(() => {
    setId(cookies.get("userId"));
    setToken(cookies.get("token"));
  }, [id, token]);

  const AuthAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_JINLAP_BASE_URL,
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      // RetryAfter: 5000,
    },
  });

  const get = async (endPoint) => {
    try {
      const response = await AxiosInstance.get(endPoint);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getLanguage = async (endPoint, lang) => {
    try {
      const response = await AxiosInstance.get(`${endPoint}?language=${lang}`);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getAuthData = async (endPoint) => {
    try {
      const response = await AuthAxiosInstance.get(endPoint);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getAuthDataById = async (endPoint, id) => {
    try {
      const response = await AuthAxiosInstance.get(`${endPoint}/${id}`);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getOrderData = async (endPoint) => {
    try {
      const response = await AuthAxiosInstance.get(endPoint);
      return {
        data: response.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getCategoriesdataById = async (endPoint, id) => {
    try {
      const response = await AxiosInstance.get(`/categories/${id}/${endPoint}`);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getById = async (endPoint, id) => {
    try {
      const response = await AxiosInstance.get(`${endPoint}/${id}`);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getDonationId = async (endPoint, id) => {
    try {
      const response = await AxiosInstance.get(`${endPoint}/${id}`);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getFundData = async (endPoint, id) => {
    try {
      const response = await AxiosInstance.get(`${endPoint}/${id}`);
      return {
        data: response.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getWishlistById = async (endPoint) => {
    try {
      const response = await AuthAxiosInstance.get(
        `${endPoint}/${id}/wishlist`
      );

      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getCartById = async (endPoint) => {
    if (!id) {
      return {
        data: null,
        error: "ID is not present",
        success: false,
        message: "Fetch data failed: ID is missing",
      };
    }
    try {
      const response = await AuthAxiosInstance.get(`${endPoint}/${id}/cart`);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const updatedCart = async (UpdatedData) => {
    try {
      const { data: responseData } = await AuthAxiosInstance.put(
        `products/${UpdatedData.id}/cart`,
        UpdatedData.data
      );
      return responseData.data;
    } catch (error) {
      throw error;
    }
  };

  const updatedDharmaCart = async (UpdatedData) => {
    try {
      const { data: responseData } = await AuthAxiosInstance.put(
        `services/${UpdatedData.id}/cart`,
        UpdatedData.data
      );
      return responseData.data;
    } catch (error) {
      throw error;
    }
  };
  const searchQuery = async (requestData) => {
    try {
      const response = await AxiosInstance.post(
        ` ${requestData.endpoint}/search`,
        requestData.data
      );
      return {
        data: response.data.data,
        status: true,
        message: "Data has been saved",
      };
    } catch (error) {
      return {
        status: true,
        message: "Data has been saved",
      };
    }
  };

  return {
    get,
    getLanguage,
    getCategoriesdataById,
    getById,
    searchQuery,
    getWishlistById,
    getCartById,
    updatedCart,
    updatedDharmaCart,
    getAuthData,
    getDonationId,
    getOrderData,
    getFundData,
    getAuthDataById,
  };
};

export default useAPI;
