import React from "react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { TopPathBar } from "../../components/RunningProjectDetail/ToppathBar/TopPathBar";
import { ContentSection } from "../../components/RunningProjectDetail/ContentSection/ContentSection";
import { DonationSection } from "../../components/RunningProjectDetail/DonationSection/DonationSection";
import { SimilarProjects } from "../../components/RunningProjectDetail/DonationSection/SimilarProjects";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import Vector from "../../../src/components/assets/Vector.png";

export const RunningProjectDetail = () => {
  return (
    <Box>
      <TopPathBar />
      <Container>
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "column",
              xl: "row",
            },
            justifyContent: "space-evenly",
            marginTop: "50px",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <ContentSection />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
            <DonationSection />
          </Grid>
        </Grid>
        <Box
          sx={{
            marginTop: {
              xs: "80px",
              sm: "80px",
              md: "90px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <HorizontalLines imgUrl={`${Vector}`} />
          <SimilarProjects />
        </Box>
        <Subscribe />
      </Container>
    </Box>
  );
};
