import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { PastInitiativesTopPathBar } from "../../components/PastInitiativesDetailComponents/PastInitativesToppathBar/PastInitiativesTopPathBar";
import { PastInitiativesContentSection } from "../../components/PastInitiativesDetailComponents/TopContent/PastInitiativesContentSection";
import { DataContent } from "../../components/PastInitiativesDetailComponents/TopContent/DataContent";
import { Donation } from "../../components/PastInitiativesDetailComponents/Donation/Donation";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import Vector from "../../../src/components/assets/Vector.png";
import { PastSimilarProjects } from "../../components/PastInitiativesDetailComponents/PastSimilarProjects/PastSimilarProjects";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const PastInitiativesDetailPage = () => {
  const { id } = useParams();

  const { getById } = useAPI();

  const getPastProjectsData = async () => {
    const res = await getById(endpoint.projects, id);
    return res.data;
  };

  const { isLoading, data: pastprojectDetails } = useQuery({
    queryKey: ["pastprojectDetails"],
    queryFn: getPastProjectsData,
  });

  if (isLoading) return <div>Loading...</div>;
  return (
    <Box>
      <PastInitiativesTopPathBar />
      <Container>
        <PastInitiativesContentSection
          pastprojectDetails={pastprojectDetails}
        />
        <DataContent />
        <Donation pastprojectDetails={pastprojectDetails} />
        <HorizontalLines imgUrl={`${Vector}`} />
        <PastSimilarProjects />
        <Subscribe />
      </Container>
    </Box>
  );
};
