import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ContentDataSection } from "./ContentDataSection";
import { useParams } from "react-router-dom";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const ContentSection = () => {
  // const [siteSettings, setSiteSettings] = React.useState({});

  const { id } = useParams();

  const { getById, get } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.projects, id);
    return res.data;
  };

  const { isLoading: runningProjLoading, data: runnningprojectDetails } =
    useQuery({
      queryKey: ["runningprojectsDetails"],
      queryFn: getData,
    });

  const getsitesettingsData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isLoading: siteLoading, data: siteSettings } = useQuery({
    queryKey: ["siteSettings"],
    queryFn: getsitesettingsData,
  });

  if (runningProjLoading || siteLoading) return <>Loading...</>;

  return (
    <Box
      sx={{
        marginBottom: {
          xs: "30px",
          sm: "30px",
          md: "40px",
          lg: "100px",
          xl: "100px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "30%",
              sm: "10%",
              md: "10%",
              lg: "8%",
              xl: "8%",
            },
            height: "auto",
          }}
        >
          <img
            src={siteSettings?.secondary_logo}
            alt="projectlogo "
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              objectPosition: "center",
            }}
            loading="lazy"
          />
        </Box>
        <Box
          sx={{
            textAlign: "justify",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "19px",
                sm: "20px",
                md: "22px",
                lg: "24px",
                xl: "24px",
              },

              color: "var(--black)",
              fontFamily: "var( --primary-font)",
            }}
          >
            {runnningprojectDetails?.title}
          </Typography>
        </Box>
      </Box>
      <Box>
        <ContentDataSection />
      </Box>
    </Box>
  );
};
