import React from "react";
import { Container } from "@mui/material";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { PastInitativesBottom } from "../../components/PastInitativesComponents/PastInitativesBottom";
import { PastIniativesTopData } from "../../Data/PastInitiativesDetail/PastInitatives";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import Vector from "../../../src/components/assets/Vector.png";

export const PastIniatives = () => {
  return (
    <Box>
      {" "}
      {PastIniativesTopData.map((item) => {
        return (
          <Box key={item.id}>
            <TopSection
              image={item.imgUrl}
              title2={item.title}
              subtitle1={item.subtitle1}
              subtitle2={item.subtitle2}
              id={item.id}
            />
            <Container>
              <HorizontalLines imgUrl={`${Vector}`} />
              <PastInitativesBottom />
              <Subscribe />
            </Container>
          </Box>
        );
      })}
    </Box>
  );
};
