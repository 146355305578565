export const endpoint = {
  navbar: `navbars`,
  home_top_slider: `sliders`,
  dharmas: `categories`,
  featured_gooddharma: `featured-services`,
  featured_dharmagood: `featured-products`,
  all_dharma_goods: `categories-products`,
  getgoodsbyId: `products`,
  all_good_dharma: `services`,
  home_support_artists: `support-artists`,
  home_support_projects: `support-projects`,
  testimonials: `testimonials`,
  sitesettings: `site-settings`,
  subcategories: `subcategories`,
  artists: `artists`,
  projects: `projects`,
  past_projects: `past-projects`,
  abouts: `abouts`,
  notes: `notes`,
  termsconditions: `terms-conditions`,
  privacypolicy: `privacy-policy`,
  home_testimonials: `home-testimonials`,
  project_testimonials: `project-testimonials`,
  good_dharma_testimonials: `good-dharma-testimonials`,
  order_details: `order-details`,
  support_projects_donations: `support-projects-donations`,
  support_artist_donations: `support-artist-donations`,
  artistFund: `artistFund`,
  projectFund: `projectFund`,
  artistdonation: "artist-donation-page",
  projectdonation: "project-donation-page",
  ordertotal: `orders-total`,
  recent_order_details: `user-orders`,
  neworders: `new-orders`,
  orderhistory: `order-history`,
  pastprjdonation: `past-project-donation-listing`,
  orderdetails: `order-details`,
  returncancellation:`return-cancellations`,
  footercontents: `footer-contents`
};
