import React from "react";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { GoodDharmaTopbar } from "../../components/GoodDharmaDetails/GoodDharmaTopbar";
import { useParams } from "react-router-dom";
import { GoodDharmaContentImageSide } from "../../components/GoodDharmaDetails/GoodDharmaContentImageSide/GoodDharmaContentImageSide";
import { GoodDharmaContentDetailsSide } from "../../components/GoodDharmaDetails/GoodDharmaContentDetailsSide/GoodDharmaContentDetailsSide";
import { HowitwillDone } from "../../components/GoodDharmaDetails/HowitwillDone";
import { useMediaQuery, useTheme } from "@mui/material";
import { RelatedGoodDharma } from "../../components/GoodDharmaDetails/RelatedGoodDharma/RelatedGoodDharma";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import lotus from "../../components/assets/lotus.png";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { PujaReviews } from "../../components/GoodDharmaDetails/PujaReviews/Reviews/PujaReviews";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const GoodDharmaDetailsPage = () => {
  const { id } = useParams();

  const { getById } = useAPI();

  const theme = useTheme();

  // Use the useMediaQuery hook to get the screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm", "xs"));
  const spacing = isSmallScreen ? 0 : 4;

  const getData = async () => {
    const res = await getById(endpoint.all_good_dharma, id);
    return res.data;
  };

  const { isLoading, data: gooddharmacontent } = useQuery({
    queryKey: ["Gooddharmacontent", id],
    queryFn: getData,
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box>
      {gooddharmacontent && (
        <>
          <GoodDharmaTopbar title={gooddharmacontent?.name} />

          <Container>
            <Grid
              container
              xs={12}
              spacing={spacing}
              sx={{
                marginTop: "50px",
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                  xl: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box>
                  <GoodDharmaContentImageSide />
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                        xl: "block",
                      },
                    }}
                  >
                    <HowitwillDone
                      howitwilldone={gooddharmacontent?.long_description}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box>
                  <GoodDharmaContentDetailsSide />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "flex",
                      md: "flex",
                      lg: "none",
                      xl: "none",
                    },
                  }}
                >
                  <HowitwillDone
                    howitwilldone={gooddharmacontent?.long_description}
                  />
                </Box>
              </Grid>
            </Grid>
            <PujaReviews
              pujaTitle={gooddharmacontent?.name}
              pujaId={gooddharmacontent?.id}
              pujareviews={gooddharmacontent?.reviews}
              pujaquestions={gooddharmacontent?.questions}
              avgRating={gooddharmacontent?.average_rating}
              totalreviews={gooddharmacontent?.total_reviews}
            />
            <HorizontalLines imgUrl={`${lotus}`} />
            <RelatedGoodDharma />
            <Subscribe />
          </Container>
        </>
      )}
    </Box>
  );
};
