import React from "react";
import { Box } from "@mui/system";
import { Typography, Grid, Container } from "@mui/material";
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";

export const ArtistStory = ({ imgsrc, name, desc }) => {
  const location = useLocation();
  const iconBoxStyle = {
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  // Function to generate shareable URLs for social media platforms
  const generateShareUrl = () => {
    // Replace these placeholders with the actual URL and content you want to share
    const shareUrl = `${window.location.origin}${location.pathname}`;
    const shareTitle = "Check out this page"; // Replace with your page title
    const shareDescription = "Description of the shared content"; // Replace with your description

    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareTitle
      )}&url=${encodeURIComponent(shareUrl)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${shareTitle}\n${shareUrl}`
      )}`,
      youtubeUrl: "https://www.youtube.com/user/yourchannel", // Replace with the user's YouTube channel URL
      tiktokUrl: "https://www.tiktok.com/youraccount",
    };
  };

  const socialMediaUrls = generateShareUrl();

  const hor_line = {
    width: "100%",
    height: "0.5px",
    backgroundColor: "var(--title-backgrd-color)",
    marginTop: "1px",
  };

  return (
    <Box>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "20px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            marginTop: "10px",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "18px",
              },
              color: "var(--btn-color)",
              fontFamily: "var( --primary-font)",
              textAlign: "justify",
              fontWeight: "500",
            }}
          >
            Share
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            alignItems: "flex-start",
            zIndex: "1",
          }}
        >
          <ul
            style={{
              listStyle: "none",
              margin: "0",
              padding: "0",
              display: "flex",
            }}
            className="socialmediaicons"
          >
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaFacebookF
                  style={iconBoxStyle}
                  size="40px"
                  className="icon"
                />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaXTwitter style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.whatsapp}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <ImWhatsapp style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            {/* <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaYoutube style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaTiktok style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li> */}
          </ul>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "18px",
            },
            color: "var( --btn-color)",
            fontFamily: "var( --primary-font)",
            textAlign: "justify",
            fontWeight: "500",
          }}
        >
          Designed/ Crafted by:
        </Typography>
        <Box
          sx={{
            marginTop: "20px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              <Box
                sx={{
                  width: {
                    xs: "20vw",
                    sm: "15vw",
                    md: "10vw",
                    lg: "8vw",
                    xl: "8vw",
                  },
                  height: {
                    xs: "12vh",
                    sm: "13vh",
                    md: "13vh",
                    lg: "13vh",
                    xl: "13vh",
                  },
                }}
              >
                <img
                  src={imgsrc}
                  alt="..."
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading="lazy"
                />
              </Box>
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                    color: "var(--black-color)",
                    fontFamily: "var( --primary-font)",
                    textAlign: "justify",
                    fontWeight: "600",
                  }}
                >
                  {name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "var(--theme-color)",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      fontWeight: "600",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    Read artist’s Story
                  </Typography>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/rightarrow.png"}
                    alt="..."
                    style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                    loading="lazy"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  marginBottom: "10px",
                }}
              >
                <div style={hor_line} />
                <div style={hor_line} />
                <div style={hor_line} />
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "11px",
                      lg: "11px",
                      xl: "15px",
                    },
                    textAlign: "justify",
                  }}
                >
                  "{desc}"
                </Typography>
              </Box>
            </Grid>

            <Container
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                marginTop: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "11px",
                    lg: "11px",
                    xl: "15px",
                  },
                  textAlign: "justify",
                }}
              >
                "{desc}"
              </Typography>
            </Container>
            <Grid item xs={12}>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "80%",
                    md: "80%",
                    lg: "80%",
                    xl: "80%",
                  },
                  marginTop: "20px",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/artistbottom.png"}
                  alt="..."
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading="lazy"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
