import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NotesSlider } from "./NotesSlider";

export const Notes = () => {
  return (
    <Box>
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              xs: "16px",
              sm: "16px",
              md: "18px",
              lg: "22px",
              xl: "22px",
            },
            fontFamily: "var(--primary-font)",
            color: "var(--theme-color)",
          }}
        >
          Notes
        </Typography>
        <br />
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "24px",
              sm: "24px",
              md: "24px",
              lg: "24px",
              xl: "24px",
            },
            fontFamily: "var(--primary-font)",
            color: "var(--black)",
          }}
        >
          Trust we achieved
        </Typography>
      </Box>
      <NotesSlider />
    </Box>
  );
};
