import axios from "axios";

export const CurrencyData = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_JINLAP_BASE_URL + `/currencies`
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};
