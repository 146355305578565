import { configureStore } from "@reduxjs/toolkit";
import dharmagoodCartReducer from "../feature/DharmagoodCartSlice";
import gooddharmaCartReducer from "../feature/GoodDharmaCartSlice";
import CurrencyReducer from "../feature/CurrencySlice";

export const Store = configureStore({
  reducer: {
    alldharmagoodCart: dharmagoodCartReducer,
    allgooddharmaCart: gooddharmaCartReducer,
    currency: CurrencyReducer,
  },
});
