export const footerTopData = [
  {
    id: 1,
    title: "Handmade product",
    desc: "Made by professionals",
    icon: "./assets/footer/handmade.png",
  },
  {
    id: 2,
    title: "Best price and offers",
    desc: "Orders $100 or more",
    icon: "./assets/footer/bestoffer.png",
  },
  {
    id: 3,
    title: "Secure payment ",
    desc: "For every purchase",
    icon: "./assets/footer/securepayment.png",
  },
  {
    id: 4,
    title: "Responsive Design",
    desc: "For all devices",
    icon: "./assets/footer/responsivedesign.png",
  },
];



