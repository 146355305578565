import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { TermsConditionContent } from "../../components/Terms&ConditionsComp/TermsConditionContent";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const TermsConditionPage = () => {
  const [language, setLanguage] = React.useState("english");
  const { getLanguage } = useAPI();
  const getData = async () => {
    const res = await getLanguage(endpoint.termsconditions, language);
    return res.data;
  };

  const { isLoading, data: termsandconditions } = useQuery({
    queryKey: ["termsandconditions", language],
    queryFn: getData,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Box>
      {termsandconditions && (
        <Box>
          <TopSection
            image={termsandconditions?.image}
            title={termsandconditions?.top_title}
            description={`Last Updated : ${termsandconditions?.updated_date}`}
          />
        </Box>
      )}
      <Container>
        <TermsConditionContent language={language} setLanguage={setLanguage} />
        <Subscribe />
      </Container>
    </Box>
  );
};
