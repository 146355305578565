import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import thankyouicon from "../../assets/pray.png";

const horizontal_line = {
  width: "100%",
  height: "1px",
  backgroundColor: "var(--title-backgrd-color)",
};
export const ThankyouforSubscribe = ({ handleCloseSubscribe }) => {
  return (
    <Box>
      <Box
        sx={{
          height: "50px",
        }}
      >
        <Button
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "var(--black-color)",
            "&:hover": {
              backgroundColor: "transparent",
              color: "var(--theme-color)",
            },
          }}
          onClick={handleCloseSubscribe}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center horizontally
        }}
      >
        <div style={horizontal_line}></div>
        <div style={horizontal_line}></div>
        <div style={horizontal_line}></div>

        <img
          src={thankyouicon}
          alt="..."
          style={{
            width: "80px",
            zIndex: "1",
            // bottom: "60px",
            top: "20px",
            position: "absolute",
            backgroundColor: "var(--white)",
          }}
          loading="lazy"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center horizontally
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "14px",
              sm: "16px",
              md: "16px",
              lg: "18px",
              xl: "28px",
            },
            fontWeight: "500",
            color: "var(--theme-color)",
            fontFamily: "var(--primary-font)",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          Thank you for Subscribing
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            fontSize: {
              xs: "13px",
              sm: "14px",
              md: "15px",
              lg: "16px",
              xl: "16px",
            },
            fontWeight: "400",
            color: "var(--black-color)",
            fontFamily: "var(--primary-font)",
            marginBottom: "50px",
          }}
        >
          You have successfully subscribed to our lists. We will let you know
          about the latest updates and newly released dharma goods and good
          dharma.
        </Typography>
        <Box
          sx={{
            width: "15vw",
            height: "auto",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
            alt="..."
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
            loading="lazy"
          />
        </Box>
      </Box>
    </Box>
  );
};
