import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useApi";

export const DharmaGoods = () => {
  const navlinkStyle = {
    textDecoration: "none",
    color: "var(--black)",
    margin: "5px",
  };

  const { get, getCategoriesdataById } = useAPI();

  // Use query keys as arrays
  const { isLoading, data: dharmas } = useQuery(["dharmas"], getData);
  const { isSubCategoriesDataLoading, data: subcategories } = useQuery(
    ["subcategories"],
    getDharmaGoodSubcategoriesData,
    {
      // Wait for dharmas data to be available before fetching subcategories
      enabled: !!dharmas,
    }
  );

  async function getData() {
    try {
      const res = await get(endpoint.dharmas);
      return res.data;
    } catch (error) {
      throw new Error("Error fetching dharmas");
    }
  }

  async function getDharmaGoodSubcategoriesData() {
    try {
      // Ensure dharmas data is available before proceeding
      if (!dharmas) {
        throw new Error("Dharmas data not available");
      }

      const DharmaGoodData = dharmas?.find(
        (item) => item.name === "Dharma Goods"
      );

      if (!DharmaGoodData) {
        throw new Error("Dharma Goods not found in dharmas data");
      }

      const res = await getCategoriesdataById(endpoint.subcategories, 1);
      return res.data;
    } catch (error) {
      throw new Error("Error fetching subcategories: " + error.message);
    }
  }

  if (isLoading || isSubCategoriesDataLoading) {
    return <>Loading...</>; // Display a loading component while data is loading
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "justify",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="span"
              sx={{
                color: "var(--theme-color)",
                fontWeight: "bold",

                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "17px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              Dharma Goods
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
            }}
          >
            {subcategories?.map((item, index) => {
              return (
                <NavLink
                  to={`/goods/${item.id}`}
                  style={navlinkStyle}
                  key={index}
                >
                  <Typography variant="span">{item.name}</Typography>
                </NavLink>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
