import React, { useState } from "react";
import { ProductDetailsTopbar } from "../../components/ProductDetails/ProductDetailsTopbar";
import { Box } from "@mui/material";
import { Container, Grid } from "@mui/material";
import { InformationForm } from "../../components/CheckoutPageComponents/InformationForm";
import { ProductsList } from "../../components/CheckoutPageComponents/ProductsList";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { ShippingForm } from "../../components/CheckoutPageComponents/ShippingForm";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { Typography } from "@mui/material";

export const CheckoutPage = () => {
  const [showShippingForm, setShowShippingForm] = useState(false);
  const [showpPromodiscount, setShowPromodiscount] = useState(false);

  const handleContinueToShipping = () => {
    setShowShippingForm(true);
    setShowPromodiscount(true);
  };

  const handleBacktoCart = () => {
    setShowShippingForm(false);
  };

  const { getAuthData } = useAPI();

  const getOrderDetails = async () => {
    const res = await getAuthData(endpoint.order_details);
    return res.data;
  };

  const { isLoading, data: orderDetails } = useQuery({
    queryKey: ["orderDetails"],
    queryFn: getOrderDetails,
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  const totalamount = orderDetails?.map((item) => item.total_amount);
  const orderId = orderDetails?.map((item) => item.id);

  return (
    <>
      <Box
        sx={{
          background: {
            xs: "linear-gradient(to bottom, var(--white) 0%,var(--white) 57%,  var(--light-grey) 0%, var(--light-grey) 50%)",
            sm: "linear-gradient(to right, var(--white) 0%,var(--white) 50%,  var(--light-grey) 0%, var(--light-grey) 50%)",
            md: "linear-gradient(to right, var(--white) 0%,var(--white) 50%,  var(--light-grey) 0%, var(--light-grey) 50%)",
            lg: "linear-gradient(to right, var(--white) 0%,var(--white) 50%,  var(--light-grey) 0%, var(--light-grey) 50%)",
            xl: "linear-gradient(to right, var(--white) 0%,var(--white) 50%,  var(--light-grey) 0%, var(--light-grey) 50%)",
          },
        }}
      >
        <ProductDetailsTopbar firsttitle="Cart" secondtitle="Checkout" />
        <Container>
          <Grid container>
            {/* Left Side (Blue) */}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {showShippingForm ? (
                <ShippingForm
                  onContinue={handleBacktoCart}
                  totalamount={totalamount}
                  orderId={orderId}
                  orderData={orderDetails}
                />
              ) : (
                <InformationForm onContinue={handleContinueToShipping} />
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{
                backgroundColor: "var(--light-grey)",
              }}
            >
              <ProductsList
                showpPromodiscount={showpPromodiscount}
                orderData={orderDetails}
                orderId={orderId}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Subscribe />
      </Container>
    </>
  );
};
