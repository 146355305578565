import React, { useState } from "react";
import { Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Cookies } from "react-cookie";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

export const DharmagoodCategorySection = ({ searchData }) => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();

  const [displayedItems, setDisplayedItems] = useState(8);
  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const { getCategoriesdataById } = useAPI();

  const getData = async () => {
    const response = await getCategoriesdataById(endpoint.getgoodsbyId, id);
    return response.data;
  };

  const { isLoading, data: dharmagoodProductsData } = useQuery({
    queryKey: [["CategoriesdataById"], id],
    queryFn: getData,
  });

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  const handleShowMore = () => {
    setDisplayedItems(dharmagoodProductsData?.length);
  };
  const handleShowLess = () => {
    setDisplayedItems(8);
  };

  return (
    <Box sx={{ marginBottom: "60px" }}>
      <Grid container spacing={3}>
        {searchData.length > 0
          ? searchData?.slice(0, displayedItems)?.map((item) => {
              return (
                <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={item.id}>
                  <Box
                    sx={{
                      position: "relative",
                      "&:hover": {
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "#e0bace", // Light pink color
                          opacity: 0.7,
                          zIndex: 1,
                        },
                        "& button": {
                          visibility: "visible",
                        },
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: "35vh",
                        width: "100%",
                      }}
                    >
                      <img
                        src={item.images[0]?.image}
                        alt={item.name}
                        style={{ height: "100%", width: "100%" }}
                        loading="lazy"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "40%",
                          transform: "translate(-50%, -50%)",
                          visibility: "hidden",
                          zIndex: 2,
                        }}
                        size="small"
                        color="primary"
                        onClick={async () => {
                          const wishlistdata = {
                            color: item.colors[0]?.color,
                            size: item.sizes[0]?.size,
                            product_id: item.id,
                            currency: selectedCurrency,
                            qty: 1,
                            price: item.after_discount
                              ? (item.after_discount / rate).toFixed(2)
                              : (item.price / rate).toFixed(2),
                          };

                          try {
                            const response = await axios.post(
                              process.env.REACT_APP_JINLAP_BASE_URL +
                                `/products/add-to-wishlist`,
                              wishlistdata,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                },
                              }
                            );
                            if (response.data.status === true) {
                              queryClient.invalidateQueries(
                                "dharmagoodwishlist"
                              );
                              toast.success(
                                `${item.name} added to wishlist successfully!`
                              );
                            } else {
                              toast.warning(response.data.message);
                            }
                          } catch (error) {
                            toast.error(error.response.data.message);
                          }
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "var(--very-lightthemecolor)",
                            padding: "5px",
                            borderRadius: "50%",
                            color: "var(--theme-color)",
                            "&:hover": {
                              //zoom the buttom
                              transform: "scale(1.1)",
                              backgroundColor: "var(--theme-color)",
                              color: "var(--white)",
                            },
                          }}
                        >
                          <FavoriteBorderIcon fontSize="medium" />
                        </Box>
                      </IconButton>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "60%",
                          transform: "translate(-50%, -50%)",
                          visibility: "hidden",
                          zIndex: 2,
                        }}
                        size="small"
                        color="primary"
                        onClick={async () => {
                          const data = {
                            color: item.colors[0]?.color,
                            size: item.sizes[0]?.size,
                            qty: 1,
                            product_id: item.id,
                            currency: selectedCurrency,
                            price: item.after_discount
                              ? (item.after_discount / rate).toFixed(2)
                              : (item.price / rate).toFixed(2),
                          };

                          try {
                            const response = await axios.post(
                              process.env.REACT_APP_JINLAP_BASE_URL +
                                `/products/add-to-cart`,
                              data,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                },
                              }
                            );
                            if (response.data.status) {
                              queryClient.invalidateQueries("dharmagoodcart");
                              toast.success(
                                `${item.name} added to cart successfully!`
                              );
                            } else {
                              toast.warning(response.data.message);
                            }
                          } catch (error) {
                            toast.error(error.response.data.message);
                          }
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "var(--very-lightthemecolor)",
                            padding: "5px",
                            borderRadius: "50%",
                            color: "var(--theme-color)",
                            "&:hover": {
                              //zoom the buttom
                              transform: "scale(1.1)",
                              backgroundColor: "var(--theme-color)",
                              color: "var(--white)",
                            },
                          }}
                        >
                          <ShoppingBagOutlinedIcon fontSize="medium" />
                        </Box>
                      </IconButton>
                    </Box>
                  </Box>

                  <Box sx={{ textAlign: "justify", marginTop: "10px" }}>
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/productdetails/${item.id}`);
                      }}
                    >
                      {item.name}
                    </Typography>
                    <br />
                    <Typography
                      variant="span"
                      sx={{
                        color: "#96195a",
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                        textAlign: "center",
                      }}
                    >
                      {item.after_discount ? (
                        <>
                          <del>
                            {selectedCurrency} {""}{" "}
                            {(item.price / rate)?.toFixed(2)}
                          </del>
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            {selectedCurrency} {""}{" "}
                            {(item.after_discount / rate)?.toFixed(2)}
                          </span>
                        </>
                      ) : (
                        <span>
                          {selectedCurrency} {""}
                          {item.price.toFixed(2)}
                        </span>
                      )}
                    </Typography>
                  </Box>
                </Grid>
              );
            })
          : dharmagoodProductsData
              ?.slice(0, displayedItems)
              .map((item, index) => {
                return (
                  <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={item.id}>
                    <Box
                      sx={{
                        position: "relative",
                        "&:hover": {
                          "&::before": {
                            content: "''",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "#e0bace", // Light pink color
                            opacity: 0.7,
                            zIndex: 1,
                          },
                          "& button": {
                            visibility: "visible",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          height: "35vh",
                          width: "100%",
                        }}
                      >
                        <img
                          src={item.images[0]?.image}
                          alt={item.name}
                          style={{ height: "100%", width: "100%" }}
                          loading="lazy"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "40%",
                            transform: "translate(-50%, -50%)",
                            visibility: "hidden",
                            zIndex: 2,
                          }}
                          size="small"
                          color="primary"
                          onClick={async () => {
                            const wishlistdata = {
                              color: item.colors[0]?.color,
                              size: item.sizes[0]?.size,
                              product_id: item.id,
                              currency: selectedCurrency,
                              qty: 1,
                              price: item.after_discount
                                ? (item.after_discount / rate).toFixed(2)
                                : (item.price / rate).toFixed(2),
                            };

                            try {
                              const response = await axios.post(
                                process.env.REACT_APP_JINLAP_BASE_URL +
                                  `/products/add-to-wishlist`,
                                wishlistdata,
                                {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                  },
                                }
                              );
                              if (response.data.status === true) {
                                queryClient.invalidateQueries(
                                  "dharmagoodwishlist"
                                );
                                toast.success(
                                  `${item.name} added to wishlist successfully!`
                                );
                              } else {
                                toast.warning(response.data.message);
                              }
                            } catch (error) {
                              toast.error(error.response.data.message);
                            }
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--very-lightthemecolor)",
                              padding: "5px",
                              borderRadius: "50%",
                              color: "var(--theme-color)",
                              "&:hover": {
                                //zoom the buttom
                                transform: "scale(1.1)",
                                backgroundColor: "var(--theme-color)",
                                color: "var(--white)",
                              },
                            }}
                          >
                            <FavoriteBorderIcon fontSize="medium" />
                          </Box>
                        </IconButton>
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "60%",
                            transform: "translate(-50%, -50%)",
                            visibility: "hidden",
                            zIndex: 2,
                          }}
                          size="small"
                          color="primary"
                          onClick={async () => {
                            const data = {
                              color: item.colors[0]?.color,
                              size: item.sizes[0]?.size,
                              qty: 1,
                              product_id: item.id,
                              currency: selectedCurrency,
                              price: item.after_discount
                                ? (item.after_discount / rate).toFixed(2)
                                : (item.price / rate).toFixed(2),
                            };

                            try {
                              const response = await axios.post(
                                process.env.REACT_APP_JINLAP_BASE_URL +
                                  `/products/add-to-cart`,
                                data,
                                {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                  },
                                }
                              );
                              if (response.data.status) {
                                queryClient.invalidateQueries("dharmagoodcart");
                                toast.success(
                                  `${item.name} added to cart successfully!`
                                );
                              } else {
                                toast.warning(response.data.message);
                              }
                            } catch (error) {
                              toast.error(error.response.data.message);
                            }
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--very-lightthemecolor)",
                              padding: "5px",
                              borderRadius: "50%",
                              color: "var(--theme-color)",
                              "&:hover": {
                                //zoom the buttom
                                transform: "scale(1.1)",
                                backgroundColor: "var(--theme-color)",
                                color: "var(--white)",
                              },
                            }}
                          >
                            <ShoppingBagOutlinedIcon fontSize="medium" />
                          </Box>
                        </IconButton>
                      </Box>
                    </Box>

                    <Box sx={{ textAlign: "justify", marginTop: "10px" }}>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          cursor: "pointer",
                          ":hover": {
                            color: "var(--theme-color)",
                          },
                        }}
                        onClick={() => {
                          navigate(`/productdetails/${item.id}`);
                        }}
                      >
                        {item.name}
                      </Typography>
                      <br />
                      <Typography
                        variant="span"
                        sx={{
                          color: "#96195a",
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          textAlign: "center",
                        }}
                      >
                        {item.after_discount ? (
                          <>
                            <del>
                              {selectedCurrency} {""}{" "}
                              {(item.price / rate)?.toFixed(2)}
                            </del>
                            <span
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              {selectedCurrency} {""}{" "}
                              {(item.after_discount / rate)?.toFixed(2)}
                            </span>
                          </>
                        ) : (
                          <span>
                            {selectedCurrency} {""}
                            {item.price.toFixed(2)}
                          </span>
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
      </Grid>
      {displayedItems < dharmagoodProductsData?.length && (
        <Button
          // variant="outlined"
          sx={{
            color: "#96195A",
            border: "1px solid #96195A",
            width: { xs: "100%", sm: "100%", md: "15%", lg: "15%", xl: "15%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "capitalize",
            margin: "auto",
            marginTop: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            marginBottom: "150px",
            "&:hover": {
              backgroundColor: "#96195A",
              color: "white",
              border: "1px solid #96195A",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowMore}
        >
          SHOW MORE
          <KeyboardArrowDownIcon />
        </Button>
      )}
      {displayedItems > 8 && (
        <Button
          variant="outlined"
          sx={{
            color: "#96195A",
            border: "1px solid #96195A",
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "150px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "#96195A",
              color: "white",
              border: "1px solid #96195A",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowLess}
        >
          Show Less
          <KeyboardArrowUpIcon />
        </Button>
      )}
    </Box>
  );
};
