import React from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export default function ProjectDonationCheckoutForm({
  projectId,
  handleCloseVisa,
}) {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const cookies = new Cookies();
  const userToken = cookies.get("token");

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (
      (response.error && response.error.type === "card_error") ||
      (response.error && response.error.type === "validation_error")
    ) {
      setMessage(response.error.message);

      axios.put(
        `${process.env.REACT_APP_JINLAP_BASE_URL}/supportprojects-donations/${projectId}`,
        {
          status: "failed",
          //   amount: response.paymentIntent.amount,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      handleCloseVisa();
      // toast.error(res.data.message);
    } else if (response.paymentIntent.id) {
      //display success message or redirect user
      setMessage("Payment was successful");
      axios.put(
        `${process.env.REACT_APP_JINLAP_BASE_URL}/supportprojects-donations/${projectId}`,
        {
          status: response.paymentIntent.status,
          //   amount: response.paymentIntent.amount,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      handleCloseVisa();
      toast.success("Payment was successful");
      navigate("/thankyoufordonation");
    }
    setIsProcessing(false);
    // handleOptionsClose();
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        variant="contained"
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          backgroundColor: "var(--theme-color)",
          textTransform: "capitalize",
          color: "white",
          width: "30%",
          height: "40px",
          borderRadius: "5px",
          fontSize: "15px",
          fontWeight: "bold",
          border: "none",
        }}
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>

      {/* Show any error or success messages */}
      {message && (
        <div
          id="payment-message"
          style={{
            textAlign: "center",
            color: message.includes("success") ? "green" : "red",
            margin: "1rem 0 1rem 0",
            fontSize: "16px",
          }}
        >
          {message}
        </div>
      )}
    </form>
  );
}
