export const accountdata = [
  {
    id: 1,
    title: "Accounts",
    imgUrl: "./assets/DharmaGoodpage/topsec_img.png",
  },
];

export const accountTableData = [
  {
    orderid: "#123456",
    productsdetail: [
      {
        id: 1,
        prodname: "Shakyamuni Buddha Thangka Painting",
        prodimg: "./assets/DharmaGood/dharmagood4.png",
        prodprice: 5000,
        Quantity: 1,
      },
      {
        id: 2,
        prodname: "Singing Bowl",
        prodimg: "./assets/DharmaGood/dharmagood3.png",
        prodprice: 4000,
        Quantity: 2,
      },
      {
        id: 2,
        prodname: "Shakyamuni Buddha Thangka Painting",
        prodimg: "./assets/DharmaGood/dharmagood4.png",
        prodprice: 4000,
        Quantity: 1,
      },
    ],
    ordereddate: "24 feb, 2023",
    OrderStatus: "Delivered",
    TotalQuantity: 4,
    paymentStatus: "paid",
    TotalCost: 13060,
    subTotal: 13000,
    deliveryCharge: 60,
    shippingaddress: [
      {
        name: "Bibek Kumar Shrestha",
        email: "Company@emailexample.com.np",
        address:
          "Bagmati Province, Kathmandu Metro 13 - Kalimati Area, Bafal, bafal, bijayamarg",
        phone: "9841234567",
      },
    ],
    billingaddress: [
      {
        name: "Bibek Kumar Shrestha",
        email: "Company@emailexample.com.np",
        address:
          "Bagmati Province, Kathmandu Metro 13 - Kalimati Area, Bafal, bafal, bijayamarg",
        phone: "9841234567",
      },
    ],
  },
];
