import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Grid from "@mui/material/Grid";
import { LogInForm } from "./LogInForm";
import { RegistrationForm } from "./RegistrationForm";
import { LoginRegImageSide } from "./LoginRegImageSide";

export const LogInRegTab = ({ handleClose }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indicatorStyles = {
    backgroundColor: "var(--theme-color)", // Change this to your desired color
    height: "3.5px", // Adjust the height of the indicator if needed
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        // width: "100%",
        // height: "auto",
      }}
    >
      <Grid
        item
        xs={4}
        sx={{
          display: {
            xs: "none",
            sm: "flex",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      >
        <LoginRegImageSide />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        lg={8}
        xl={8}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "15px", // Add margin to separate logo from tab list
          }}
        >
          <Box
            sx={{
              width: {
                xs: "40vw",
                sm: "15vw",
                md: "15vw",
                lg: "13vw",
                xl: "13vw",
              },
              padding: {
                xs: "10px",
                sm: "10px",
                md: "0px",
                lg: "0px",
                xl: "0px",
              },
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "./assets/logo.png"}
              alt="logo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              loading="lazy"
            />
          </Box>
        </Box>

        <Box>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: "2px dashed",
                borderColor: "divider",
                width: "100%",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
                TabIndicatorProps={{
                  style: indicatorStyles,
                }}
              >
                <Tab
                  label="Login"
                  value="1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                  }}
                />
                <Tab
                  label="Registration"
                  value="2"
                  sx={{
                    fontFamily: "var(--primary-font)",
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <LogInForm handleClose={handleClose} />
            </TabPanel>
            <TabPanel value="2">
              <RegistrationForm handleClose={handleClose} />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};
