import React from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { CartToppathBar } from "../../components/CartComp/CartToppathBar/CartToppathBar";
import { DharmaGoodCart } from "../../components/CartComp/DharmaGoodCart/DharmaGoodCart";
import { GoodDharmaCart } from "../../components/CartComp/GoodDharmaCart/GoodDharmaCart";
import { CartBottomComp } from "../../components/CartComp/CartBottomComp/CartBottomComp";
import { MobDharmaGoodCart } from "../../components/CartComp/DharmaGoodCart/MobDharmaGoodCart";
import { MobGoodDharmaCart } from "../../components/CartComp/GoodDharmaCart/MobGoodDharmaCart";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";

export const CartPage = () => {
  return (
    <Box>
      <CartToppathBar />
      <Container>
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "var(--primary-font)",
              color: "var(--light-black)",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              margin: "40px 0px 40px 0px",
            }}
          >
            Cart
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <DharmaGoodCart />
          </Box>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <MobDharmaGoodCart />
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <GoodDharmaCart />
          </Box>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <MobGoodDharmaCart />
          </Box>

          <CartBottomComp />
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <Subscribe />
        </Box>
      </Container>
    </Box>
  );
};
