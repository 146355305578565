import React from "react";
import TopSlider from "../../components/LandingPage/TopSlider";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import "./Landpage.css";
import { GoodDharma } from "../../components/LandingPage/GoodDharma/GoodDharma";
import Vector from "../../../src/components/assets/Vector.png";
import { DharmaGood } from "../../components/LandingPage/DharmaGood/DharmaGood";
import Buddhaicon from "../../components/assets/buddhaicon.png";
import { Goods } from "../../components/LandingPage/Goods/Goods";
import { SupportActivity } from "../../components/LandingPage/SupportActivity/SupportActivity";
import lotus from "../../components/assets/lotus.png";
import manicon from "../../components/assets/manicon.png";
import { Blog } from "../../components/LandingPage/Blog/Blog";
import { Supportproject } from "../../components/LandingPage/Supportproject/Supportproject";
import { Testimonial } from "../../components/Shared/Testimonial/Testimonial";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { DharmaGoodMob } from "../../components/LandingPage/DharmaGood/DharmaGoodMob";
import { Dharma } from "../../components/LandingPage/Dharma/Dharma";
import Skeleton from "@mui/material/Skeleton";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const Landpage = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.home_testimonials);
    return res.data;
  };

  const { isLoading: testimonialLoading, data: testimonial } = useQuery({
    queryKey: ["testimonial"],
    queryFn: getData,
  });

  const getsitesettingsData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isLoading: siteLoading, data: siteSettings } = useQuery({
    queryKey: ["siteSettings"],
    queryFn: getsitesettingsData,
  });

  if (testimonialLoading || siteLoading) {
    return (
      <Container>
        <Skeleton variant="rectangular" height={300} width={"100%"} />
      </Container>
    );
  }

  return (
    <Box
      sx={{
        margin: "0",
        padding: "0",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          marginBottom: "150px",
        }}
      >
        <TopSlider />
      </Box>
      <Box
        sx={{
          marginBottom: {
            xs: "250px",
            sm: "0px",
            md: "20px",
            lg: "40px",
            xl: "40px",
          },
        }}
      >
        <Dharma />
      </Box>
      <Container>
        <HorizontalLines imgUrl={`${Vector}`} />
        <GoodDharma />
      </Container>
      <Box
        sx={{
          backgroundColor: "var(--back-color)",
        }}
      >
        <Container>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <div style={{ marginBottom: "50px" }}>
              <HorizontalLines title={siteSettings?.feature_product} />
            </div>
            <DharmaGood />
          </Box>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <HorizontalLines title={siteSettings?.feature_product} />
            </div>
            <DharmaGoodMob />
          </Box>
        </Container>
      </Box>
      <Container>
        <div style={{ marginBottom: "20px" }}>
          <HorizontalLines imgUrl={`${Buddhaicon}`} />
        </div>
        <Goods />
      </Container>
      <SupportActivity />
      <Container>
        <HorizontalLines imgUrl={`${lotus}`} />
        <Blog />
      </Container>
      <Supportproject />
      <Container>
        <HorizontalLines imgUrl={`${manicon}`} />
      </Container>
      <Testimonial data={testimonial} />
      <Container
        sx={{
          marginTop: {
            xs: "10%",
            sm: "5%",
            md: "10px",
            lg: "10px",
            xl: "10px",
          },
        }}
      >
        <Subscribe />
      </Container>
    </Box>
  );
};
