import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dharmagoodcart: [],
  // items: dharmaGoodsdata,
  items: [],
  dharmagoodtotalQuantity: 0,
  dharmagoodtotalPrice: 0,
};

const DharmagoodCartSlice = createSlice({
  name: "dharmagoodcart",
  initialState,
  reducers: {
    // Initialize items with the fetched data
    setDharmaGoodsData: (state, action) => {
      state.items = action.payload;
    },
    addTodharmagoodCart: (state, action) => {
      const find = state.dharmagoodcart.findIndex(
        (item) => item.id === action.payload.id
      );

      if (find >= 0) {
        // Increase the quantity of the existing item
        state.dharmagoodcart[find].quantity += 1;
      } else {
        // Add the item to the cart
        state.dharmagoodcart.push(action.payload);
      }
      // Save updated cart data to localStorage
      localStorage.setItem(
        "dharmagoodcart",
        JSON.stringify(state.dharmagoodcart)
      );
    },

    getdharmagoodCartTotal: (state) => {
      let { dharmagoodtotalQuantity, dharmagoodtotalPrice } =
        state.dharmagoodcart.reduce(
          (cartTotal, cartItem) => {
            const { price, quantity } = cartItem;
            const itemTotal = price * quantity;
            cartTotal.dharmagoodtotalPrice += itemTotal;
            cartTotal.dharmagoodtotalQuantity += quantity;
            localStorage.setItem(
              "dharmagoodtotalQuantity",
              JSON.stringify(state.dharmagoodtotalQuantity)
            );

            return cartTotal;
          },
          {
            dharmagoodtotalPrice: 0,
            dharmagoodtotalQuantity: 0,
          }
        );
      state.dharmagoodtotalPrice = parseInt(dharmagoodtotalPrice);
      state.dharmagoodtotalQuantity = dharmagoodtotalQuantity;
    },

    removedharmagoodItem: (state, action) => {
      const removedItemId = action.payload;

      // Remove the item from the cart
      state.dharmagoodcart = state.dharmagoodcart.filter(
        (item) => item.id !== removedItemId
      );

      // Save updated cart data to localStorage
      localStorage.setItem(
        "dharmagoodcart",
        JSON.stringify(state.dharmagoodcart)
      );
    },
    increasedharmagoodItemQuantity: (state, action) => {
      state.dharmagoodcart = state.dharmagoodcart.map((item) => {
        if (item.id === action.payload) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
    },
    decreasedharmagoodItemQuantity: (state, action) => {
      state.dharmagoodcart = state.dharmagoodcart.map((item) => {
        if (item.id === action.payload) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
    },
  },
});

export const {
  addTodharmagoodCart,
  getdharmagoodCartTotal,
  removedharmagoodItem,
  increasedharmagoodItemQuantity,
  decreasedharmagoodItemQuantity,
  setDharmaGoodsData,
} = DharmagoodCartSlice.actions;

export default DharmagoodCartSlice.reducer;
