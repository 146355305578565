import React from "react";
import { Box } from "@mui/system";
import "./TopSection.css";
import { Typography } from "@mui/material";

export const TopSection = ({
  title,
  description,
  id,
  subtitle1,
  subtitle2,
  title2,
  image,
}) => {
  return (
    <>
      {/* {DharmaGoodTopSectionData.map((item) => { */}
      <Box
        className="top-section"
        key={id}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // position: "sticky",
          // top: 0,
          // zIndex: 110,
          // // background: "orange",
          // overflow: "auto",
          // height: "300px",
        }}
      >
        <Box
          className="top-section-left"
          sx={{
            width: {
              xs: "95%",
              sm: "95%",
              md: "95%",
              lg: "70%",
              xl: "70%",
            },
            textAlign: "justify",
            padding: {
              xs: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "3.2rem",
              xl: "3.2rem",
            },
            // zIndex: "1",
            marginLeft: {
              xs: "0",
              sm: "1%",
              md: "1%",
              lg: "9%",
              xl: "9%",
            },
          }}
        >
          <Typography
            variant="p"
            className="top-section-left-heading"
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.5rem",
                lg: "2rem",
                xl: "2.5rem",
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="p"
            className="top-section-left-heading"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "15px",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            {title2}
          </Typography>

          <br />
          <Typography
            variant="p"
            className="top-section-left-subheading"
            sx={{
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "15px",
                lg: "16px",
                xl: "16px",
              },
            }}
          >
            {description}
          </Typography>
          {subtitle1 && (
            <>
              <Typography
                variant="p"
                className="top-section-left-subheading"
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "18px",
                    lg: "28px",
                    xl: "28px",
                  },
                }}
              >
                {subtitle1}
              </Typography>
              <br />
            </>
          )}
          {subtitle2 && (
            <>
              <Typography
                variant="p"
                className="top-section-left-subheading"
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "18px",
                    lg: "28px",
                    xl: "28px",
                  },
                }}
              >
                {subtitle2}
              </Typography>
            </>
          )}
        </Box>

        <Box className="top-section-right" sx={{ zIndex: "0", width: "40%" }}>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%", // Set the height to 100% to cover the entire left section
                top: 0,
                left: 0,
                zIndex: "100",
                background:
                  "linear-gradient(to left,  rgba(202,81,141,0.19100140056022408) 34%, rgba(150,25,90,0.9781162464985994) 99%)",
              }}
            ></Box>

            <Box>
              <img
                src={
                  image
                    ? image
                    : process.env.PUBLIC_URL +
                      "./assets/DharmaGoodpage/topsec_img.png"
                }
                alt="Dharma Good TopImage"
                className="top-section-right-image"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
