import React from "react";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { Paintingsearch } from "../../components/Paintings/Paintingsearch";
import { FeaturedPaintings } from "../../components/Paintings/FeaturedPaintings/FeaturedPaintings";
import { useParams } from "react-router-dom";
import axios from "axios";

export const IndividualCategorypage = () => {
  const { id } = useParams();
  const [dharmagoodcategories, setDharmagoodcategories] = React.useState([]);
  const [dharmagoodProductsData, setDharmagoodProductsData] = React.useState(
    []
  );

  React.useEffect(() => {
    const fetchCategoriesDataAsync = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_JINLAP_BASE_URL + `/categories/${id}`
        );
        setDharmagoodcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategoriesDataAsync();
  }, [id]);

  React.useEffect(() => {
    const getDharmaGoodData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_JINLAP_BASE_URL + `/categories/${id}/products`
        );
        setDharmagoodProductsData(response.data.data);
      } catch (error) {
        throw error;
      }
    };
    getDharmaGoodData();
  }, [id]);

  return (
    <Box>
      {dharmagoodcategories && (
        <Box>
          <TopSection
            title={dharmagoodcategories.name}
            description={dharmagoodcategories.description}
            id={dharmagoodcategories.id}
            image={dharmagoodcategories.image}
          />
        </Box>
      )}

      <Container>
        <Paintingsearch
          title={dharmagoodcategories.name}
          length={dharmagoodProductsData.length}
        />

        {/* <DharmagoodCategorySection /> */}
      </Container>
      <FeaturedPaintings id={id} title={dharmagoodcategories.name} />
      <Container>
        <Subscribe />
      </Container>
    </Box>
  );
};
